import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import { getGruposDeHorarios } from "../../../services/grupos-de-horarios"
import { getSetores, putSetor } from "../../../services/setores"
import DefaultForm from "../../form/DefaultForm"

export default withTranslation()(function ModalEdicaoSetor({ opened, setOpened, refresh, values, ...props }) {
    function translate (text) {
      return props.t(text)
    }
    const [checked, setChecked] = useState(false)

    const [form, setForm] = useState({
        nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 8, md: 8, sm: 12 } },
        setor_id_transbordo: { name: "setor_id_transbordo", type: "select", options: [], label: translate("Setor de transbordo"), placeholder: translate("Nenhum setor selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
        descricao: { name: "descricao", type: "textarea", label: translate("Descrição"), placeholder: translate("Descreva o setor"), validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        grupo_horario_id: { name: "grupo_horario_id", type: "select", options: [], label: translate("Grupo de Horário"), placeholder: translate("Nenhum grupo selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
        grupo_horario_id_excecao: { name: "grupo_horario_id_excecao", type: "select", options: [], label: translate("Grupo de Excessão de Horário"), placeholder: translate("Nenhum grupo selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
        configuracoes: {
            label: translate("Configurações"), fields: {
                distribuicao_atendimentos: { name: "configuracoes['distribuicao_atendimentos']", type: "select", options: [{ label: translate("Todos agentes"), value: translate("todos") }, { label: translate("Com menos atendimentos"), value: "menos_atendimentos" }, { label: translate("Sequencial"), value: "sequencial" }], label: translate("Distribuição de atendimentos"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
                historico_conversa: { name: "configuracoes['historico_conversa']", type: "select", options: [{ label: translate("Geral"), value: "geral" }, { label: translate("Sessão atual"), value: "sessao_atual" }, { label: translate("Sessões da equipe"), value: "sessoes_equipe" }], label: translate("Histórico"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
                tempo_inatividade_sessao: { name: "configuracoes['tempo_inatividade_sessao']", type: "number", label: translate("Limite de inatividade de sessão"), placeholder: translate("Valor em minutos"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 } },
                espera_transferencia_status: { name: "configuracoes['espera_transferencia_status']", type: "select", options: [{ label: translate("Não"), value: "0" }, { label: translate("Sim"), value: "1" }], label: translate("Limite de transferência"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 }, onChange: (e) => { alterouLimiteTransferencia(e.target.value) } },
                espera_transferencia_setor_tipo: { name: "configuracoes['espera_transferencia_setor_tipo']", type: "select", options: [{ label: translate("Segundos"), value: "segundos" }, { label: translate("Minutos"), value: "minutos" }, { label: translate("Horas"), value: "horas" }], label: translate("Tipo de tempo"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 }, display: false },
                espera_transferencia_tempo: { name: "configuracoes['espera_transferencia_tempo']", type: "number", label: translate("Tempo"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 }, display: false },
                mensagem_nenhum_agente: { name: "configuracoes['mensagem_nenhum_agente']", type: "textarea", label: translate("Mensagem quando não houver agentes"), placeholder: translate("Digite alguma mensagem"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
                mensagem_encerramento_sessao: { name: "configuracoes['mensagem_encerramento_sessao']", type: "textarea", label: translate("Mensagem de encerramento"), placeholder: translate("Digite alguma mensagem"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
                permissao_de_setor_total: { label: translate("Permissões"), component: <div>
                  <br/>
                    <FormGroup check>
                      <Input id="permissao_de_setor_total" name="permissao_de_setor_total" type="checkbox" onChange={(e) => setChecked(e.target.checked)} />
                      <Label check>{translate("Permissão Total")}</Label>
                    </FormGroup>
                  <br/>
                </div>},
                permissoes: { fields: {} }
            }
        }
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        start()
    }, [values])

    const start = async () => {

        if (values) {
            try {
                console.log("VALUESSS", values)

                let newJson = { ...form }
                Object.keys(newJson).forEach(key => {
                    newJson[key] = { ...newJson[key], value: values[key] ? `${values[key]}` : null }
                })
                console.log("CONFIGURAÇÕES", values.configuracoes, newJson)

                /*if (values.configuracoes) {
                    console.log("CONFIGURAÇÕES", values.configuracoes, newJson)

                    for (let key of Object.keys(values.configuracoes)) {
                        let config = values.configuracoes[key]
                        newJson.configuracoes.fields[key] = { ...newJson.configuracoes.fields[key], value: config.valor }
                    }
                }*/

                let setores = await getSetores()

                if (values.permissoes) {
                    console.log("PERMISSÕES", values.permissoes)

                    // Separar permissões subcategorias
                    let subcategorias = []
                    for (let permissao_de_setor of values.permissoes) {
                        let encontrou = false
                        for (let sub of subcategorias) {
                            if (sub.id == permissao_de_setor.permissao_de_setor_subcategoria_id) {
                                encontrou = true
                                break
                            }
                        }
                        if (!encontrou) subcategorias.push({ id: permissao_de_setor.permissao_de_setor_subcategoria_id, nome: permissao_de_setor.permissao_de_setor_subcategoria_nome, permissoes: [] })
                    }

                    // Colocar as permissões nas subcategorias
                    for (let permissao_de_setor of values.permissoes) {
                        for (let sub of subcategorias) {
                            if (sub.id == permissao_de_setor.permissao_de_setor_subcategoria_id) {
                                sub.permissoes.push(permissao_de_setor)
                                break
                            }
                        }
                    }

                    console.log("SUBCATEGORIAS", subcategorias)

                    let size = { lg: 4, md: 4, sm: 4 }
                    if (subcategorias.length == 1) size = { lg: 12, md: 12, sm: 12 }
                    else if (subcategorias.length >= 2) size = { lg: 6, md: 6, sm: 12 }
                    //else if (subcategorias.length >= 3) size = { lg: 4, md: 4, sm: 12 }

                    subcategorias.forEach(sub => {
                        let options = []
                        sub.permissoes.forEach(perm => { options.push({ label: perm.nome, value: perm.id, help: perm.descricao, checked: perm.permissao_de_setor === 1 }) })
                        newJson.configuracoes.fields.permissoes.fields[`permissao_de_setor_subcategoria[${sub.id}]`] = { name: `permissoes[${sub.id}]`, options: options, disabled: checked, label: sub.nome, type: "checkbox-group", size: size, direction: "column" }
                    })
                }

                let options = [{ label: translate("Nenhum"), value: null }]
                for (let s of setores) {
                    if (s.id != values.id)
                        options.push({ label: s.nome, value: s.id })
                }

                if (options.length == 0) options = [{ label: translate("Nenhum setor"), value: null }]

                let gruposDeHorarios = await getGruposDeHorarios()

                let optionsGruposDeHorarios = [{ label: translate("Nenhum"), value: null }]
                for (let g of gruposDeHorarios) {
                    if (values.grupo_horario_id == g.id) {
                        optionsGruposDeHorarios[0] = { label: g.nome, value: g.id }
                    } else {
                        optionsGruposDeHorarios.push({ label: g.nome, value: g.id })
                    }
                }

                if (optionsGruposDeHorarios.length == 0) optionsGruposDeHorarios = [{ label: translate("Nenhum grupo de horário"), value: null }]

                let optionsGruposDeHorariosExcecao = [{ label: translate("Nenhum"), value: null }]
                for (let g of gruposDeHorarios) {
                    if (values.grupo_horario_id_excecao == g.id) {
                        optionsGruposDeHorariosExcecao[0] = { label: g.nome, value: g.id }
                    } else {
                        optionsGruposDeHorariosExcecao.push({ label: g.nome, value: g.id })
                    }
                }

                if (optionsGruposDeHorariosExcecao.length == 0) optionsGruposDeHorariosExcecao = [{ label: translate("Nenhum grupo de horário"), value: null }]

                newJson = {
                    ...newJson,
                    setor_id_transbordo: { ...newJson.setor_id_transbordo, options: options, disabled: options.length == 0 },
                    grupo_horario_id: { ...form.grupo_horario_id, options: optionsGruposDeHorarios, disabled: optionsGruposDeHorarios.length == 0 },
                    grupo_horario_id_excecao: { ...form.grupo_horario_id_excecao, options: optionsGruposDeHorariosExcecao, disabled: optionsGruposDeHorariosExcecao.length == 0 }
                }

                setForm(newJson)
            } catch (error) {
                console.log("ERRO", error)
            }
        }
    }

    const alterouLimiteTransferencia = (val) => {
        let display = val == "1"
        console.log("DISPLAY", display, form, form.configuracoes.fields)
        setForm({
            ...form,
            configuracoes: {
                ...form.configuracoes,
                fields: {
                    ...form.configuracoes.fields,
                    espera_transferencia_setor_tipo: { ...form.configuracoes.fields.espera_transferencia_setor_tipo, display: display },
                    espera_transferencia_tempo: { ...form.configuracoes.fields.espera_transferencia_tempo, display: display, validade: display ? { required: { value: true } } : {} }
                }
            }
        })
    }

    const handleSubmit = async (event, errors, val) => {
        try {
            console.log("{ handleSubmit }", errors, val)

            let data = val

            if (data.permissoes) {
              let permissoes = []
              Object.keys(data.permissoes).forEach(perm => {
                  permissoes = permissoes.concat(data.permissoes[perm])
              })

              data.permissao_total = checked
              data.permissoes = {}
              values.permissoes.forEach(perm => {
                  data.permissoes[perm.id] = permissoes.indexOf(perm.id) >= 0
              })
            }

            const setor = await putSetor(values.id, data)

            console.log("USUARIO ATUALIZADO", setor)

            if (refresh) refresh(setor, "edit") // Passar setor para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Setor")} ${setor.nome} ${translate("atualizado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalEdicaoSetor ERROR", error, error.result, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Atualizar Setor")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
