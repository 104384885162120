import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Carregando from "../../../components/Carregando"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ModalCadastroCampoAdicional from "../../../components/modals/campo-adicional/ModalCadastroCampoAdicional"
import ModalEdicaoCampoAdicional from "../../../components/modals/campo-adicional/ModalEdicaoCampoAdicional"
import PermissaoContext from "../../../contexts/PermissaoContext"
import { deleteCampoAdicional, getCamposAdicionais } from "../../../services/campos-adicionais"
import { getUsers } from "../../../store/contacts/actions"
import CamposAdicionaisListaColunas from "./CamposAdicionaisColunas"

const CamposAdicionaisLista = props => {
  function translate (text) {
    return props.t(text)
  }

  const { users, onGetUsers } = props
  const [list, setList] = useState([])
  const [listView, setListView] = useState([])
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: list.length,
    custom: true
  })
  const { SearchBar } = Search

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const [modalCadastroOpened, setModalCadastroOpened] = useState(false)
  const [modalEdicaoOpened, setModalEdicaoOpened] = useState(null)

  const [modalUsuariosCamposAdicionaisIsOpened, setModalUsuariosCamposAdicionaisIsOpened] = useState(false)
  const [modalUsuariosCamposAdicionaisOpened, setModalUsuariosCamposAdicionaisOpened] = useState(null)

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const { permissoes, isLoadingPermissoes } = useContext(PermissaoContext)

  useEffect(() => {
    buscarCamposAdicionais()
  }, [])

  useEffect(() => {
    setModalUsuariosCamposAdicionaisIsOpened(modalUsuariosCamposAdicionaisOpened != null)
  }, [modalUsuariosCamposAdicionaisOpened])

  useEffect(() => {
    if (!isEmpty(users)) {
      setList(users)
    }
  }, [users])

  const buscarCamposAdicionais = async () => {
    try {
      const campos_adicionais = await getCamposAdicionais()

      setList(campos_adicionais)
      setListView(campos_adicionais)
    } catch (error) {
    }
  }

  const removerCampoAdicional = async (campo_adicional) => {
    try {
      console.log("removerCampoAdicional", campo_adicional)
      setSweetAlert({
        ...sweetAlert,
        show: true,
        title: translate("Atenção!"),
        text: `${translate("Tem certeza que deseja excluir o campo_adicional")} ${campo_adicional.nome}?`,
        showCancel: true,
        cancelBtnBsText: translate("Não"),
        cancelBtnBsStyle: "danger",
        warning: true,
        onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
        onConfirm: async () => {
          setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: translate("Estamos removendo o campo_adicional") })
          try {
            let retorno = await deleteCampoAdicional(campo_adicional.id)
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: translate("Sucesso"), text: retorno || translate("Campo Adicional removido com sucesso!"), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
            handleRefresh(campo_adicional, "remove")
          } catch (error) {
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: translate("Ooops"), text: error.message || translate("Ocorreu um erro."), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleRefresh = async (campo_adicional, action = "add") => {
    console.log("{ handleRefresh }", action, list, campo_adicional)
    if (action == "add") {
      let newArray = [...list, campo_adicional]
      setList(newArray)
      setPageOptions({ ...pageOptions, totalSize: list.length + 1 })
      handleTableChange(null, { page, searchText }, newArray)
    } else if (action == "edit") {
      let newArray = [...list]
      for (let i = 0; i < newArray.length; i++) {
        const el = newArray[i]
        if (el.id == campo_adicional.id) {
          newArray[i] = campo_adicional
          break
        }
      }
      handleTableChange(null, { page, searchText }, newArray)
    } else if (action == "remove") {
      let newArray = list.filter(user => user.id != campo_adicional.id)
      setList(newArray)
      setPageOptions({ ...pageOptions, totalSize: newArray.length - 1 })
      handleTableChange(null, { page, searchText }, newArray)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }, data = null) => {
    const dados = data || list
    console.log("{ handleTableChange }", page, searchText, dados)
    setPage(page)
    setSearchText(searchText)

    let inicio = pageOptions.sizePerPage * page - pageOptions.sizePerPage
    let fim = pageOptions.sizePerPage * page
    if (fim > dados.length) fim = dados.length

    setListView(
      dados.slice(inicio, fim).filter(user =>
        Object.keys(user).some(
          key =>
            typeof user[key] === "string" &&
            user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={translate("Campos Adicionais")} breadcrumbItem={translate("Lista de Campos Adicionais")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {isLoadingPermissoes ? <Carregando /> :
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={listView || []}
                          columns={CamposAdicionaisListaColunas(removerCampoAdicional, setModalEdicaoOpened, permissoes, setModalUsuariosCamposAdicionaisOpened, translate)}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="row mb-2">
                                <Col md={6}>
                                  {permissoes.campos_adicionais_gerenciamento && <div className="mb-3">
                                    <Link to="#" className="btn btn-success waves-effect waves-light" onClick={() => setModalCadastroOpened(true)}><i className="mdi mdi-plus me-2"></i>{translate("Novo")}</Link>
                                  </div>}
                                </Col>

                                <Col md={6}>
                                  <div className="form-inline float-md-end mb-3">
                                    <div className="search-box ms-2">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="mdi mdi-magnify search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive mb-4">
                                    <BootstrapTable
                                      selectRow={selectRowProp}
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={"table table-centered table-nowrap mb-0"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div className="float-sm-end">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalCadastroCampoAdicional opened={modalCadastroOpened} setOpened={setModalCadastroOpened} refresh={handleRefresh} />
      <ModalEdicaoCampoAdicional opened={modalEdicaoOpened} setOpened={setModalEdicaoOpened} refresh={handleRefresh} values={modalEdicaoOpened} />

      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
}

CamposAdicionaisLista.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CamposAdicionaisLista)))
