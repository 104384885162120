import classnames from "classnames"
import React, { useState } from "react"
import { withTranslation } from "react-i18next"
import { Col, Container, Row } from "reactstrap"
import Configuracoes from "../../components/Aplicacao/Configuracoes"
import Estilo from "../../components/Aplicacao/Estilo"
// import KeepAlive from "../../components/Aplicacao/KeepAlive"
import Mensagens from "../../components/Aplicacao/Mensagens"
import "./styles.scss"

export default withTranslation()(function Aplicacao(props) {
  function translate (text) {
    return props.t(text)
  }

  const [tabSelected, setTabSelected] = useState("configuracoes")

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="resumo-chats-container">
            <div className="nav" style={{ marginBottom: 10 }}>
              <div className={classnames({ navLink: true, active: tabSelected === "configuracoes" })} onClick={() => setTabSelected("configuracoes")}>
                <span>{translate("Configurações de Aplicação")}</span>
              </div>
              <div className={classnames({ navLink: true, active: tabSelected === "mensagens" })} onClick={() => setTabSelected("mensagens")}>
                <span>{translate("Mensagens Automáticas")}</span>
              </div>
              <div className={classnames({ navLink: true, active: tabSelected === "keep" })} onClick={() => setTabSelected("keep")}>
                <span>{translate("Keep-alive")}</span>
              </div>
              <div className={classnames({ navLink: true, active: tabSelected === "estilo" })} onClick={() => setTabSelected("estilo")}>
                <span>{translate("Estilo de página de atendente")}</span>
              </div>
            </div>
            <Col lg={12}>
              {tabSelected === "configuracoes" && < Configuracoes />}
              {tabSelected === "mensagens" && <Mensagens />}
              {/* {tabSelected === "keep" && <KeepAlive />} */}
              {tabSelected === "estilo" && <Estilo />}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
})
