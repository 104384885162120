import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { getCamposAdicionais } from "../../../services/campos-adicionais"
import { putCanal } from "../../../services/canais"
import { getFluxos } from "../../../services/fluxos"
import Carregando from "../../Carregando"
import DefaultForm from "../../form/DefaultForm"






export default withTranslation()(function ModalEdicaoCanal({ opened, setOpened, refresh, values, remover, ...props }) {
  const [loading, setLoading] = useState(true)

  function translate(text) {
    return props.t(text)
  }

  const [form, setForm] = useState({
    tabs: {
      type: "tabs",
      tabs: {
        geral: {
          title: translate("Geral"),
          fields: {
            token: { type: "clipboard", label: translate("Token"), size: { lg: 12, md: 12, sm: 12 } },
            nome: { type: "info" /* text */, name: "nome", label: translate("Nome"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
            fluxo_id: { type: "info" /* select */, name: "fluxo_id", label: translate("Fluxo"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } }
          }
        }
      }
    }
  })

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const [atualizarShow, setAtualizarShow] = useState(false)

  useEffect(() => {
    start()
  }, [values])

  const start = async () => {
    console.log("START", values)
    if (values) {
      try {
        // Alimentar select de fluxos
        let fluxos = await getFluxos()

        let optionsFluxos = []

        for (let fluxo of fluxos) {
          optionsFluxos.push({ label: fluxo.nome, value: fluxo.id })
        }

        let json = {
          ...form,
          tabs: {
            ...form.tabs,
            tabs: {
              ...form.tabs.tabs,
              geral: {
                ...form.tabs.tabs.geral,
                fields: {
                  token: { ...form.tabs.tabs.geral.fields.token, value: values.token },
                  nome: { ...form.tabs.tabs.geral.fields.nome, value: values.nome },
                  fluxo_id: { ...form.tabs.tabs.geral.fields.fluxo_id, options: optionsFluxos, value: values.fluxo_nome },
                }
              }
            }
          }
        }

        if (values.canal_tipo_id == "webchat") {
          let campos_adicionais = await getCamposAdicionais()
          let optionsCamposAdicionais = {}

          if (campos_adicionais.length > 0) {
            optionsCamposAdicionais = {
              label: translate("Campos Adicionais"),
              options: []
            }

            for (let campo of campos_adicionais) {
              optionsCamposAdicionais.options.push({ label: campo.nome, value: `adicional_${campo.id}` })
            }
          }

          let optionsCamposIniciais = [
            {
              label: translate("Contato"),
              options: [
                { label: translate("Nome"), value: "contato_nome" }
              ]
            },
            { ...optionsCamposAdicionais }
          ]
          let selecteds = []
          for (let campo of values.campos_iniciais) {
            selecteds.push({ label: campo.campo_adicional_id ? campo.campo_adicional_nome : campo.campo_contato_nome, value: campo.campo_adicional_id ? `adicional_${campo.campo_adicional_id}` : `contato_${campo.campo_contato}` })
          }

          json = {
            ...json,
            tabs: {
              ...json.tabs,
              tabs: {
                ...json.tabs.tabs,
                configuracoes: {
                  title: translate("Configurações do Webchat"),
                  fields: {
                    webchat_titulo: { name: "configuracoes['webchat_titulo']", type: "info", label: translate("Título"), placeholder: translate("Informe o título"), value: values?.configuracoes?.webchat_titulo, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
                    webchat_subtitulo: { name: "configuracoes['webchat_subtitulo']", type: "info", label: translate("Subtítulo"), placeholder: translate("Informe o subtítulo"), value: values?.configuracoes?.webchat_subtitulo, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
                    webchat_cor_primaria: { name: "configuracoes['webchat_cor_primaria']", type: "info", label: translate("Cor primária"), placeholder: "#000000", value: values?.configuracoes?.webchat_cor_primaria, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 3, md: 3, sm: 12 } },
                    webchat_cor_secundaria: { name: "configuracoes['webchat_cor_secundaria']", type: "info", label: translate("Cor secundária"), placeholder: "#000000", value: values?.configuracoes?.webchat_cor_secundaria, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 3, md: 3, sm: 12 } },
                    webchat_cor_mensagem_envio: { name: "configuracoes['webchat_cor_mensagem_envio']", type: "info", label: translate("Cor mens. envio"), placeholder: "#000000", value: values?.configuracoes?.webchat_cor_mensagem_envio, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 3, md: 3, sm: 12 } },
                    webchat_cor_mensagem_recebida: { name: "configuracoes['webchat_cor_mensagem_recebida']", type: "info", label: translate("Cor mens. recebida"), placeholder: "#000000", value: values?.configuracoes?.webchat_cor_mensagem_recebida, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 3, md: 3, sm: 12 } },
                    webchat_opcao_emoji: { name: "configuracoes['webchat_opcao_emoji']", type: "info", options: [{ label: translate("Sim"), value: "1" }, { label: translate("Não"), value: "0" }], value: +values?.configuracoes?.webchat_opcao_emoji ? translate("Sim") : translate("Não"), label: translate("Opção de Emoji"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 } },
                    webchat_opcao_anexo: { name: "configuracoes['webchat_opcao_anexo']", type: "info", options: [{ label: translate("Sim"), value: "1" }, { label: translate("Não"), value: "0" }], value: +values?.configuracoes?.webchat_opcao_anexo ? translate("Sim") : translate("Não"), label: translate("Opção de Anexo"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 } },
                    webchat_opcao_audio: { name: "configuracoes['webchat_opcao_audio']", type: "info", options: [{ label: translate("Sim"), value: "1" }, { label: translate("Não"), value: "0" }], value: +values?.configuracoes?.webchat_opcao_audio ? translate("Sim") : translate("Não"), label: translate("Opção de Áudio"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 } }
                  }
                },
                campos_iniciais: {
                  title: translate("Campos Adicionais"),
                  fields: {
                    campos_iniciais: { name: "campos_iniciais", type: "multiselect", label: translate("Campos"), isMulti: true, selecteds: selecteds, options: optionsCamposIniciais, errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 12, md: 12, sm: 12 } }
                  }
                }
              }
            }
          }
        }

        setForm(json)
      } catch (error) {
        console.log("ERRO", error)
      }
    }

    setLoading(() => false)
  }

  const handleSubmit = async (event, errors, val) => {
    try {
      console.log("{ handleSubmit }", errors, val)
      if (errors.length > 0) return

      let data = val

      const canal = await putCanal(values.id, data)

      console.log("USUARIO ATUALIZADO", canal)

      if (refresh) refresh(canal, "edit") // Passar canal para componente pai

      setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `Canal ${canal.nome} atualizado com sucesso.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
    } catch (error) {
      console.error("ModalEdicaoCanal ERROR", error, error.result, error.response)

      setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
    }
  }

  const handleAtualizarShow = (status) => {
    setAtualizarShow(status)
    setForm(form => {
      form = {
        ...form,
        tabs: {
          ...form.tabs,
          tabs: {
            ...form.tabs.tabs,
            geral: {
              ...form.tabs.tabs.geral,
              fields: {
                ...form.tabs.tabs.geral.fields,
                nome: { ...form.tabs.tabs.geral.fields.nome, type: status ? "text" : "info" },
                fluxo_id: { ...form.tabs.tabs.geral.fields.fluxo_id, type: status ? "select" : "info", value: status ? values.fluxo_id : values.fluxo_nome },
              }
            },
            configuracoes: {
              ...form.tabs.tabs.configuracoes,
              fields: {
                webchat_titulo: { ...form.tabs.tabs.configuracoes.fields.webchat_titulo, type: status ? "text" : "info" },
                webchat_subtitulo: { ...form.tabs.tabs.configuracoes.fields.webchat_subtitulo, type: status ? "text" : "info" },
                webchat_cor_primaria: { ...form.tabs.tabs.configuracoes.fields.webchat_cor_primaria, type: status ? "color" : "info" },
                webchat_cor_secundaria: { ...form.tabs.tabs.configuracoes.fields.webchat_cor_secundaria, type: status ? "color" : "info" },
                webchat_cor_mensagem_envio: { ...form.tabs.tabs.configuracoes.fields.webchat_cor_mensagem_envio, type: status ? "color" : "info" },
                webchat_cor_mensagem_recebida: { ...form.tabs.tabs.configuracoes.fields.webchat_cor_mensagem_recebida, type: status ? "color" : "info" },
                webchat_opcao_emoji: { ...form.tabs.tabs.configuracoes.fields.webchat_opcao_emoji, type: status ? "select" : "info", value: status ? values?.configuracoes?.webchat_opcao_emoji : values?.configuracoes?.webchat_opcao_emoji ? translate("Sim") : translate("Não") },
                webchat_opcao_anexo: { ...form.tabs.tabs.configuracoes.fields.webchat_opcao_anexo, type: status ? "select" : "info", value: status ? values?.configuracoes?.webchat_opcao_anexo : values?.configuracoes?.webchat_opcao_anexo ? translate("Sim") : translate("Não") },
                webchat_opcao_audio: { ...form.tabs.tabs.configuracoes.fields.webchat_opcao_audio, type: status ? "select" : "info", value: status ? values?.configuracoes?.webchat_opcao_audio : values?.configuracoes?.webchat_opcao_audio ? translate("Sim") : translate("Não") }
              }
            }
          }
        }
      }
      return form
    })
  }

  return (
    <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
      <ModalHeader toggle={() => { setOpened(!opened) }}>
        {translate("Atualizar Canal")}
      </ModalHeader>
      <ModalBody>
        {loading && <Carregando />}

        {!loading &&
          <DefaultForm fields={form} handleSubmit={handleSubmit} buttonSubmit={atualizarShow} buttonText={translate("Atualizar")} buttons={[
            { text: translate("Remover"), color: "danger", onClick: () => { remover(values) }, show: !atualizarShow },
            { text: translate("Atualizar"), color: "warning", onClick: () => { handleAtualizarShow(true) }, show: !atualizarShow },
            { text: translate("Cancelar"), color: "danger", onClick: () => { handleAtualizarShow(false) }, show: atualizarShow }
          ]} />
        }

        {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
      </ModalBody>
    </Modal>
  )
})
