import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { withTranslation } from "react-i18next"
import { Input, Label } from "reactstrap"
import { getMensagensAutomaticasAplicacao, putMensagensAutomaticasAplicacao } from "../../../services/aplicacao"
import Carregando from "../../Carregando"
import DefaultForm from "../../form/DefaultForm"
import "./styles.scss"

const tipos = [
  { value: "Texto", label: "Texto" },
]

export default withTranslation()(function Mensagens(props, values) {
  const [loading, setLoading] = useState(true)
  const [mensagens, setMensagens] = useState(false)
  const [opcaoInvalida, setOpcaoInvalida] = useState(false)
  const [atendimentoIniciado, setAtendimentoIniciado] = useState(false)
  const [limiteDeTransbordo, setLimiteDeTransbordo] = useState(false)
  const [atendimentoFinalizado, setAtendimentoFinalizado] = useState(false)
  const [agenteIndisponivelPorCodenome, setAgenteIndisponivelPorCodenome] = useState(false)
  const [administradorSetor, setAdministradorSetor] = useState(false)
  const [atendenteOutroAtendente, setAtendenteOutroAtendente] = useState(false)
  const [atendenteOutroSetor, setAtendenteOutroSetor] = useState(false)
  const [administradorAtendente, setAdministradorAtendente] = useState(false)

  function translate(text) {
    return props.t(text)
  }

  const [form, setForm] = useState({
    mensagens_de_atendimento: {
      label: translate("Mensagens de Atendimento"), fields: {
        opcao_invalida_ativa: { component:
          <Label check style={{ marginBottom: 14, marginTop: 16 }}>
            <Input value={opcaoInvalida} id="opcao_invalida_ativa" name="opcao_invalida_ativa" type="checkbox" onChange={(e) => setOpcaoInvalida(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
            Mensagem de opção inválida
          </Label>
        },
        opcao_invalida_tipo: { name: "opcao_invalida_tipo", type: "select", value: tipos[0].value, options: tipos, size: { lg: 4, md: 4, sm: 12 } },
        opcao_invalida_mensagem: { name: "opcao_invalida_mensagem", type: "textarea", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        atendimento_iniciado_ativa: { component:
          <Label check style={{ marginBottom: 14, marginTop: 16 }}>
            <Input value={atendimentoIniciado} id="atendimento_iniciado_ativa" name="atendimento_iniciado_ativa" type="checkbox" onChange={(e) => setAtendimentoIniciado(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
            Mensagem de atendimento iniciado
          </Label>
        },
        atendimento_iniciado_tipo: { name: "atendimento_iniciado_tipo", type: "select", value: tipos[0].value, options: tipos, size: { lg: 4, md: 4, sm: 12 } },
        atendimento_iniciado_mensagem: { name: "atendimento_iniciado_mensagem", type: "textarea", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        limite_de_transbordo_ativa: { component:
          <Label check style={{ marginBottom: 14, marginTop: 16 }}>
            <Input value={limiteDeTransbordo} id="limite_de_transbordo_ativa" name="limite_de_transbordo_ativa" type="checkbox" onChange={(e) => setLimiteDeTransbordo(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
            Mensagem do limite de transbordo
          </Label>
        },
        limite_de_transbordo_tipo: { name: "limite_de_transbordo_tipo", type: "select", value: tipos[0].value, options: tipos, size: { lg: 4, md: 4, sm: 12 } },
        limite_de_transbordo_mensagem: { name: "limite_de_transbordo_mensagem", type: "textarea", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        atendimento_finalizado_ativa: { component:
          <Label check style={{ marginBottom: 14, marginTop: 16 }}>
            <Input value={atendimentoFinalizado} id="atendimento_finalizado_ativa" name="atendimento_finalizado_ativa" type="checkbox" onChange={(e) => setAtendimentoFinalizado(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
            Mensagem de atendimento finalizado
          </Label>
        },
        atendimento_finalizado_tipo: { name: "atendimento_finalizado_tipo", type: "select", value: tipos[0].value, options: tipos, size: { lg: 4, md: 4, sm: 12 } },
        atendimento_finalizado_mensagem: { name: "atendimento_finalizado_mensagem", type: "textarea", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        agente_indisponivel_por_codenome_ativa: { component:
          <Label check style={{ marginBottom: 14, marginTop: 16 }}>
            <Input value={agenteIndisponivelPorCodenome} id="agente_indisponivel_por_codenome_ativa" name="agente_indisponivel_por_codenome_ativa" type="checkbox" onChange={(e) => setAgenteIndisponivelPorCodenome(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
            Agente indisponível por codinome
          </Label>
        },
        agente_indisponivel_por_codenome_tipo: { name: "agente_indisponivel_por_codenome_tipo", type: "select", value: tipos[0].value, options: tipos, size: { lg: 4, md: 4, sm: 12 } },
        agente_indisponivel_por_codenome_mensagem: { name: "agente_indisponivel_por_codenome_mensagem", type: "textarea", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
      }
    },
    mensagens_de_transferencia: {
      label: translate("Mensagens de Transferência"), fields: {
        administrador_setor_ativa: { component:
          <Label check style={{ marginBottom: 14, marginTop: 16 }}>
            <Input value={administradorSetor} id="administrador_setor_ativa" name="administrador_setor_ativa" type="checkbox" onChange={(e) => setAdministradorSetor(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
            Transferência de administrador para setor
          </Label>
        },
        administrador_setor_tipo: { name: "administrador_setor_tipo", type: "select", value: tipos[0].value, options: tipos, size: { lg: 4, md: 4, sm: 12 } },
        administrador_setor_mensagem: { name: "administrador_setor_mensagem", type: "textarea", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        atendente_outro_atendente_ativa: { component:
          <Label check style={{ marginBottom: 14, marginTop: 16 }}>
            <Input value={atendenteOutroAtendente} id="atendente_outro_atendente_ativa" name="atendente_outro_atendente_ativa" type="checkbox" onChange={(e) => setAtendenteOutroAtendente(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
            Transferência de atendente para outro atendente
          </Label>
        },
        atendente_outro_atendente_tipo: { name: "atendente_outro_atendente_tipo", type: "select", value: tipos[0].value, options: tipos, size: { lg: 4, md: 4, sm: 12 } },
        atendente_outro_atendente_mensagem: { name: "atendente_outro_atendente_mensagem", type: "textarea", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        atendente_outro_setor_ativa: { component:
          <Label check style={{ marginBottom: 14, marginTop: 16 }}>
            <Input value={atendenteOutroSetor} id="atendente_outro_setor_ativa" name="atendente_outro_setor_ativa" type="checkbox" onChange={(e) => setAtendenteOutroSetor(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
            Transferência de atendente para outro setor
          </Label>
        },
        atendente_outro_setor_tipo: { name: "atendente_outro_setor_tipo", type: "select", value: tipos[0].value, options: tipos, size: { lg: 4, md: 4, sm: 12 } },
        atendente_outro_setor_mensagem: { name: "atendente_outro_setor_mensagem", type: "textarea", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        administrador_atendente_ativa: { component:
          <Label check style={{ marginBottom: 14, marginTop: 16 }}>
            <Input value={administradorAtendente} id="administrador_atendente_ativa" name="administrador_atendente_ativa" type="checkbox" onChange={(e) => setAdministradorAtendente(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
            Transferência de administrador para o atendente
          </Label>
        },
        administrador_atendente_tipo: { name: "administrador_atendente_tipo", type: "select", value: tipos[0].value, options: tipos, size: { lg: 4, md: 4, sm: 12 } },
        administrador_atendente_mensagem: { name: "administrador_atendente_mensagem", type: "textarea", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
      }
    }
  })

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const start = async () => {
    try {
      const mensagensAtual = await getMensagensAutomaticasAplicacao()
      setMensagens(mensagensAtual)
      let newJson = { ...form }
      Object.keys(newJson).forEach(key => {
        newJson[key] = { ...newJson[key], value: mensagensAtual[key] ? `${mensagensAtual[key]}` : null }
      })

      setForm(newJson)
    } catch (error) {
      console.log("ERRO", error)
    }

    setLoading(() => false)
  }

  const handleSubmit = async (event, errors, data) => {
    try {
      if (errors.length > 0) return

      data.opcao_invalida_ativa = opcaoInvalida
      data.atendimento_iniciado_ativa = atendimentoIniciado
      data.limite_de_transbordo_ativa = limiteDeTransbordo
      data.atendimento_finalizado_ativa = atendimentoFinalizado
      data.agente_indisponivel_por_codenome_ativa = agenteIndisponivelPorCodenome
      data.administrador_setor_ativa = administradorSetor
      data.atendente_outro_atendente_ativa = atendenteOutroAtendente
      data.atendente_outro_setor_ativa = atendenteOutroSetor
      data.administrador_atendente_ativa = administradorAtendente

      await putMensagensAutomaticasAplicacao(mensagens.id, data)

      setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `Configurações atualizada com sucesso.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); ; } })//setOpened(false); } })
    } catch (error) {
      setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
    }
  }

  useEffect(() => {
    start()
  }, [])

  return (
    <React.Fragment>
      {loading ? <Carregando />
      :
        <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />
      }

      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
})
