import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Carregando from "../../../components/Carregando"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import ModalCadatroGrupoDeHorario from "../../../components/modals/grupo-de-horario/ModalCadatroGrupoDeHorario"
import ModalEdicaoGrupoDeHorario from "../../../components/modals/grupo-de-horario/ModalEdicaoGrupoDeHorario"
import PermissaoContext from "../../../contexts/PermissaoContext"
import { deleteGrupoDeHorario, getGruposDeHorarios } from "../../../services/grupos-de-horarios"
import { getUsers } from "../../../store/contacts/actions"
import GruposDeHorariosListaColunas from "./GruposDeHorariosListaColunas"

const GruposDeHorariosLista = props => {
  function translate(text) {
    return props.t(text)
  }

  const { users } = props
  const [list, setList] = useState([])
  const [listView, setListView] = useState([])
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: list.length,
    custom: true
  })
  const { SearchBar } = Search

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const [modalCadastroOpened, setModalCadastroOpened] = useState(false)
  const [modalEdicaoOpened, setModalEdicaoOpened] = useState(null)

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const { permissoes, isLoadingPermissoes } = useContext(PermissaoContext)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    buscarGruposDeHorarios()
  }, [])

  useEffect(() => {
    if (!isEmpty(users)) {
      setList(users)
    }
  }, [users])

  const buscarGruposDeHorarios = async () => {
    try {
      setLoading(() => true)

      const gruposDeHorarios = await getGruposDeHorarios()

      setList(gruposDeHorarios)
      setListView(gruposDeHorarios)

      setLoading(() => false)
    } catch (error) {
      setLoading(() => false)
    }
  }

  const removerGrupoDeHorarios = async (grupoDeHorarios) => {
    try {
      setSweetAlert({
        ...sweetAlert,
        show: true,
        title: translate("Atenção!"),
        text: `${translate("Tem certeza que deseja excluir o grupo de horários")} ${grupoDeHorarios.nome}?`,
        showCancel: true,
        cancelBtnBsText: translate("Não"),
        cancelBtnBsStyle: "danger",
        warning: true,
        onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
        onConfirm: async () => {
          setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: translate("Estamos removendo o grupo de horários") })
          try {
            let retorno = await deleteGrupoDeHorario(grupoDeHorarios.id)
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: translate("Sucesso"), text: retorno || translate("Grupo de horários removido com sucesso!"), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
            handleRefresh(grupoDeHorarios, "remove")
          } catch (error) {
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: translate("Ooops"), text: error.message || translate("Ocorreu um erro."), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleRefresh = async (grupoDeHorarios, action = "add") => {
    console.log("{ handleRefresh }", action, list, grupoDeHorarios)
    if (action === "add") {
      let newArray = [...list, grupoDeHorarios]
      setList(newArray)
      setPageOptions({ ...pageOptions, totalSize: list.length + 1 })
      handleTableChange(null, { page, searchText }, newArray)
    } else if (action === "edit") {
      let newArray = [...list]
      for (let i = 0; i < newArray.length; i++) {
        const el = newArray[i]
        if (el.id === grupoDeHorarios.id) {
          newArray[i] = grupoDeHorarios
          break
        }
      }
      handleTableChange(null, { page, searchText }, newArray)
    } else if (action === "remove") {
      let newArray = list.filter(user => user.id !== grupoDeHorarios.id)
      setList(newArray)
      setPageOptions({ ...pageOptions, totalSize: newArray.length - 1 })
      handleTableChange(null, { page, searchText }, newArray)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }, data = null) => {
    const dados = data || list
    console.log("{ handleTableChange }", page, searchText, dados)
    setPage(page)
    setSearchText(searchText)

    let inicio = pageOptions.sizePerPage * page - pageOptions.sizePerPage
    let fim = pageOptions.sizePerPage * page
    if (fim > dados.length) fim = dados.length

    setListView(
      dados.slice(inicio, fim).filter(user =>
        Object.keys(user).some(
          key =>
            typeof user[key] === "string" &&
            user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={translate("Grupos de Horários")} breadcrumbItem={translate("Lista de Grupos de Horários")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {(isLoadingPermissoes || loading) ? <Carregando /> :
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={listView || []}
                          columns={GruposDeHorariosListaColunas(removerGrupoDeHorarios, setModalEdicaoOpened, permissoes, translate)}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="row mb-2">
                                <Col md={6}>
                                  {permissoes.setores_gerenciamento && <div className="mb-3">
                                    <Link to="#" className="btn btn-success waves-effect waves-light" onClick={() => setModalCadastroOpened(true)}><i className="mdi mdi-plus me-2"></i>{translate("Novo")}</Link>
                                  </div>}
                                </Col>

                                <Col md={6}>
                                  <div className="form-inline float-md-end mb-3">
                                    <div className="search-box ms-2">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="mdi mdi-magnify search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive mb-4">
                                    <BootstrapTable
                                      selectRow={selectRowProp}
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={"table table-centered table-nowrap mb-0"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div className="float-sm-end">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalCadatroGrupoDeHorario opened={modalCadastroOpened} setOpened={setModalCadastroOpened} refresh={handleRefresh} values={undefined} />
      <ModalEdicaoGrupoDeHorario opened={modalEdicaoOpened} setOpened={setModalEdicaoOpened} refresh={handleRefresh} values={modalEdicaoOpened} />

      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
}

GruposDeHorariosLista.propTypes = {
  users: PropTypes.array,
}

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(GruposDeHorariosLista)))
