import React, { useEffect, useState } from "react";
//i18n
import { withTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";
import { v4 as uuidv4 } from 'uuid';
import LinhaHorario from "./LinhaHorario";

export default withTranslation()(function CadastroHorarios({ horarios: horariosDefault, style, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const [horarios, setHorarios] = useState([])

    useEffect(() => {
        console.log("USEEFFECT", horariosDefault)
        if (horariosDefault && Array.isArray(horariosDefault)) {
            setHorarios(() => horariosDefault)
        } else
            setHorarios(() => [{ id: uuidv4(), dia_inicio: null, dia_fim: null, dia_semana_inicio: null, dia_semana_fim: null, dia_mes_inicio: null, dia_mes_fim: null, hora_inicio: "", hora_fim: "" }])
    }, [horariosDefault])

    const remover = (id) => {
        setHorarios(horarios => horarios.map(horario => {
          if (horario.id === id) {
            return {
              ...horario,
              dia_inicio: null,
              dia_fim: null,
              dia_mes_inicio: null,
              dia_mes_fim: null,
              dia_semana_inicio: null,
              dia_semana_fim: null,
              hora_inicio: "",
              hora_fim: "",
              removed: true,
            }
          } else {
            return horario
          }
        }))
    }

    const adicionar = () => {
      const {
        id
      } = horarios[horarios.filter(item => item.removed != true).length-1]

      const add = (
        document.getElementById(`descricao-${id}`).value === "" ||
        document.getElementById(`dia-inicio-${id}`).value === "" ||
        document.getElementById(`dia-fim-${id}`).value === "" ||
        document.getElementById(`dia-semana-inicio-${id}`).value === "" ||
        document.getElementById(`dia-semana-fim-${id}`).value === "" ||
        document.getElementById(`horario-inicio-${id}`).value === "" ||
        document.getElementById(`horario-fim-${id}`).value === ""
      )

      !add && setHorarios([...horarios, { id: uuidv4(), dia_inicio: null, dia_fim: null, dia_semana_inicio: null, dia_semana_fim: null, hora_inicio: "", hora_fim: "" }])
    }

    return (
      <div style={style}>
        <Row>
          <Col lg={2}><label>{translate("Descrição")}</label></Col>
          <Col lg={2}><label>{translate("Dias")}</label></Col>
          <Col lg={2}><label>{translate("Meses")}</label></Col>
          <Col lg={2}><label>{translate("Dia da Semana")}</label></Col>
          <Col lg={1}><label>{translate("Horário Cheio")}</label></Col>
          <Col lg={2}><label>{translate("Horário")}</label></Col>
          <Col lg={1}></Col>
        </Row>
        {horarios.map((horario, index) => <LinhaHorario key={index} index={index} remover={remover} removeDisabled={horarios.length === 1} {...horario} />)}
        <Row>
          <Col lg={12} style={{ textAlign: "right", paddingBottom: 10 }}>
            <Button color="success" size="sm" onClick={() => adicionar()}>{translate("Novo")}</Button>
          </Col>
        </Row>
      </div>
    )
})
