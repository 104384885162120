import classnames from "classnames"
import React, { useCallback, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
import AvatarDefault from "../../assets/images/avatar-default.jpeg"
import { getRelatoriosAtivos } from "../../services/chats"
import { formatDataHora } from "../../services/formatService"
import Carregando from "../Carregando"
import "./styles.scss"

export default withTranslation()(function ResumoChats(props) {
  function translate (text) {
    return props.t(text)
  }

  const [tabSelected, setTabSelected] = useState("fases")
  const [relatorio, setRelatorio] = useState({})
  const [chats, setChats] = useState([])
  const [loading, setLoading] = useState(false)

  const montarRelatorio = useCallback((tab) => {
    setLoading(() => false)
    setRelatorio(chats[tab])
    setTabSelected(tab)
    setLoading(() => false)
  }, [chats]);

  const buscarRelatorio = useCallback(async () => {
    try {
      setLoading(() => true)
      const resultado = await getRelatoriosAtivos()
      setChats(resultado)
      setRelatorio(resultado["fases"])
      setLoading(() => false)
    } catch (error) {
      console.log("ERROR", error)
    } finally {
      setLoading(() => false)
    }
  }, []);

  useEffect(() => {
    buscarRelatorio()
  }, [buscarRelatorio])

  return (
    <Row className="resumo-chats-container">
      <div className="nav" style={{ marginBottom: 10 }}>
        <div className={classnames({ navLink: true, active: tabSelected === "fases" })} onClick={() => montarRelatorio("fases")}>
          <span>{translate("Fases")}</span>
        </div>
        <div className={classnames({ navLink: true, active: tabSelected === "atendentes" })} onClick={() => montarRelatorio("atendentes")}>
          <span>{translate("Atendentes")}</span>
        </div>
        <div className={classnames({ navLink: true, active: tabSelected === "setores" })} onClick={() => montarRelatorio("setores")}>
          <span>{translate("Setores")}</span>
        </div>
      </div>
      <Col lg={12}>
        {loading ?
          <Carregando /> :
          <Card>
            <CardBody>
              <Row>
                {relatorio && Object.keys(relatorio).map(key => (
                  <Col key={key} lg={12 / Object.keys(relatorio).length} className="coluna">
                    <p className="titulo">{tabSelected === "fases" ? translate(relatorio[key]?.titulo) : relatorio[key]?.titulo} <span>({relatorio[key]?.chats.length})</span></p>

                    {relatorio[key].chats.length === 0 && <p style={{ textAlign: "center" }}>{translate("Nenhum")}</p>}
                    {relatorio[key].chats.map(chat => (
                      <Link to={`/atendimentos/${chat.atendimento_id || chat.id}`} key={chat.id} className="chat-box">
                        <img
                          className="rounded-circle header-profile-user"
                          src={chat.contato_foto || AvatarDefault}
                          alt="avatar-default"
                        />
                        <div>
                          <p className="nome">{chat.contato_nome || <i>Sem nome</i>}</p>
                          {chat.setor_nome && <p className="setor">{chat.setor_nome}{chat.atendente_nome ? <i> - {chat.atendente_nome}</i> : ""}</p>}
                          <p className="datahora">{formatDataHora(chat.datahora_cadastro, "DD/MM/YYYY - HH:mm")}</p>
                        </div>
                      </Link>
                    ))}
                  </Col>
                ))}
              </Row>
            </CardBody>
          </Card>
        }
      </Col>
    </Row>
  )
})
