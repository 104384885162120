import React, { useState, createContext, useEffect } from 'react'

import { getPermissoesUsuario } from "../services/permissoes"

const PermissaoContext = createContext({})

export const PermissaoProvider = ({ children }) => {
    const [permissoes, setPermissoes] = useState({})
    const [isLoadingPermissoes, setIsLoadingPermissoes] = useState(true)

    useEffect(() => {
        console.log("kkkk")
        getPermissoes()
    }, [])

    const getPermissoes = async () => {
        let resultado = await getPermissoesUsuario()
        console.log("{ getPermissoes }", resultado)
        setPermissoes(resultado)
        setIsLoadingPermissoes(false)
    }

    return (
        <PermissaoContext.Provider value={{
            permissoes, setPermissoes,
            getPermissoes,
            isLoadingPermissoes
        }}>
            {children}
        </PermissaoContext.Provider>
    )
}

export default PermissaoContext
