import React from 'react';

import NodePersonalizado from './NodePersonalizado';

import "./style.scss"

export default function NodeHorario({ data, id, type, ...rest }) {
  return <NodePersonalizado
    key={id}
    id={id}
    label={data?.nome}
    data={data}
    type={type}
    handles={3}
    {...rest}
  />
}
