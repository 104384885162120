import React, { useEffect, useState, useContext } from "react"
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import DefaultForm from "../../../../components/form/DefaultForm"

import SweetAlert from "react-bootstrap-sweetalert"

import FluxoContext from "../../../../contexts/FluxoContext"

import operadoresCondicao from "../../../../values/operadoresCondicao.json"

import { v4 as uuidv4 } from 'uuid';

export default function ModalEdicaoCondicoes() {
  const { modalEdicaoCondicoesIsOpened, setModalEdicaoCondicoesIsOpened, modalEdicaoCondicoes, elements, setElements, findConnection } = useContext(FluxoContext)

  const initialForm = {
    title_variavel: { type: "fields", label: "Variável", size: { lg: 3, md: 7, sm: 12 } },
    title_operador: { type: "fields", label: "Condição", size: { lg: 2, md: 7, sm: 12 } },
    title_valor: { type: "fields", label: "Valor", size: { lg: 3, md: 7, sm: 12 } },
    title_destino: { type: "fields", label: "Destino", size: { lg: 3, md: 7, sm: 12 } },
    title_btn: { type: "fields", label: "", size: { lg: 1, md: 7, sm: 12 } },
  }

  const [form, setForm] = useState({})

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  useEffect(() => {
    if (modalEdicaoCondicoesIsOpened)
      start()
  }, [modalEdicaoCondicoesIsOpened])

  useEffect(() => {
    console.log("form", form)
  }, [form])

  const start = async () => {
    console.log("{ start }", modalEdicaoCondicoes, elements)


    setForm(initialForm)

    let data = modalEdicaoCondicoes.data.etapa ? modalEdicaoCondicoes.data.etapa : modalEdicaoCondicoes.data

    if (!data.condicoes || data.condicoes.length == 0)
      inserirOpcao()
    else {
      for (let condicao of data.condicoes) {
        inserirOpcao(condicao)
      }
    }
  }

  const inserirOpcao = (values = null) => {
    let uuid = uuidv4()
    console.log("inserirOpcao", uuid, values)
    setForm((form) => {
      // Variáveis
      let variaveis = []
      elements.filter(e => (e.type && e.type == "variavel") || (e?.data?.variavel)).map(e => {
        console.log({
          label: e?.type == "variavel" ? e?.data?.chave : e?.data?.variavel,
          value: e?.data?.id
        })
        variaveis.push({
          label: e?.type == "variavel" ? e?.data?.chave : e?.data?.variavel,
          value: e?.data?.id
        })
      })

      // Destinos
      let destinos = [{ label: "-", value: null }]
      elements.filter(e => e.type && e.type != "inicial" && e.id != modalEdicaoCondicoes.id).map(e => {
        let label = e?.data?.nome

        if (e.type == "variavel")
          label = `Variável "${e?.data?.chave}"`

        destinos.push({
          label,
          value: e?.id
        })
      })

      console.log(destinos)

      let destino = null

      let handle = Object.keys(form).length / 4
      let connection = values?.proxima_fluxo_etapa_id ? findConnection(values.proxima_fluxo_etapa_id, handle) : null
      console.log("connection", connection, connection?.target)

      console.log("AQUIII", values, elements)
      console.log(values?.proxima_fluxo_etapa_id)
      if (!connection && values?.proxima_fluxo_etapa_uuid) {
        destino = values.proxima_fluxo_etapa_uuid
      }

      let operadores = Object.keys(operadoresCondicao).map(key => { return { label: operadoresCondicao[key], value: key } })
      /*[
        { label: "Maior que", value: ">" },
        { label: "Menor que", value: "<" },
        { label: "Igual a", value: "==" },
        { label: "Diferente de", value: "!=" },
      ]*/

      return {
        ...form,
        [`variavel_${uuid}`]: { uuid, name: `variavel_${uuid}`, type: "select", value: values?.fluxo_variavel_id, options: variaveis, size: { lg: 3, md: 7, sm: 12 } },
        [`operador_${uuid}`]: { uuid, name: `operador_${uuid}`, type: "select", value: values?.operador, options: operadores, size: { lg: 2, md: 7, sm: 12 } },
        [`valor_${uuid}`]: { uuid, name: `valor_${uuid}`, type: "text", size: { lg: 3, md: 7, sm: 12 }, placeholder: "Valor", validate: { required: { value: true } }, value: values ? values.valor : "" },
        [`destino_${uuid}`]: { uuid, name: `destino_${uuid}`, type: "select", value: destino, options: destinos, size: { lg: 3, md: 7, sm: 12 } },
        [`btn_${uuid}`]: { uuid, type: "component", size: { lg: 1, md: 1, sm: 12 }, validate: {}, component: <Button color="danger" style={{ width: "100%" }} onClick={() => removerOpcao(uuid)}><i className="uil uil-trash"></i></Button> }
      }
    })
  }

  const removerOpcao = (uuid) => {
    console.log("removerOpcao", uuid)
    setForm((form) => {
      console.log("FORM ATUAL", form)
      let newForm = {}
      for (let key of Object.keys(form)) {
        if (form[key].uuid == uuid) {
          console.log("VAI VIRAR HIDDEN", form[key])
          newForm[key] = {
            ...form[key],
            type: "hidden",
            component: <></>
          }
        } else {
          newForm[key] = { ...form[key] }
        }
      }
      console.log("NEW FORM", newForm)
      return newForm
    })
  }

  const handleSubmit = async (event, errors, val) => {
    try {
      console.log("{ handleSubmit }", errors, val, elements)
      if (errors.length > 0) return

      let condicoesObj = {}
      let condicoes = []

      for (let key of Object.keys(val)) {
        let [name, uuid] = key.split("_")
        console.log(name, uuid, { ...condicoesObj[uuid], [name]: val[key] })
        condicoesObj[uuid] = { ...condicoesObj[uuid], [name]: val[key] }
      }

      for (let key of Object.keys(condicoesObj)) {
        condicoes.push(condicoesObj[key])
      }

      console.log("OPÇÕES", condicoes)

      // Pegar elements de conexão desse menu
      console.log(modalEdicaoCondicoes.id)

      let newElements = [...elements.filter(e => !e.source || e.source != modalEdicaoCondicoes.id)]
      for (let el of newElements) {
        console.log(el)
        if (el.id == modalEdicaoCondicoes.id) {
          el.data.condicoes = condicoes
        }
      }

      for (let i = 0; i < condicoes.length; i++) {
        let condicao = condicoes[i]

        console.log(modalEdicaoCondicoes.id)
        console.log(`condicao ${i}`, condicao)

        if (condicao.destino) {
          let edge = {
            id: `reactflow__edge-${modalEdicaoCondicoes.id}handle-${i}-${condicao.destino}`,
            source: modalEdicaoCondicoes.id,
            sourceHandle: `handle-${i}-${modalEdicaoCondicoes.id}`,
            target: condicao.destino,
            targetHandle: null
          }
          Object.keys(edge).map(key => console.log(`EDGES COM ${key} IGUAIS`, elements.filter(e => e[key] == edge[key])))
          console.log(edge)
          newElements.push(edge)
        }
      }

      console.log("NEW ELEMENT", newElements)

      // return
      setElements(newElements)

      setModalEdicaoCondicoesIsOpened(false)
    } catch (error) {

    }
  }

  return (
    <Modal size="lg" isOpen={modalEdicaoCondicoesIsOpened} toggle={() => { setModalEdicaoCondicoesIsOpened(!modalEdicaoCondicoesIsOpened) }}>
      <ModalHeader toggle={() => { setModalEdicaoCondicoesIsOpened(!modalEdicaoCondicoesIsOpened) }}>
        Atualizar Condicoes
      </ModalHeader>
      <ModalBody>
        <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText="Atualizar" buttons={[{ color: "success", text: "Nova opção", onClick: () => { inserirOpcao() } }]} />

        {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
      </ModalBody>
    </Modal>
  )
}
