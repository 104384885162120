import React, { useContext } from "react"
import { Card, Col, Row } from "reactstrap"

import FluxoContext from "../../../contexts/FluxoContext";

import Carregando from "../../../components/Carregando"
import Fluxo from "../FluxoEditor/Fluxo";
import Menu from "../FluxoEditor/Menu";

export default function FluxoWrapper() {
  const { loading, error } = useContext(FluxoContext)
  return (
    <>
      {loading ? <Carregando /> :
        error ?
          <Row>
            <Col lg={12} md={12} sm={12} style={{ textAlign: "center", paddingTop: 100 }}>
              <h3>{error.titulo}</h3>
              {error.descricao && <h5>{error.descricao}</h5>}
            </Col>
          </Row>
          :
          <Row>
            <Col lg={9} md={9} sm={12} >
              <Card>
                <Fluxo />
              </Card>
            </Col>
            <Col lg={3} md={3} sm={12}>
              <Menu />
            </Col>
          </Row>
      }
    </>
  )
}
