import React, { Component } from 'react';

class Reader extends Component {
  constructor(props) {
    super(props);
  }

  showFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)
      console.log(text.split(','))


      const result = {
        headers: [],
        data: [],
      }

      const data = text.split("\n")

      result.headers = data[0].split(",")
      const filteredData = data.map(item => {
        return item.split(",")
      })

      let filteredData2 = {}

      filteredData[0].map((item, index) => {
        filteredData2[item] = { index: [] }
        return {}
      })



      console.log(result)
      this.props.action(result)
    };

    reader.readAsText(e.target.files[0])
  }

  render = () => {

    return (
      <div className={`mb-3 ${this.props.hidden && "hidden"}`}>
        <label for="formFileLg" className="form-label" title="Escolher Aquivo" >Escolher Arquivo</label>
        <input class="form-control form-control-lg" id="formFileLg" type="file" title="Escolher Aquivo" onChange={(e) => this.showFile(e)} />
      </div>
    )
  }
}

export default Reader;
