import React, { useState, memo } from "react"

import { Row, Col } from "reactstrap"

import Input from "../../Input"
import Nav from "./Nav"

import "./style.scss"

const FormPart = ({ input }) => {
    //console.log("FormPart", input)

    if (input?.type == "tabs") {
        return (
            <Col key={Math.random() * 10000000000000} {...input.size || { lg: 12, md: 12, sm: 12 }}>
                <Nav tabs={input.tabs} />
            </Col >
        )
    } else if (input.fields) {
        return (
            <Col key={Math.random() * 10000000000000} {...input.size || { lg: 12, md: 12, sm: 12 }}>
                <Row>
                    {input.label && <Col lg={12}>
                        <h6>{input.label}</h6>
                    </Col>}
                    {Object.keys(input.fields).map(k => <FormPart key={k} input={input.fields[k]} />)}
                </Row>
            </Col>
        )
    } else if (input.component) {
        return (
            <Col key={Math.random() * 10000000000000} {...input.size || { lg: 12, md: 12, sm: 12 }}>
                <Row>
                    {input.label && <Col lg={12}>
                        <h6>{input.label}</h6>
                    </Col>}
                    <Col lg={12}>
                        {input.component}
                    </Col>
                </Row>
            </Col>
        )
    }

    return (
        <Col {...input.size} style={{ ...input.colStyle }}>
            {(!input.hasOwnProperty("display") || input.display) && <Input {...input} size={{}} />}
        </Col>
    )
}

export default memo(FormPart)
