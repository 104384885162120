import React, { useEffect, useState, useContext } from "react"
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import DefaultForm from "../../../../components/form/DefaultForm"

import SweetAlert from "react-bootstrap-sweetalert"

import FluxoContext from "../../../../contexts/FluxoContext"

import { v4 as uuidv4 } from 'uuid';

export default function ModalEdicaoMenu() {
  const { modalEdicaoMenuIsOpened, setModalEdicaoMenuIsOpened, modalEdicaoMenu, elements, setElements, findConnection } = useContext(FluxoContext)

  const [form, setForm] = useState({})

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  useEffect(() => {
    if (modalEdicaoMenuIsOpened)
      start()
  }, [modalEdicaoMenuIsOpened])

  useEffect(() => {
    console.log("form", form)
  }, [form])

  const start = async () => {
    console.log("{ start }", modalEdicaoMenu, elements)


    setForm({})

    let data = modalEdicaoMenu.data.etapa ? modalEdicaoMenu.data.etapa : modalEdicaoMenu.data

    if (!data.opcoes || data.opcoes.length == 0)
      inserirOpcao()
    else {
      for (let opcao of data.opcoes) {
        inserirOpcao(opcao)
      }
    }
  }

  const inserirOpcao = (values = null) => {
    let uuid = uuidv4()
    console.log("inserirOpcao", uuid)
    setForm((form) => {
      let destinos = [{ label: "-", value: null }]

      elements.filter(e => e.type && e.type != "inicial" && e.id != modalEdicaoMenu.id).map(e => {
        destinos.push({
          label: e.type == "variavel" ? `Variável "${e?.data?.chave}"` : e?.data?.nome,
          value: e?.id
        })
      })

      console.log(destinos)

      let handle = Object.keys(form).length / 4
      let connection = findConnection(modalEdicaoMenu.id, handle)
      console.log("connection", connection, connection?.target)

      return {
        ...form,
        [`entradas_${uuid}`]: { uuid, name: `entradas_${uuid}`, type: "text", placeholder: "Entradas", size: { lg: 3, md: 4, sm: 12 }, validate: { required: { value: true } }, value: values ? values.entradas : "" },
        [`descricao_${uuid}`]: { uuid, name: `descricao_${uuid}`, type: "text", placeholder: "Descrição", size: { lg: 5, md: 7, sm: 12 }, validate: { required: { value: true } }, value: values ? values.descricao : "" },
        [`destino_${uuid}`]: {
          uuid, name: `destino_${uuid}`, type: "select", value: connection?.target, options: destinos, size: { lg: 3, md: 7, sm: 12 }
        },
        [`btn_${uuid}`]: { uuid, type: "component", size: { lg: 1, md: 1, sm: 12 }, validate: {}, component: <Button color="danger" style={{ width: "100%" }} onClick={() => removerOpcao(uuid)}><i className="uil uil-trash"></i></Button> }
      }
    })
  }

  const removerOpcao = (uuid) => {
    console.log("removerOpcao", uuid)
    setForm((form) => {
      console.log("FORM ATUAL", form)
      let newForm = {}
      for (let key of Object.keys(form)) {
        if (form[key].uuid == uuid) {
          console.log("VAI VIRAR HIDDEN", form[key])
          newForm[key] = {
            ...form[key],
            type: "hidden",
            component: <></>
          }
        } else {
          newForm[key] = { ...form[key] }
        }
      }
      console.log("NEW FORM", newForm)
      return newForm
    })
  }

  const handleSubmit = async (event, errors, val) => {
    try {
      console.log("{ handleSubmit }", errors, val, elements)
      if (errors.length > 0) return

      let opcoesObj = {}
      let opcoes = []

      for (let key of Object.keys(val)) {
        let [name, uuid] = key.split("_")
        console.log(name, uuid, { ...opcoesObj[uuid], [name]: val[key] })
        opcoesObj[uuid] = { ...opcoesObj[uuid], [name]: val[key] }
      }

      for (let key of Object.keys(opcoesObj)) {
        opcoes.push(opcoesObj[key])
      }

      console.log("OPÇÕES", opcoes)

      // Pegar elements de conexão desse menu
      console.log(modalEdicaoMenu.id)

      let newElements = [...elements.filter(e => !e.source || e.source != modalEdicaoMenu.id)]
      for (let el of newElements) {
        console.log(el)
        if (el.id == modalEdicaoMenu.id) {
          el.data.opcoes = opcoes
        }
      }

      for (let i = 0; i < opcoes.length; i++) {
        let opcao = opcoes[i]

        if (opcao.destino) {
          newElements.push({
            id: `reactflow__edge-${modalEdicaoMenu.id}handle-${i}-${opcao.destino}`,
            source: modalEdicaoMenu.id,
            sourceHandle: `handle-${i}-${modalEdicaoMenu.id}`,
            target: opcao.destino,
            targetHandle: null
          })
        }
      }

      console.log("NEW ELEMENT", newElements)

      // return
      setElements(newElements)

      setModalEdicaoMenuIsOpened(false)
    } catch (error) {

    }
  }

  return (
    <Modal size="lg" isOpen={modalEdicaoMenuIsOpened} toggle={() => { setModalEdicaoMenuIsOpened(!modalEdicaoMenuIsOpened) }}>
      <ModalHeader toggle={() => { setModalEdicaoMenuIsOpened(!modalEdicaoMenuIsOpened) }}>
        Atualizar Menu
      </ModalHeader>
      <ModalBody>
        <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText="Atualizar" buttons={[{ color: "success", text: "Nova opção", onClick: () => { inserirOpcao() } }]} />

        {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
      </ModalBody>
    </Modal>
  )
}
