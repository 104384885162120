import React, { useState, useEffect, useRef, useContext } from 'react';
import ReactFlow, { MiniMap, Controls, ControlButton, addEdge, removeElements, useUpdateNodeInternals } from 'react-flow-renderer';

import FluxoContext from "../../../../contexts/FluxoContext"
import ModalEdicaoTipo from '../ModalEdicaoTipo';

import NodeInicial from './nodes/NodeInicial';
import NodeEntrada from "./nodes/NodeEntrada";
import NodeImagem from './nodes/NodeImagem';
import NodeMensagem from './nodes/NodeMensagem';
import NodeVariavel from './nodes/NodeVariavel';
import NodeFinalizar from './nodes/NodeFinalizar';
import NodeMenu from './nodes/NodeMenu';
import NodeSetor from './nodes/NodeSetor';
import NodeHorario from './nodes/NodeHorario';


import "./style.scss"
import ModalEdicaoMenu from '../ModalEdicaoMenu';
import ModalEdicaoCondicoes from '../ModalEdicaoCondicoes';
import ModalHelp from './ModalHelp';
import NodeCondicao from './nodes/NodeCondicao';

export default function Fluxo() {
  const { elements, setElements, createId, fluxoAlterado } = useContext(FluxoContext)

  const updateNodeInternals = useUpdateNodeInternals();

  useEffect(() => {
    for (let element of elements)
      updateNodeInternals(element.id)
  }, [elements])

  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const onConnect = (params) => setElements((els) => addEdge(params, els));
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const [openedHelp, setOpenedHelp] = useState(false)

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = (event) => {

    console.log("onDrop")
    event.preventDefault();

    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow');
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNode = {
      id: createId(),
      type,
      position,
      data: {
        nome: criarNomeNode(type)
      },
    };

    setElements([...elements, newNode]);
  };

  const onNodeDragStop = (event, element) => {
    console.log("{ onNodeDragStop }", event, element)

    let elems = [...elements]
    for (let el of elems) {
      if (el.id == element.id) {
        el.position = element.position
      }
    }
    setElements(elems)

    fluxoAlterado()
  }

  const removeEdge = (e1, e2) => {
    setElements(elements.filter(e => e.id != e2.id))
  }

  const nodeTypes = {
    inicial: NodeInicial,
    entrada: NodeEntrada,
    mensagem: NodeMensagem,
    imagem: NodeImagem,
    variavel: NodeVariavel,
    condicao: NodeCondicao,
    menu: NodeMenu,
    finalizar: NodeFinalizar,
    setor: NodeSetor,
    horario: NodeHorario
  };

  const criarNomeNode = (type) => {
    return ({
      entrada: "Entrada",
      mensagem: "Mensagem",
      imagem: "Imagem",
      variavel: "Variável",
      condicao: "Condição",
      menu: "Menu",
      finalizar: "Finalizar",
      setor: "Ir para setor",
      horario: "Horário",
    })[type]
  }

  const abrirHelp = () => {
    setOpenedHelp(() => true)
  }

  return (
    <div className="fluxo-wrapper reactflow-wrapper" ref={reactFlowWrapper}>
      <ReactFlow
        elements={elements}
        nodeTypes={nodeTypes}
        onConnect={onConnect}
        onElementsRemove={onElementsRemove}
        onLoad={onLoad}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onNodeDragStop={onNodeDragStop}
        onEdgeDoubleClick={removeEdge}
      >
        <Controls>
          <ControlButton onClick={abrirHelp}>
            <i className="uil-info-circle" />
          </ControlButton>
        </Controls>
        <MiniMap
          nodeColor={(node) => {
            switch (node.type) {
              case 'inicial':
                return '#bfd62d';
              case 'input':
                return 'red';
              case 'default':
                return '#00ff00';
              case 'output':
                return 'rgb(0,0,255)';
              default:
                return '#eee';
            }
          }}
          nodeStrokeWidth={3}
        />
      </ReactFlow>

      <ModalEdicaoTipo />
      <ModalEdicaoMenu />
      <ModalEdicaoCondicoes />
      <ModalHelp opened={openedHelp} setOpened={setOpenedHelp} />
    </div>
  )
}
