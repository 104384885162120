import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"

const CamposAdicionaisListaColunas = (removerCampoAdicional, setEdicaoModal, permissoes, usuariosOpened, translate) => [
  {
    text: translate("Nome"),
    dataField: "nome",
    sort: true,
    formatter: (cellContent, campo_adicional) => (
      <>
        <Link to="#" className="text-body" onClick={() => { setEdicaoModal(campo_adicional) }}>
          {campo_adicional.nome}
        </Link>
      </>
    ),
  },
  {
    text: translate("Descrição"),
    dataField: "descricao",
    sort: true,
    formatter: (cellContent, campo_adicional) => (
      <>
        {
          campo_adicional.descricao ||
          <i>{translate("Sem descrição")}</i>
        }
      </>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, campo_adicional) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#">Visualizar</DropdownItem>
              {permissoes.campos_adicionais_gerenciamento && <DropdownItem href="#" onClick={() => { setEdicaoModal(campo_adicional) }}>{translate("Editar")}</DropdownItem>}
              {permissoes.campos_adicionais_gerenciamento && <DropdownItem href="#" onClick={() => { removerCampoAdicional(campo_adicional) }}>{translate("Remover")}</DropdownItem>}
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    ),
  },
]

export default CamposAdicionaisListaColunas
