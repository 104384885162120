import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { getUsers } from "../../../store/contacts/actions"
import FluxosListaColunas from "./FluxosListaColunas"
import { isEmpty } from "lodash"

import { getFluxos, deleteFluxo } from "../../../services/fluxos"

import PermissaoContext from "../../../contexts/PermissaoContext"

import SweetAlert from "react-bootstrap-sweetalert"

import Carregando from "../../../components/Carregando"

const FluxosLista = props => {
  const { users, onGetUsers } = props

  const { permissoes, isLoadingPermissoes } = useContext(PermissaoContext)

  const [list, setList] = useState([])
  const [listView, setListView] = useState([])
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: list.length,
    custom: true
  })
  const { SearchBar } = Search

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const [modalCadastroOpened, setModalCadastroOpened] = useState(false)
  const [modalEdicao, setModalEdicao] = useState(null) // Armazena os dados que serão utilizados na edição
  const [modalEdicaoOpened, setModalEdicaoOpened] = useState(false)

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  useEffect(() => {
    buscarFluxos()
  }, [])

  useEffect(() => {
    setModalEdicaoOpened(modalEdicao != null)
  }, [modalEdicao])

  useEffect(() => {
    if (!isEmpty(users)) {
      setList(users)
    }
  }, [users])

  const buscarFluxos = async () => {
    try {
      const fluxos = await getFluxos()

      setList(fluxos)
      setListView(fluxos)
    } catch (error) {
    }
  }

  const removerFluxo = async (fluxo) => {
    try {
      console.log("removerFluxo", fluxo)
      setSweetAlert({
        ...sweetAlert,
        show: true,
        title: "Atenção!",
        text: `Tem certeza que deseja excluir o usuário ${fluxo.nome}?`,
        showCancel: true,
        cancelBtnBsText: "Não",
        cancelBtnBsStyle: "danger",
        warning: true,
        onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
        onConfirm: async () => {
          setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: "Aguarde", text: "Estamos removendo o usuário", show: true })
          try {
            let retorno = await deleteFluxo(fluxo.id)
            console.log("RETORNOOO", retorno)
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: "Sucesso", text: retorno || "Fluxo removido com sucesso!", onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }, show: true })
            handleRefresh(fluxo, "remove")
          } catch (error) {
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: "Ooops", text: error.data || "Ocorreu um erro.", onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }, show: true })
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleRefresh = async (grupo, action = "add") => {
    console.log("{ handleRefresh }", action, list, grupo)
    if (action == "add") {
      let newArray = [...list, grupo]
      setList(newArray)
      setPageOptions({ ...pageOptions, totalSize: list.length + 1 })
      handleTableChange(null, { page, searchText }, newArray)
    } else if (action == "edit") {
      let newArray = [...list]
      for (let i = 0; i < newArray.length; i++) {
        const el = newArray[i]
        if (el.id == grupo.id) {
          newArray[i] = grupo
          break
        }
      }
      handleTableChange(null, { page, searchText }, newArray)
    } else if (action == "remove") {
      let newArray = list.filter(user => user.id != grupo.id)
      setList(newArray)
      setPageOptions({ ...pageOptions, totalSize: newArray.length - 1 })
      handleTableChange(null, { page, searchText }, newArray)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }, data = null) => {
    const dados = data || list
    console.log("{ handleTableChange }", page, searchText, dados)
    setPage(page)
    setSearchText(searchText)

    let inicio = pageOptions.sizePerPage * page - pageOptions.sizePerPage
    let fim = pageOptions.sizePerPage * page
    if (fim > dados.length) fim = dados.length

    setListView(
      dados.slice(inicio, fim).filter(user =>
        Object.keys(user).some(
          key =>
            typeof user[key] === "string" &&
            user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )
  }

  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Fluxos" breadcrumbItem="Lista de Fluxos" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {isLoadingPermissoes ? <Carregando /> :
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={listView || []}
                          columns={FluxosListaColunas(removerFluxo, setModalEdicao, permissoes)}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="row mb-2">
                                <Col md={6}>
                                  {permissoes.fluxos_gerenciamento && <div className="mb-3">
                                    <Link to="/fluxos/editor" className="btn btn-success waves-effect waves-light" onClick={() => setModalCadastroOpened(true)}><i className="mdi mdi-plus me-2"></i> Novo</Link>
                                  </div>}
                                </Col>

                                <Col md={6}>
                                  <div className="form-inline float-md-end mb-3">
                                    <div className="search-box ms-2">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="mdi mdi-magnify search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive mb-4">
                                    <BootstrapTable
                                      selectRow={selectRowProp}
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={"table table-centered table-nowrap mb-0"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <div className="float-sm-end">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
}

FluxosLista.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FluxosLista))
