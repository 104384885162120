import React from "react"

import { formatDataHora } from "../../../../services/formatService"

import "./style.scss"

export default function Mensagem({ mensagem , translate }) {
    return (
        <div className={`mensagem-wrapper ${`mensagem-${mensagem.envio ? translate("enviada") : translate("recebida")}`} ${`mensagem-${mensagem.tipo}`}`}>
            {mensagem.tipo == "notificacao" ?
                <div className="notificacao">{mensagem.mensagem}</div>
                :
                <div className={`mensagem`} dangerouslySetInnerHTML={{ __html: mensagem.mensagem }}></div>
            }
            <div className="horario">{formatDataHora(mensagem.datahora_cadastro, "DD/MM/YYYY - HH:mm:ss")}</div>
        </div>
    )
}