import api from "./api"

const getContatos = async () => {
  try {
    const { data } = await api.get("/contatos")
    return data
  } catch (error) {
    throw error
  }
}

const getByIdContatos = async (id) => {
  try {
    const { data } = await api.get(`/contatos/${id}`)
    return data
  } catch (error) {
    throw error
  }
}

const postContato = async (data) => {
  try {
    const resultado = await api.post("/contatos", data)
    return resultado.data
  } catch (error) {
    throw error
  }
}

const postContatosImportados = async (data) => {
  try {
    const resultado = await api.post("/contatos/importados", data)
    return resultado.data
  } catch (error) {
    throw error
  }
}

const putContato = async (id, data) => {
  try {
    const resultado = await api.put(`/contatos/${id}`, data)
    return resultado.data
  } catch (error) {
    throw error
  }
}

const deleteContato = async (id) => {
  try {
    const resultado = await api.delete(`/contatos/${id}`)
    return resultado.data
  } catch (error) {
    throw error
  }
}

export { getContatos, getByIdContatos, postContato, postContatosImportados, putContato, deleteContato }
