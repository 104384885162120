import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"

const semanas = {
  1: "Seg",
  2: "Ter",
  3: "Qua",
  4: "Qui",
  5: "Sex",
  6: "Sáb",
  0: "Dom"
}

const meses = {
  1: "Jan",
  2: "Fev",
  3: "Mar",
  4: "Abr",
  5: "Mai",
  6: "Jun",
  7: "Jul",
  8: "Ago",
  9: "Set",
  10: "Out",
  11: "Nov",
  12: "Dez",
}

const GruposDeHorariosListaColunas = (removerGrupoDeHorarios, setEdicaoModal, permissoes, translate) => [
  {
    text: translate("Nome do Grupo"),
    dataField: "nome_do_grupo",
    sort: true,
    formatter: (cellContent, grupoDeHorarios) => (
      <>
        <Link to="#" className="text-body" onClick={() => { setEdicaoModal(grupoDeHorarios) }}>
          {grupoDeHorarios.nome}
        </Link>
        {grupoDeHorarios.horarios.map(() => { return <br/> })}
      </>
    ),
  },
  {
    // text: "Descrição",
    // dataField: "descricao",
    // sort: true,
    // formatter: (cellContent, grupoDeHorarios) => (
    //   <>
    //     {
    //       grupoDeHorarios.descricao ||
    //       <i>Sem descrição</i>
    //     }
    //   </>
    // ),
  },
  {
    text: translate("Descrição"),
    dataField: "descricao",
    sort: true,
    formatter: (cellContent, grupoDeHorarios) => (
      <>
        {grupoDeHorarios.horarios.map(value => { return <>{value.descricao}<br/></>})}
      </>
    ),
  },
  {
    text: translate("Dias"),
    dataField: "dias",
    sort: true,
    formatter: (cellContent, grupoDeHorarios) => (
      <>
        {grupoDeHorarios.horarios.map(value => { return <>{value.dia_inicio} - {value.dia_fim}<br/></>})}
      </>
    ),
  },
  {
    text: translate("Meses"),
    dataField: "meses",
    sort: true,
    formatter: (cellContent, grupoDeHorarios) => (
      <>
        {grupoDeHorarios.horarios.map(value => { return <>{translate(meses[value.dia_mes_inicio])} - {translate(meses[value.dia_mes_fim])}<br/></>})}
      </>
    ),
  },
  {
    text: translate("Dias da Semana"),
    dataField: "dias_da_semana",
    sort: true,
    formatter: (cellContent, grupoDeHorarios) => (
      <>
        {grupoDeHorarios.horarios.map(value => { return <>{translate(semanas[value.dia_semana_inicio])} - {translate(semanas[value.dia_semana_fim])}<br/></>})}
      </>
    ),
  },
  {
    text: translate("Horário"),
    dataField: "horario",
    sort: true,
    formatter: (cellContent, grupoDeHorarios) => (
      <>
        {grupoDeHorarios.horarios.map(value => { return <>{value.hora_inicio} - {value.hora_fim}<br/></>})}
      </>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, grupoDeHorarios) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {permissoes.grupo_de_horarios_gerenciamento || true && <DropdownItem href="#" onClick={() => { setEdicaoModal(grupoDeHorarios) }}>{translate("Editar")}</DropdownItem>}
              {permissoes.grupo_de_horarios_gerenciamento || true && <DropdownItem href="#" onClick={() => { removerGrupoDeHorarios(grupoDeHorarios) }}>{translate("Remover")}</DropdownItem>}
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    ),
  },
]

export default GruposDeHorariosListaColunas
