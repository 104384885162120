import api from "./api"

const getFluxos = async () => {
    try {
        const resultado = await api.get("/fluxos")
        console.log("{ getFluxos }", resultado)
        const fluxos = resultado.data
        return fluxos
    } catch (error) {
        throw error
    }
}

const getFluxo = async (id) => {
    try {
        const resultado = await api.get(`/fluxos/${id}`)
        console.log("{ getFluxo }", resultado)
        const fluxo = resultado.data
        return fluxo
    } catch (error) {
        throw error
    }
}

const putFluxo = async (id, data) => {
    try {
        const resultado = await api.put(`/fluxos/${id}`, data)
        const fluxos = resultado.data
        console.log("putFluxo result", fluxos)
        return fluxos
    } catch (error) {
        console.error("ERROR putFluxo", error)
        throw error
    }
}

const postFluxo = async (data) => {
    try {
        const resultado = await api.post("/fluxos", data)
        const fluxos = resultado.data
        console.log("postFluxos result", fluxos)
        return fluxos
    } catch (error) {
        throw error
    }
}

const deleteFluxo = async (id) => {
    try {
        const resultado = await api.delete(`/fluxos/${id}`)
        const resposta = resultado.data
        return resposta
    } catch (error) {
        console.error("DELETE FLUXO ERROR", error)
        throw error
    }
}

const postRascunho = async (data) => {
    try {
        const resultado = await api.post("/fluxos/rascunho", data)
        const rascunho = resultado.data
        console.log("postRascunho result", rascunho)
        return rascunho
    } catch (error) {
        throw error
    }
}

const postPublicar = async (fluxo_id) => {
    try {
        const resultado = await api.post("/fluxos/publicar", { fluxo_id: fluxo_id })
        const fluxo = resultado.data
        console.log("postPublicar result", fluxo)
        return fluxo
    } catch (error) {
        throw error
    }
}

export { getFluxos, getFluxo, postFluxo, putFluxo, deleteFluxo, postRascunho, postPublicar }