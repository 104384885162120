import React, { useContext, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import AuthContext from "../../../contexts/AuthContext"
import { putUsuario } from "../../../services/usuarios"
import DefaultForm from "../../form/DefaultForm"






export default withTranslation()(function ModalMeusDados({ opened, setOpened, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const { usuario, setUsuario } = useContext(AuthContext)

    const [form, setForm] = useState({
        foto: { name: "foto", type: "upload-avatar", placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 }, colStyle: { display: "flex", flexDirection: "row", justifyContent: "center" }, edit: true },
        nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
        codinome: { name: "codinome", type: "text", label: translate("Codinome"), placeholder: translate("Codinome"), help: translate("Atalho de acesso e identificação do usuário"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
        email: { name: "email", type: "email", label: translate("E-mail"), placeholder: translate("E-mail"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 }, disabled: true }
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        if (usuario)
            start(usuario)
    }, [usuario])

    const start = (dados) => {
        let newJson = { ...form }

        /*newJson.coluna1.fields.foto.value = dados.foto != "https://res.cloudinary.com/ipass/image/upload/v1629464320/user_f1oeda.png" ? dados.foto : null
        newJson.coluna2.fields.nome.value = dados.nome
        newJson.coluna2.fields.codinome.value = dados.codinome
        newJson.coluna2.fields.email.value = dados.email*/

        newJson.foto.value = dados.foto != "https://res.cloudinary.com/ipass/image/upload/v1629464320/user_f1oeda.png" ? dados.foto : null
        newJson.foto.edit = dados.foto != "https://res.cloudinary.com/ipass/image/upload/v1629464320/user_f1oeda.png" ? false : true
        newJson.nome.value = dados.nome
        newJson.codinome.value = dados.codinome
        newJson.email.value = dados.email

        console.log("newJson", newJson)

        setForm(newJson)
    }

    const handleSubmit = async (event, errors, values) => {
        try {
            console.log("{ handleSubmit }", errors, values, form)
            if (errors.length > 0) return

            const { email, ...dados } = values

            const resultado = await putUsuario("sessao", dados)

            console.log("USUARIO CADASTRADO", resultado)

            setUsuario(resultado)
            start(resultado)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Usuário")} ${usuario.nome} ${translate("cadastrado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalCadastroUsuario ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Meus Dados")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
