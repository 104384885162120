import React, { useCallback, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { getCamposAdicionais } from "../../../services/campos-adicionais"
import { putContato } from "../../../services/contatos"
import DefaultForm from "../../form/DefaultForm"

export default function ModalEdicaoContato({ opened, setOpened, refresh, values, remover, translate }) {
  const [form, setForm] = useState({
    foto: { type: "upload-avatar", secondType: "upload-avatar", name: "foto", label: "Foto", validate: {}, size: { lg: 12, md: 12, sm: 12 } },
    nome: { type: "info", secondType: "text", name: "nome", label: "Nome", validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
  })

  const [camposAdicionais, setCamposAdicionais] = useState([])

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const [atualizarShow, setAtualizarShow] = useState(false)

  const handleAtualizarShow = (status) => {
    setAtualizarShow(status)
    setForm(form => {
      for (let key of Object.keys(form)) {
        console.log(key, form[key].secondType)
        form[key].type = status ? form[key].secondType : "info"
      }
      console.log(form)
      return form
    })
  }

  const buscarCamposAdicionais = useCallback(async () => {
    try {
      const camposAdicionais = await getCamposAdicionais()

      setCamposAdicionais(camposAdicionais)

      setForm((form) => {
        for (let campo of camposAdicionais) {
          form = {
            ...form,
            [`campo_adicional_${campo.id}`]: { name: `campo_adicional_${campo.id}`, type: atualizarShow ? "text" : "info", secondType: "text", label: campo.nome, placeholder: campo.nome, errorMessage: translate("Campo obrigatório"), validate: campo.obrigatorio ? { required: { value: true } } : {}, size: { lg: 6, md: 12, sm: 12 } }
          }
        }
        return form
      })
    } catch (error) {
      setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Ooops!"), text: error.response?.data ? error.response?.data : translate("Ocorreu um erro"), success: false, error: true }));
    }
  }, [translate, atualizarShow]);

  const handleSubmit = useCallback(async (event, errors, data) => {
    console.log(data)
    try {
      if (errors.length > 0) return

      const newData = {
        nome: data.nome,
        foto: data.foto,
        campos_adicionais: []
      }

      for (var campo of camposAdicionais) {
        if(data.hasOwnProperty(`campo_adicional_${campo.id}`)) {
          newData.campos_adicionais.push({ id: campo.id, valor: data[`campo_adicional_${campo.id}`] })
        }
      }
      console.log(newData)
      const contato = await putContato(values.id, newData)
      if (refresh) refresh(contato, "edit")
      setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Contato")} ${contato.nome} ${translate("atualizado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } }));
    } catch (error) {
      setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Ooops!"), text: !!error.response?.data ? error.response?.data : translate("Ocorreu um erro"), success: false, error: true }));
    }
  }, [refresh, translate, setOpened, values]);

  const start = useCallback( async () => {
    await buscarCamposAdicionais()
    if (values) {
      try {
        setForm((form) => {
          form = {
            ...form,
            ...(values.foto ? { foto: { ...form.foto, value: values.foto } } : {}),
            nome: { ...form.nome, value: values.nome }
          }

          for (let campo of camposAdicionais) {
            let valor = null
            let busca = values?.campos_adicionais.filter(ca => ca.campo_adicional_id === campo.id)
            if (busca.length > 0) {
              valor = busca[0].valor
              form = {
                ...form,
                [`campo_adicional_${campo.id}`]: { name: `campo_adicional_${campo.id}`, type: atualizarShow ? "text" : "info", secondType: "text", value: valor, label: campo.nome, placeholder: campo.nome, errorMessage: "Campo obrigatório", validate: { required: campo.obrigatorio ? { value: true } : {} }, size: { lg: 6, md: 12, sm: 12 } }
              }
            }
          }

          return form
        })
      } catch (error) {
        setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Ooops!"), text: error.response?.data ? error.response?.data : translate("Ocorreu um erro"), success: false, error: true }));
      }
    }
  }, [values, camposAdicionais, translate, buscarCamposAdicionais, atualizarShow]);

  useEffect(() => {
    start()
  }, [values, atualizarShow])

  return (
    <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
      <ModalHeader toggle={() => { setOpened(!opened) }}>
          {translate("Atualizar Contato")}
      </ModalHeader>
      <ModalBody>
        <DefaultForm fields={form} handleSubmit={handleSubmit} buttonSubmit={atualizarShow} buttonText={translate("Atualizar")} buttons={
          [
            { text: translate("Remover"), color: "danger", onClick: () => { remover(values) }, show: !atualizarShow },
            { text: translate("Atualizar"), color: "warning", onClick: () => { handleAtualizarShow(true) }, show: !atualizarShow },
            { text: translate("Cancelar"), color: "danger", onClick: () => { handleAtualizarShow(false) }, show: atualizarShow }
          ]
        } />

        {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
      </ModalBody>
    </Modal>
  )
}
