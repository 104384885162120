import React, { useEffect, useState, useContext } from "react"
import Carregando from "../../../../../components/Carregando"

import DefaultForm from "../../../../../components/form/DefaultForm"
import FluxoContext from "../../../../../contexts/FluxoContext"

import { getGruposDeHorarios } from "../../../../../services/grupos-de-horarios"

export default function MDHorario({
  modalEdicao,
  handleSubmit
}) {
  const { elements, infos, setInfos } = useContext(FluxoContext)

  const [form, setForm] = useState({
    nome: { name: "nome", type: "text", value: modalEdicao.data.nome, label: "Nome", size: { lg: 12, md: 12, sm: 12 } },
    grupo_horario_id: {
      name: "grupo_horario_id", type: "select", options: [],
      value: modalEdicao.data.grupo_horario_id || modalEdicao.data?.etapa?.grupo_horario_id, label: "Horário", validate: {}, size: { lg: 6, md: 6, sm: 12 },
      onChange: e => changeSelect("fluxo_etapa_id_grupo_horario", e.target.value)
    },
    fluxo_etapa_id_grupo_horario: {
      name: "fluxo_etapa_id_grupo_horario", type: "select", options: [],
      value: modalEdicao.data.fluxo_etapa_id_grupo_horario || modalEdicao.data?.etapa?.fluxo_etapa_id_grupo_horario, label: "Ir para", validate: {}, size: { lg: 6, md: 6, sm: 12 },
      disabled: false
    },
    grupo_horario_id_excecao: {
      name: "grupo_horario_id_excecao", type: "select", options: [],
      value: modalEdicao.data.grupo_horario_id_excecao || modalEdicao.data?.etapa?.grupo_horario_id_excecao, label: "Exceção", validate: {}, size: { lg: 6, md: 6, sm: 12 },
      onChange: e => changeSelect("fluxo_etapa_id_grupo_horario_excecao", e.target.value)
    },
    fluxo_etapa_id_grupo_horario_excecao: {
      name: "fluxo_etapa_id_grupo_horario_excecao", type: "select", options: [],
      value: modalEdicao.data.fluxo_etapa_id_grupo_horario_excecao || modalEdicao.data?.etapa?.fluxo_etapa_id_grupo_horario_excecao, label: "Ir para", validate: {}, size: { lg: 6, md: 6, sm: 12 },
      disabled: false
    },
    proximo_fluxo_etapa_id: {
      name: "proximo_fluxo_etapa_id", type: "select", options: [],
      value: modalEdicao.data.proximo_fluxo_etapa_id || modalEdicao.data?.etapa?.proximo_fluxo_etapa_id, label: "Senão, ir para", validate: {}, size: { lg: 12, md: 12, sm: 12 }
    }
  })

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const [disableds, setDisableds] = useState({
    fluxo_etapa_id_grupo_horario: false,
    fluxo_etapa_id_grupo_horario_excecao: false
  })

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    console.log("disableds", disableds)
  }, [disableds])

  const init = async () => {
    try {
      setLoading(() => true)

      let gruposDeHorarios = await buscarGruposDeHorarios()

      let destinos = getDestinos()

      let newForm = { ...form }
      newForm.grupo_horario_id.options = gruposDeHorarios
      newForm.grupo_horario_id_excecao.options = gruposDeHorarios

      newForm.fluxo_etapa_id_grupo_horario.options = (!isNaN(+(modalEdicao.data.fluxo_etapa_id_grupo_horario || modalEdicao.data?.etapa?.fluxo_etapa_id_grupo_horario))) ? destinos : []
      newForm.fluxo_etapa_id_grupo_horario_excecao.options = (!isNaN(+(modalEdicao.data.fluxo_etapa_id_grupo_horario_excecao || modalEdicao.data?.etapa?.fluxo_etapa_id_grupo_horario_excecao))) ? destinos : []
      newForm.proximo_fluxo_etapa_id.options = destinos

      newForm.fluxo_etapa_id_grupo_horario.disabled = (modalEdicao.data.fluxo_etapa_id_grupo_horario || modalEdicao.data?.etapa?.fluxo_etapa_id_grupo_horario) ? false : true
      newForm.fluxo_etapa_id_grupo_horario_excecao.disabled = (modalEdicao.data.fluxo_etapa_id_grupo_horario_excecao || modalEdicao.data?.etapa?.fluxo_etapa_id_grupo_horario_excecao) ? false : true

      setForm(newForm)
    } catch (error) {
      setError({
        titulo: "Ocorreu um erro",
        descricao: error?.message
      })
    } finally {
      setLoading(() => false)
    }
  }

  const getDestinos = () => {
    let destinos = [{ label: "-", value: null }]
    elements.filter(e => e.type && e.type != "inicial" && e.id != modalEdicao.id).map(e => {
      let label = e?.data?.nome

      if (e.type == "variavel")
        label = `Variável "${e?.data?.chave}"`

      destinos.push({
        label,
        value: e?.id
      })
    })
    return destinos
  }

  const buscarGruposDeHorarios = async () => {
    try {
      let gruposDeHorarios = []

      if (infos.gruposDeHorarios)
        gruposDeHorarios = infos.gruposDeHorarios
      else {
        gruposDeHorarios = await getGruposDeHorarios()
        setInfos(infos => {
          return { ...infos, gruposDeHorarios }
        })
      }

      gruposDeHorarios.map(grupo => {
        grupo.value = grupo.id
        grupo.label = grupo.nome
      })

      gruposDeHorarios = [{
        label: "Selecione",
        value: null
      }, ...gruposDeHorarios]

      return gruposDeHorarios
    } catch (error) {
      throw error
    }
  }

  const changeSelect = (name, value) => {
    console.log("changeSelect", name, value, form)

    let disabled = isNaN(+value)

    let outro = name == "fluxo_etapa_id_grupo_horario" ? "fluxo_etapa_id_grupo_horario_excecao" : "fluxo_etapa_id_grupo_horario"

    console.log("disabled", disabled)

    console.log(`${name}`, disableds[name])
    console.log(`${outro}`, disableds[outro])

    setForm(form => {
      let newForm = { ...form }

      let destinos = getDestinos()

      newForm[name] = {
        ...newForm[name],
        disabled,
        validate: disabled ? {} : { required: { value: true } },
        options: disabled ? [] : destinos
      }
      // newForm[outro] = {
      //   ...newForm[outro],
      //   disabled: disableds[outro],
      //   validate: disableds[outro] ? {} : { required: { value: true } }
      // }

      console.log("form", form)
      console.log("newForm", newForm)

      return newForm
    })

    setDisableds(disableds => {
      disableds = { ...disableds }
      disableds[name] = disabled
      return disableds
    })
  }

  return (
    <>
      {loading ?
        <Carregando /> :
        error ? <>
          <h3>{error.titulo}</h3>
          {error.descricao && <h5>{error.descricao}</h5>}
        </> :
          <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText="Atualizar" />
      }
    </>
  )
}
