import React from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

export default function ModalHelp({ opened, setOpened }) {
    return (
        <Modal size="md" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                Ajuda
            </ModalHeader>
            <ModalBody>
                <p><b>Shift + Seleção</b>: selecionar vários elementos ao mesmo tempo.</p>
            </ModalBody>
        </Modal>
    )
}