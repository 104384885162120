import React, { useCallback, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import { putGrupo } from "../../../services/grupos"
import { getGruposDeHorarios } from "../../../services/grupos-de-horarios"
import DefaultForm from "../../form/DefaultForm"

export default withTranslation()(function ModalEdicaoGrupo({ opened, setOpened, refresh, values, ...props }) {
    function translate (text) {
      return props.t(text)
    }
    const [checked, setChecked] = useState(false)

    const [form, setForm] = useState({
        nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        grupo_horario_id: { name: "grupo_horario_id", type: "select", options: [], label: translate("Grupo de Horário"), placeholder: translate("Nenhum grupo selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
        grupo_horario_id_excecao: { name: "grupo_horario_id_excecao", type: "select", options: [], label: translate("Grupo de Excessão de Horário"), placeholder: translate("Nenhum grupo selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
        descricao: { name: "descricao", type: "textarea", label: translate("Descrição"), placeholder: translate("Descreva o grupo"), validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        permissao_total: { label: translate("Permissões"), component: <div>
          <br/>
            <FormGroup check>
              <Input id="permissao_total" name="permissao_total" type="checkbox" onChange={(e) => setChecked(e.target.checked)} />
              <Label check>{translate("Permissão Total")}</Label>
            </FormGroup>
          <br/>
        </div>},
        permissoes: { fields: {} }
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        start()
    }, [values])

    const start = async () => {
        if (values) {
            console.log("VALUESSS", values)

            let newJson = { ...form }
            Object.keys(newJson).forEach(key => {
                newJson[key] = { ...newJson[key], value: values[key] ? values[key] : null }
            })

            if (values.permissoes) {
                console.log("PERMISSÕES", values.permissoes)

                // Separar permissões subcategorias
                let subcategorias = []
                for (let permissao of values.permissoes) {
                    let encontrou = false
                    for (let sub of subcategorias) {
                        if (sub.id == permissao.permissao_subcategoria_id) {
                            encontrou = true
                            break
                        }
                    }
                    if (!encontrou) subcategorias.push({ id: permissao.permissao_subcategoria_id, nome: permissao.permissao_subcategoria_nome, permissoes: [] })
                }

                // Colocar as permissões nas subcategorias
                for (let permissao of values.permissoes) {
                    for (let sub of subcategorias) {
                        if (sub.id == permissao.permissao_subcategoria_id) {
                            sub.permissoes.push(permissao)
                            break
                        }
                    }
                }

                console.log("SUBCATEGORIAS", subcategorias)

                let size = { lg: 4, md: 4, sm: 4 }
                if (subcategorias.length == 1) size = { lg: 12, md: 12, sm: 12 }
                else if (subcategorias.length >= 2) size = { lg: 6, md: 6, sm: 12 }
                //else if (subcategorias.length >= 3) size = { lg: 4, md: 4, sm: 12 }

                subcategorias.forEach(sub => {
                    let options = []
                    sub.permissoes.forEach(perm => { options.push({ label: perm.nome, value: perm.id, help: perm.descricao, checked: perm.permissao == 1 }) })
                    newJson.permissoes.fields[`permissao_subcategoria[${sub.id}]`] = { name: `permissoes[${sub.id}]`, options: options, disabled: checked, label: sub.nome, type: "checkbox-group", size: size, direction: "column" }
                })
            }

            let gruposDeHorarios = await getGruposDeHorarios()

            let optionsGruposDeHorarios = [{ label: translate("Nenhum"), value: null }]
            for (let g of gruposDeHorarios) {
                if (values.grupo_horario_id == g.id) {
                    optionsGruposDeHorarios[0] = { label: g.nome, value: g.id }
                } else {
                    optionsGruposDeHorarios.push({ label: g.nome, value: g.id })
                }
            }

            let optionsGruposDeHorariosExcecao = [{ label: translate("Nenhum"), value: null }]
            for (let g of gruposDeHorarios) {
                if (values.grupo_horario_id_excecao == g.id) {
                    optionsGruposDeHorarios[0] = { label: g.nome, value: g.id }
                } else {
                    optionsGruposDeHorarios.push({ label: g.nome, value: g.id })
                }
            }

            if (optionsGruposDeHorarios.length == 0) optionsGruposDeHorarios = [{ label: translate("Nenhum grupo de horário"), value: null }]

            newJson.grupo_horario_id = { ...newJson.grupo_horario_id, options: optionsGruposDeHorarios, disabled: optionsGruposDeHorarios.length === 0 }

            newJson.grupo_horario_id_excecao = { ...newJson.grupo_horario_id_excecao, options: optionsGruposDeHorariosExcecao, disabled: optionsGruposDeHorarios.length === 0 }
            setForm(newJson)
        }
    }

    const handleSubmit = useCallback (async (event, errors, val) => {
      console.log(val)
        try {
            if (errors.length > 0) return

            let permissoes = []
            Object.keys(val.permissoes).forEach(perm => {
                permissoes = permissoes.concat(val.permissoes[perm])
            })

            val.permissao_total = checked
            val.permissoes = {}
            val.permissoes.forEach(perm => {
                val.permissoes[perm.id] = permissoes.indexOf(perm.id) >= 0
            })
            const grupo = await putGrupo(values.id, val)

            if (refresh) refresh(grupo, "edit")

            setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Grupo")} ${grupo.nome} ${translate("atualizado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: false })); setOpened(false); } }))
        } catch (error) {
            console.error("ModalEdicaoGrupo", error, error.result, error.response)

            setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true }))
        }
    }, [values, refresh, setOpened, translate, setSweetAlert, checked])

  return (
    <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
      <ModalHeader toggle={() => { setOpened(!opened) }}>
        {translate("Atualizar Grupo")}
      </ModalHeader>
      <ModalBody>
        <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />

        {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
      </ModalBody>
    </Modal>
  )
})
