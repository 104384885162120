import React, { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import DefaultForm from "../../form/DefaultForm"

import SweetAlert from "react-bootstrap-sweetalert"

import { getMensagensPreDefinidas, postMensagemPreDefinida } from "../../../services/mensagens-pre-definidas"
import { getConfiguracoes } from "../../../services/configuracoes"

export default withTranslation()(function ModalCadastroMensagemPreDefinida({ opened, setOpened, refresh, setor_id = null, ...props }) {
    function translate (text) {
      return props.t(text)
    }
    
    const [form, setForm] = useState({
        descricao: { name: "descricao", type: "text", label: translate("Descrição"), placeholder: translate("Descrição"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        comando: { name: "comando", type: "text", label: translate("Comando"), placeholder: translate("Comando"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        mensagem: { name: "mensagem", type: "textarea-whatsapp", label: translate("Mensagem"), placeholder: translate("Mensagem"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        start()
    }, [])

    const start = async () => {
        try {

        } catch (error) {
            console.log("ERRORRRR", error)
        }
    }

    useEffect(() => {
        console.log("MUDOUUU", form)
    }, [form])

    const handleSubmit = async (event, errors, values) => {
        try {
            console.log("{ handleSubmit }", errors, values)
            if (errors.length > 0) return

            if (setor_id !== null) {
                values.setor_id = setor_id
            }

            let data = values

            const mensagemPreDefinida = await postMensagemPreDefinida(data)

            console.log("MENSAGEM PRÉ-DEFINIDA CADASTRADA", mensagemPreDefinida)

            if (refresh) refresh(mensagemPreDefinida) // Passar mensagemPreDefinida para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Mensagem Pré-Definida")} ${mensagemPreDefinida.descricao} ${translate("cadastrada com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalCadastroMensagemPreDefinida ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"), text: error?.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                Cadastrar Mensagem Pré-Definida
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})