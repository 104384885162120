import MetisMenu from "metismenujs"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef } from "react"
import { withTranslation } from "react-i18next"
import { Link, withRouter } from "react-router-dom"
import SimpleBar from "simplebar-react"

const SidebarContent = props => {
  function translate (text) {
    return props.t(text)
  }

  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

  }, [props.location.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }



  return (
    <React.Fragment>

      <SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="uil-home-alt"></i>
                <span>{translate("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/atendimentos" className="waves-effect">
                <i className="uil-comments-alt"></i>
                <span>{translate("Atendimentos")}</span>
              </Link>
            </li>

            <li>
              <Link to="/contatos" className="waves-effect">
                <i className="uil-chat-bubble-user"></i>
                <span>{translate("Contatos")}</span>
              </Link>
            </li>

            <li>
              <Link to="/fluxos" className="waves-effect">
                <i className="uil-game-structure"></i>
                <span>{translate("Fluxos")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-users-alt"></i>
                <span>{translate("Usuários")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/usuarios">{translate("Lista de Usuários")}</Link>
                </li>
                <li>
                  <Link to="/grupos">{translate("Grupos")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-cog"></i>
                <span>{translate("Configurações")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/grupos-de-horarios">{translate("Grupos de Horários")}</Link>
                </li>
                <li>
                  <Link to="/canais">{translate("Canais")}</Link>
                </li>
                <li>
                  <Link to="/setores">{translate("Setores")}</Link>
                </li>
                <li>
                  <Link to="/campos-adicionais">{translate("Campos Adicionais")}</Link>
                </li>
                <li>
                  <Link to="/mensagens-pre-definidas">{translate("Mensagens Pré-Definidas")}</Link>
                </li>
                <li>
                  <Link to="/aplicacao">{translate("Aplicação")}</Link>
                </li>
              </ul>
            </li>

          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
