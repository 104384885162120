import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { withTranslation } from "react-i18next"
import { getEstiloAplicacao, putEstiloAplicacao } from "../../../services/aplicacao"
import Carregando from "../../Carregando"
import DefaultForm from "../../form/DefaultForm"
import "./styles.scss"

export default withTranslation()(function Estilo(props, values) {
  const [loading, setLoading] = useState(true)
  const [estilo, setEstilo] = useState(false)

  function translate(text) {
    return props.t(text)
  }

  const [form, setForm] = useState({
    plano_de_fundo: { label: translate("Plano de Fundo"), name: "plano_de_fundo", type: "upload-avatar", placeholder: translate("Plano de Fundo"), errorMessage: translate("Campo obrigatório"), validate: { }, size: { lg: 12, md: 12, sm: 12 } },
  })

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const start = async () => {
    try {
      const estiloAtual = await getEstiloAplicacao()
      setEstilo(estiloAtual)
      let newJson = { ...form }
      Object.keys(newJson).forEach(key => {
        newJson[key] = { ...newJson[key], value: estiloAtual[key] ? `${estiloAtual[key]}` : null }
      })

      setForm(newJson)
    } catch (error) {
      console.log("ERRO", error)
    }

    setLoading(() => false)
  }

  const handleSubmit = async (event, errors, data) => {
    try {
      if (errors.length > 0) return

      await putEstiloAplicacao(estilo.id, data)

      setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `Configurações atualizada com sucesso.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); ; } })//setOpened(false); } })
    } catch (error) {
      setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
    }
  }

  useEffect(() => {
    start()
  }, [])

  return (
    <React.Fragment>
      {loading ? <Carregando />
      :
        <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />
      }

      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
})
