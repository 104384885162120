import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Carregando from "../../../components/Carregando";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { encerrarAtendimento, getAtendimentos } from "../../../services/atendimentos";
import Mensagem from "./Mensagem";
import "./style.scss";

export default withTranslation()(function Atendimento(props) {
  function translate (text) {
    return props.t(text)
  }

  let params = useParams()

  const [atendimento, setAtendimento] = useState(null)
  const [loading, setLoading] = useState(false)

  const [sweetAlert, setSweetAlert] = useState({
      show: false,
      title: "",
      text: "",
      confirmBtnBsStyle: "success",
      onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  useEffect(() => {
      buscarAtendimento()
  }, [])

  const buscarAtendimento = async () => {
    setLoading(true)
    try {
        let resultado = await getAtendimentos(params.id)
        setAtendimento(resultado)
    } catch (error) {
      setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
    }
    setLoading(false)
  }

  const handleTransferirAtendimento = async (atendimento) => {
    try {
      setSweetAlert({
        ...sweetAlert,
        show: true,
        title: translate("Atenção!"),
        text: `${translate("Regra de negócio da para transfência de atendimento vai ser discutida")}`,
        showCancel: true,
        cancelBtnBsText: translate("Não"),
        cancelBtnBsStyle: "danger",
        warning: true,
        onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
        onConfirm: async () => {
          setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: translate("Estamos transferindo o atendimento") })
          try {
            let retorno = "Sucesso" // transferirAtendimento(atendimento.id, atendente.id)
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: translate("Sucesso"), text: retorno || translate("Atendimento encerrado com sucesso!"), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          } catch (error) {
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: translate("Ooops"), text: error.message || translate("Ocorreu um erro."), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleEncerrarAtendimento = async (atendimento) => {
    try {
      setSweetAlert({
        ...sweetAlert,
        show: true,
        title: translate("Atenção!"),
        text: `${translate("Tem certeza que deseja encerrar o atendimento de protocolo")} ${atendimento.protocolo}?`,
        showCancel: true,
        cancelBtnBsText: translate("Não"),
        cancelBtnBsStyle: "danger",
        warning: true,
        onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
        onConfirm: async () => {
          setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: translate("Estamos encerrando o atendimento") })
          try {
            let retorno = await encerrarAtendimento(atendimento.id)
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: translate("Sucesso"), text: retorno || translate("Atendimento encerrado com sucesso!"), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          } catch (error) {
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: translate("Ooops"), text: error.message || translate("Ocorreu um erro."), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {atendimento && <div className="container-fluid" style={{ marginBottom: 10 }}>
            <div className="btn btn-warning" style={{ marginRight: 10 }} onClick={() => handleEncerrarAtendimento(atendimento)}>
              <span>{translate("Encerrar Atendimento")}</span>
            </div>
            <div className="btn btn-primary" style={{ marginRight: 10 }} onClick={() => handleTransferirAtendimento(atendimento)}>
              <span>{translate("Transferir Atendimento")}</span>
            </div>
          </div>}
          <Breadcrumbs title={translate("Atendimento")} breadcrumbItem={translate("Atendimento")} />
          {loading ?
            <Carregando /> :
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="mensagens">
                      {atendimento?.mensagens.length == 0 && <p>{translate("Nenhuma mensagem")}</p>}
                      {atendimento?.mensagens.map(mensagem => <Mensagem mensagem={mensagem} translate={translate}/>)}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>

      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
})
