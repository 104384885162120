import React, { useCallback, useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import Input from "../../form/Input"

const semanas = [
    { value: 1, label: "Seg" },
    { value: 2, label: "Ter" },
    { value: 3, label: "Qua" },
    { value: 4, label: "Qui" },
    { value: 5, label: "Sex" },
    { value: 6, label: "Sáb" },
    { value: 0, label: "Dom" }
]

const meses = [
    { value: 1, label: "Jan" },
    { value: 2, label: "Fev" },
    { value: 3, label: "Mar" },
    { value: 4, label: "Abr" },
    { value: 5, label: "Mai" },
    { value: 6, label: "Jun" },
    { value: 7, label: "Jul" },
    { value: 8, label: "Ago" },
    { value: 9, label: "Set" },
    { value: 10, label: "Out" },
    { value: 11, label: "Nov" },
    { value: 12, label: "Dez" },
]

export default function LinhaHorario({
    id,
    index,
    descricao,
    dia_inicio,
    dia_fim,
    dia_mes_inicio,
    dia_mes_fim,
    dia_semana_inicio,
    dia_semana_fim,
    hora_inicio,
    hora_fim,
    remover,
    removeDisabled,
    removed = false
}) {
    const [fullTime, setFulltime] = useState(false)
    const [dayInicio, setDayInicio] = useState()
    const [dayFim, setDayFim] = useState()
    const [dayMesInicio, setDayMesInicio] = useState()
    const [dayMesFim, setDayMesFim] = useState()
    const [daySemanaInicio, setDaySemanaInicio] = useState()
    const [daySemanaFim, setDaySemanaFim] = useState()
    const [hourInicio, setHourInicio] = useState("")
    const [hourFim, setHourFim] = useState("")

    const handleFullTime = useCallback((id) => {
      if (fullTime) {
        setHourInicio("")
        setHourFim("")
      } else {
        setHourInicio("00:00")
        setHourFim("23:59")
      }
      setFulltime(!fullTime)
    }, [fullTime])

    useEffect(() => {
        if (dia_inicio >= 0) setDayInicio(dia_inicio)
        if (dia_fim >= 0) setDayFim(dia_fim)
        if (dia_mes_inicio >= 0) setDayMesInicio(dia_mes_inicio)
        if (dia_mes_fim >= 0) setDayMesFim(dia_mes_fim)
        if (dia_semana_inicio >= 0) setDaySemanaInicio(dia_semana_inicio)
        if (dia_semana_fim >= 0) setDaySemanaFim(dia_semana_fim)
        if (hora_inicio) setHourInicio(hora_inicio)
        if (hora_fim) setHourFim(hora_fim)
    }, [])

    return (
      <Row id={`horario_${id}`} class={removed ? "d-none" : "inherit"}>
          <Input type="hidden" id={removed} name={`removed[${index}]`} value={removed ? 'sim' : 'nao'} />
          <Input type="hidden" id={id} name={`id[${index}]`} defaultValue={id} />
          <Col lg={2}>
              <Input type="text" placeholder="Descrição" id={`descricao-${id}`} name={`descricao[${index}]`} defaultValue={descricao} required={true} />
          </Col>
          <Col lg={1}>
              <Input type="number" min={1} max={31} id={`dia-inicio-${id}`} name={`dia_inicio[${index}]`} value={dayInicio} onChange={(e) => setDayInicio(e.target.value)} defaultValue={dia_inicio} required={true} />
          </Col>
          <Col lg={1}>
              <Input type="number" min={1} max={31} id={`dia-fim-${id}`} name={`dia_fim[${index}]`} value={dayFim} onChange={(e) => setDayFim(e.target.value)} defaultValue={dia_fim} required={true} />
          </Col>
          <Col lg={1}>
              <Input type="select-with-search" id={`dia-mes-inicio-${id}`} name={`dia_mes_inicio[${index}]`} value={dayMesInicio} onChange={(e) => setDayMesInicio(e.target.value)} options={meses} defaultValue={dia_mes_inicio} required={true} />
          </Col>
          <Col lg={1}>
              <Input type="select-with-search" id={`dia-mes-fim-${id}`} name={`dia_mes_fim[${index}]`} value={dayMesFim} onChange={(e) => setDayMesFim(e.target.value)} options={meses} defaultValue={dia_mes_fim} required={true} />
          </Col>
          <Col lg={1}>
              <Input type="select-with-search" id={`dia-semana-inicio-${id}`} name={`dia_semana_inicio[${index}]`} value={daySemanaInicio} onChange={(e) => setDaySemanaInicio(e.target.value)} options={semanas} defaultValue={dia_semana_inicio} required={true} />
          </Col>
          <Col lg={1}>
              <Input type="select-with-search" id={`dia-semana-fim-${id}`} name={`dia_semana_fim[${index}]`} value={daySemanaFim} onChange={(e) => setDaySemanaFim(e.target.value)} options={semanas} defaultValue={dia_semana_fim} required={true} />
          </Col>
          <Col lg={1}>
              <Button color={fullTime ? "primary" : "info"} style={{ width: "100%" }} onClick={() => handleFullTime(id)} ><i className="fa fa-clock" /></Button>
          </Col>
          <Col lg={1}>
              <Input type="time" id={`horario-inicio-${id}`} name={`hora_inicio[${index}]`} value={hourInicio} onChange={(e) => setHourInicio(e.target.value)} defaultValue={hora_inicio} required={true} />
          </Col>
          <Col lg={1}>
              <Input type="time" id={`horario-fim-${id}`} name={`hora_fim[${index}]`} value={hourFim} onChange={(e) => setHourFim(e.target.value)} defaultValue={hora_fim} required={true} />
          </Col>
          <Col lg={1}>
              <Button color="danger" style={{ width: "100%" }} onClick={() => remover(id)} disabled={removeDisabled}><i className="fa fa-trash" /></Button>
          </Col>
      </Row>
    )
}
