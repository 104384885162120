import React from "react";

const ContatosDinamicosListaColunas = (headers =[], removerContato, setEdicaoModal, permissoes, translate) =>
  headers.map(header => {
    return {
      text: `${translate(header)}`,
      dataField: header,
      sort: true,
      formatter: (cellContent, contato) => (
        <>
          {contato}
        </>
      ),
    }
  });

export default ContatosDinamicosListaColunas;
