import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { putCampoAdicional } from "../../../services/campos-adicionais"
import DefaultForm from "../../form/DefaultForm"





export default withTranslation()(function ModalEdicaoCampoAdicional({ opened, setOpened, refresh, values, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const [form, setForm] = useState({
        nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        descricao: { name: "descricao", type: "textarea", label: translate("Descrição"), placeholder: translate("Descreva o setor"), validate: {}, size: { lg: 12, md: 12, sm: 12 } },

        obrigatorio: { name: "obrigatorio", type: "select", options: [{ value: 1, label: translate("Sim") }, { value: 0, label: translate("Não") }], label: translate("Obrigatório"), validate: {}, size: { lg: 3, md: 3, sm: 12 } },
        permitir_busca: { name: "permitir_busca", type: "select", options: [{ value: 1, label: translate("Sim") }, { value: 0, label: translate("Não") }], label: translate("Permitir Busca"), validate: {}, size: { lg: 3, md: 3, sm: 12 } },
        exibir_lista: { name: "exibir_lista", type: "select", options: [{ value: 1, label: translate("Sim") }, { value: 0, label: translate("Não") }], label: translate("Exibir Lista"), validate: {}, size: { lg: 3, md: 3, sm: 12 } },
        confidencial: { name: "confidencial", type: "select", options: [{ value: 1, label: translate("Sim") }, { value: 0, label: translate("Não") }], label: translate("Confidencial"), validate: {}, size: { lg: 3, md: 3, sm: 12 } },

        validacao_tipo: { name: "validacao_tipo", type: "select", options: [{ value: "null", label: translate("Sem validação") }, { value: "personalizado", label: translate("Personalizado") }, { value: "layout", label: translate("Layout") }], label: translate("Validação"), placeholder: translate("Escolha o tipo de validação"), validate: {}, size: { lg: 12, md: 12, sm: 12 }, onChange: (e) => validacaoTipoAlterado(e.target.value) },
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        start()
    }, [values])

    const validacaoTipoAlterado = (val) => {
      if (val === "0") {
        setForm((form) => {
          let { validacao_layout, condicao_tipo, condicao_valor, ...rest } = form
          return {
            ...rest,
            validacao_tipo: { ...form.validacao_tipo, size: { lg: 12, md: 12, sm: 12 } }
          }
        })
      } else if (val === "personalizado") {
        setForm((form) => {
          let options = [
            { label: "=", value: "=" },
            { label: "<=", value: "<=" },
            { label: ">=", value: ">=" },
            { label: "<>", value: "<>" }
          ]
          let { validacao_layout, ...rest } = form

          return {
            ...rest,
            validacao_tipo: { ...form.validacao_tipo, size: { lg: 3, md: 3, sm: 12 } },
            condicao_tipo: {
              name: "condicao_tipo", type: "select", label: translate("Tipo de Condição"), value: options[0].value, options: options, validate: {}, size: { lg: 3, md: 3, sm: 12 }
            },
            condicao_valor: { name: "condicao_valor", type: "text", label: translate("Layout"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
          }
        })
      } else if (val === "layout") {
        setForm((form) => {
          let options = [
            { label: "CPF", value: "cpf" },
            { label: "CNPJ", value: "cnpj" },
            { label: "CEP", value: "cep" },
            { label: "Data", value: "data" }
          ]

          let { condicao_tipo, condicao_valor, ...rest } = form

          return {
            ...rest,
            validacao_tipo: { ...form.validacao_tipo, size: { lg: 3, md: 3, sm: 12 } },
            validacao_layout: {
              name: "validacao_layout", type: "select", label: translate("Layout"), value: options[0].value, options: options, validate: {}, size: { lg: 9, md: 9, sm: 12 }
            },
          }
        })
      }
    }

    const start = async () => {
      if (values) {
          try {
              setForm(() => {
                  let newJson = { ...form }
                  Object.keys(newJson).forEach(key => {
                      console.log(key, values[key])
                      newJson[key] = { ...newJson[key], value: values.hasOwnProperty(key) ? `${values[key]}` : null }
                  })

                  console.log("NEW JSON", newJson)

                  return newJson
              })

              if (values.condicao_tipo !== null) {
                setForm((form) => {
                  let options = [
                    { label: "=", value: "=" },
                    { label: "<=", value: "<=" },
                    { label: ">=", value: ">=" },
                    { label: "<>", value: "<>" }
                  ]
                  let { validacao_layout, ...rest } = form

                  return {
                    ...rest,
                    validacao_tipo: { ...form.validacao_tipo, size: { lg: 3, md: 3, sm: 12 } },
                    condicao_tipo: {
                      name: "condicao_tipo", type: "select", label: translate("Tipo de Condição"), value: options.filter(item => item.value === values.condicao_tipo)[0].value, options: options, validate: {}, size: { lg: 3, md: 3, sm: 12 }
                    },
                    condicao_valor: { name: "condicao_valor", type: "text", label: translate("Layout"), value: values.condicao_tipo, validate: {}, size: { lg: 6, md: 6, sm: 12 } },
                  }
                })
              }

              if (values.validacao_layout !== null) {
                setForm((form) => {
                  let options = [
                    { label: "CPF", value: "cpf" },
                    { label: "CNPJ", value: "cnpj" },
                    { label: "CEP", value: "cep" },
                    { label: "Data", value: "data" }
                  ]

                  let { condicao_tipo, condicao_valor, ...rest } = form

                  return {
                    ...rest,
                    validacao_tipo: { ...form.validacao_tipo, size: { lg: 3, md: 3, sm: 12 } },
                    validacao_layout: {
                      name: "validacao_layout", type: "select", label: translate("Layout"), value: options.filter(item => item.value === values.validacao_layout)[0].value, options: options, validate: {}, size: { lg: 9, md: 9, sm: 12 }
                    },
                  }
                })
              }
          } catch (error) {
              console.log("ERRO", error)
          }
      }
  }

    const handleSubmit = async (event, errors, val) => {
        try {
            console.log("{ handleSubmit }", errors, val)
            if (errors.length > 0) return

            console.log("handleSubmit", val)

            let data = val

            const setor = await putCampoAdicional(values.id, data)

            console.log("USUARIO ATUALIZADO", setor)

            if (refresh) refresh(setor, "edit") // Passar setor para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: "Sucesso!", text: `CampoAdicional ${setor.nome} atualizado com sucesso.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalEdicaoCampoAdicional ERROR", error, error.result, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: "Ooops!", text: (error.response && error.response?.data) ? error.response?.data : "Ocorreu um erro", success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Atualizar Campo Adicional")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
