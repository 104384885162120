import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

const MensagensPreDefinidasListaColunas = (removerMensagemPreDefinida, setEdicaoModal, permissoes, usuariosOpened, translate) => [
  {
    text: translate("Descrição"),
    dataField: "descricao",
    sort: true,
    formatter: (cellContent, mensagemPreDefinida) => (
      <>
        {
          mensagemPreDefinida.descricao ||
          <i>{translate("Sem descrição")}</i>
        }
      </>
    ),
  },
  {
    text: translate("Comando"),
    dataField: "comando",
    sort: true,
    formatter: (cellContent, mensagemPreDefinida) => (
      <>
        {`/${mensagemPreDefinida.comando}`}
      </>
    ),
  },
  {
    text: translate("Setor"),
    dataField: "setor",
    sort: true,
    formatter: (cellContent, mensagemPreDefinida) => (
      <>
        {
          mensagemPreDefinida.setor_id ?
          <span>{mensagemPreDefinida.setor_id}</span> : <span>{translate("Todos")}</span>
        }
      </>
    ),
  },
  {
    text: translate("Mensagem"),
    dataField: "mensagem",
    sort: true,
    formatter: (cellContent, mensagemPreDefinida) => (
      <span dangerouslySetInnerHTML={{ __html: mensagemPreDefinida.mensagem }}></span>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, mensagemPreDefinida) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#" onClick={() => { setEdicaoModal(mensagemPreDefinida) }}>{translate("Editar")}</DropdownItem>
              <DropdownItem href="#" onClick={() => { removerMensagemPreDefinida(mensagemPreDefinida) }}>{translate("Remover")}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    ),
  },
]

export default MensagensPreDefinidasListaColunas
