import api from "./api"

const getPermissoesUsuario = async () => {
    try {
        const resultado = await api.get(`/permissoes/sessao`)
        console.log("{ getPermissoesUsuario }", resultado)
        if (!resultado) throw {}
        const permissoes = resultado.data
        return permissoes
    } catch (error) {
        throw error
    }
}

const getPermissoes = async () => {
    try {
        const resultado = await api.get(`/permissoes?resumido=false`)
        const permissoes = resultado.data
        return permissoes
    } catch (error) {
        throw error
    }
}

export { getPermissoesUsuario, getPermissoes }