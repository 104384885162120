import api from "./api"

const getRelatoriosAtivos = async () => {
  try {
    const resultado = await api.get("/chat/relatorios/ativos")
    const relatorios = resultado.data
    return relatorios
  } catch (error) {
    throw error
  }
}

export { getRelatoriosAtivos }
