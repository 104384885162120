import { AvCheckbox, AvCheckboxGroup, AvField } from "availity-reactstrap-validation";
import { ContentState, convertFromHTML, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import React, { memo, useEffect, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { CopyToClipboard } from 'react-copy-to-clipboard';
// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FileBase64 from 'react-file-base64';
import Select from "react-select";
import TooltipHelp from '../../helpers/TooltipHelp';
import MensagemWhatsapp from "../../MensagemWhatsapp";
import "./style.scss";








const Input = (props) => {
    //console.log("Input", props)

    const { type = "text", id, name, label, help, options, errorMessage = "Campo incorreto", size, isMulti, isDisabled, ...rest } = props

    const [multiselectValue, setMultiselectValue] = useState([])
    const [selectValue, setSelectValue] = useState("")
    const [src, setSrc] = useState(null)
    const [edit, setEdit] = useState(true)
    const [val, setVal] = useState("")
    const [editorState] = useState((type == "textarea-whatsapp" && props.value) ? EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML(props.value)
        )) : null)

    useEffect(() => {
        if (type === "multiselect" && props.selecteds) {
            handleChangeMultiselect(props.selecteds)
        }

        if (type === "upload-avatar") {
            if (props.value) setSrc(props.value)
            if (props.hasOwnProperty("edit") && props.edit == false) setEdit(false)
        }

        if (type === "textarea-whatsapp") {
            if (props.value) {
                setVal(props.value)
            }
        }

        if (type === "file") {
            console.log("IMAGEMMMM", props)
            if (props.value) {
                setSrc(props.value)
            }
        }
    }, [props])

    const onBeforeFileLoad = (elem) => {
        if (elem.target.files[0].size > 41943040) {
            alert("Arquivo muito grande!");
            elem.target.value = "";
        };
    }

    const handleChangSelect = (value) => {
        console.log("{ handleChangeSelect }", value)
        setSelectValue(value)
    }

    const handleChangeMultiselect = (values) => {
        console.log("{ handleChangeMultiselect }", values)
        let value = []
        if (values)
            values.forEach(valor => { value.push(valor.value) })
        setMultiselectValue(value)
    }

    try {
        return (
            <div className="mb-3">
                <div className="label-wrapper">
                    {label && label !== "" && <label htmlFor={id}>{label}{rest.validate && rest.validate.required && rest.validate.required.value && <span>*</span>}</label>}
                    {help && <TooltipHelp text={help} />}
                </div>

                {["text", "email", "textarea", "number", "color", "password", "time"].includes(type) && <AvField className="mb-3" name={name} id={id || name} type={type} errorMessage={errorMessage} {...rest} />}
                {["hidden"].includes(type) && <AvField className="mb-3" name={name} id={id || name} type={"text"} errorMessage={errorMessage} {...rest} style={{ display: "none" }} />}
                {type === "file" && <>
                    <FileBase64 multiple={props.multiple || false} onDone={(files) => {
                        setSrc(files.base64)
                        if (props.onChange)
                            props.onChange(files.base64)
                    }} />
                    <AvField className="mb-3" name={name} id={id || name} type={"textarea"} errorMessage={errorMessage} {...rest} style={{ display: "none" }} value={src} />
                </>}
                {type === "select-search" && <>
                  <Select name={name} id={id || name} defaultValue={options[0]} errorMessage={errorMessage} options={options} {...rest} />
                </>}
                {type === "select" && <>
                    {/*<Select name={name} id={id || name} classNamePrefix="select2-search-disable" {...rest} />*/}
                    <AvField type="select" id={id || name} name={name} {...rest}>
                        {options && options.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </AvField>
                </>}
                {type === "select-with-search" && <>
                    <Select {...rest} id={id || name} classNamePrefix="select2-search" options={options} value={selectValue || options.find(item => item.value == props.defaultValue)} onChange={handleChangSelect} isDisabled={isDisabled} errorMessage={errorMessage} placeholder="" />
                    <AvField type="select" name={name} {...rest} errorMessage={errorMessage} defaultValue={options.find(item => item.value == props.defaultValue)} value={selectValue || options.find(item => item.value == props.defaultValue)} style={{ display: "none" }}>
                        {options && options.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </AvField>
                </>}
                {type === "multiselect" && <>
                    <Select {...rest} id={id || name} classNamePrefix="select2-search-disable" options={options} onChange={handleChangeMultiselect} defaultValue={props.selecteds || []} isMulti={isMulti} isDisabled={isDisabled} errorMessage={errorMessage} />
                    <AvField type="select" multiple name={name} {...rest} value={multiselectValue} style={{ display: "none" }}>
                        {options && options.map((option, index) => (
                            <option key={index} value={option.value}>{option.label}</option>
                        ))}
                    </AvField>
                </>}
                {type === "checkbox-group" && <>
                    <AvCheckboxGroup key={id || name} className="AvCheckboxGroup" inline name={name} id={id || name} errorMessage={errorMessage} {...rest} defaultValue={(() => {
                        let defaults = []
                        options.forEach(opt => { if (opt.checked) defaults.push(opt.value) })
                        return defaults
                    })()}>
                        {options && options.map((option, index) => (
                            <>
                                <AvCheckbox key={`${id || name}-${index}`} label={option.help ? <>{option.label} <TooltipHelp text={option.help} /></> : option.label} value={option.value} />
                                {rest.direction && rest.direction === "column" && <br />}
                            </>
                        ))}
                    </AvCheckboxGroup>
                </>}

                {type === "upload-avatar" && <>
                    {!edit ? <div style={{ position: "relative" }}>
                        <img src={src} style={{ borderRadius: '100%' }} />
                        <a href="#" style={{ position: "absolute", top: 0, left: 0, fontSize: 16, color: "#485056" }} onClick={() => setEdit(true)}>
                            <i className="uil-edit me-1"></i>
                        </a>
                    </div> : <>
                        <Avatar width={150} height={150} imageWidth={150} cropRadius={75} label="Escolha uma imagem" labelStyle={{ fontSize: 14 }} onCrop={pre => setSrc(pre)} onClose={() => {
                            if (props.hasOwnProperty("edit") && props.edit == false) { setEdit(false) }
                            if (props.value) { setSrc(props.value) }
                        }} onBeforeFileLoad={onBeforeFileLoad} exportQuality={1} {...rest} />
                        <AvField className="mb-3" name={name} id={id || name} type={type} errorMessage={errorMessage} {...rest} style={{ display: "none" }} value={src} />
                    </>}
                </>}

                {type === "info" && <p>{props.value}</p>}

                {type === "clipboard" && <>
                    <CopyToClipboard text={props.value} className="copy">
                        <div>
                            <p>{props.value}</p>
                            <i className="uil-copy" />
                        </div>
                    </CopyToClipboard>
                </>}

                {type === "editor" && <>
                    <Editor
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onChange={(e) => {
                            setVal(draftToHtml(e))
                        }}
                        defaultEditorState={editorState}
                    />
                    <AvField className="mb-3" name={name} type="textarea" {...rest} value={val} style={{ display: "none" }} />
                </>}

                {type === "textarea-whatsapp" && <>
                    <div className="textarea-whatsapp">
                        <div className="tw-editor">
                            <Editor
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onChange={(e) => {
                                    setVal(draftToHtml(e))
                                }}
                                defaultEditorState={editorState}
                                toolbar={{
                                    options: ['inline', 'emoji'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline']
                                    },
                                }}
                            />
                            <AvField className="mb-3" name={name} type="textarea" {...rest} value={val} style={{ display: "none" }} />
                        </div>
                        <div className="tw-simulador">
                            <MensagemWhatsapp value={val} imagem={rest.imagem || null} />
                        </div>
                    </div>
                </>}

                {type === "row-group" && <>

                </>}
            </div >
        )
    } catch (error) {
        return <h5>Teste</h5>
    }
}

export default memo(Input)
