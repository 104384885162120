import React, { useContext } from 'react';
import FluxoContext from '../../../../../contexts/FluxoContext';
import NodePersonalizado from './NodePersonalizado';

import "./style.scss"

export default function NodeMenu({ data, id, type, ...rest }) {
  const { setModalEdicaoMenu } = useContext(FluxoContext)

  const abrirModal = () => {
    setModalEdicaoMenu({
      id: id,
      data: data
    })
  }

  return <NodePersonalizado
    key={id}
    id={id}
    label={data?.nome}
    data={data}
    type={type}
    handles={data?.etapa?.opcoes ? data.etapa.opcoes.length : data.opcoes ? data.opcoes.length : 0}
    buttons={[
      { color: "dark", text: <i className="uil uil-list-ul"></i>, onClick: abrirModal }
    ]}
    {...rest}
  />
}
