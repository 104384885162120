import React from "react"
import { Container } from "reactstrap"
import { ReactFlowProvider } from 'react-flow-renderer';

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Fluxo from "./Fluxo"

import Menu from "./Menu"

import "./style.scss"
import { FluxoProvider } from "../../../contexts/FluxoContext";

import FluxoWrapper from "../FluxoWrapper";

export default function FluxoEditor() {

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Fluxos" breadcrumbItem="Editor de Fluxos" />
                    <hr />

                    <ReactFlowProvider>
                        <FluxoProvider>
                            <FluxoWrapper />
                        </FluxoProvider>
                    </ReactFlowProvider>
                </Container>
            </div>
        </>
    )
}