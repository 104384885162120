import React from "react"
import { Redirect } from "react-router-dom"
import Aplicacao from "../pages/Aplicacao"
import Atendimento from "../pages/Atendimentos/Atendimento"
import AtendimentosLista from "../pages/Atendimentos/AtendimentosLista/AtendimentosLista"
import Confirmacao from "../pages/Authentication/Confirmacao"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
// Profile
import UserProfile from "../pages/Authentication/user-profile"
import CamposAdicionaisLista from "../pages/CamposAdicionais/CamposAdicionaisLista/CamposAdicionaisLista"
import CanaisLista from "../pages/Canais/CanaisLista/CanaisLista"
import ContatosLista from "../pages/Contatos/ContatosLista/ContatosLista"
// Dashboard
import Dashboard from "../pages/Dashboard/index"
import FluxoEditor from "../pages/Fluxos/FluxoEditor"
import FluxosLista from "../pages/Fluxos/FluxosLista/FluxosLista"
import GruposLista from "../pages/Grupos/GruposLista/GruposLista"
import GruposDeHorariosLista from "../pages/GruposDeHorarios/GruposDeHorariosLista/GruposDeHorariosLista"
import MensagensPreDefinidasLista from "../pages/MensagensPreDefinidas/MensagensPreDefinidasLista/MensagensPreDefinidasLista"
import SetoresLista from "../pages/Setores/SetoresLista/SetoresLista"
import UsuariosLista from "../pages/Usuarios/UsuariosLista/UsuariosLista"



const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/contatos", component: ContatosLista },

  { path: "/atendimentos", component: AtendimentosLista },
  { path: "/atendimentos/:id", component: Atendimento },

  { path: "/usuarios", component: UsuariosLista },

  { path: "/grupos", component: GruposLista },

  { path: "/grupos-de-horarios", component: GruposDeHorariosLista },

  { path: "/setores", component: SetoresLista },

  { path: "/campos-adicionais", component: CamposAdicionaisLista },

  { path: "/mensagens-pre-definidas", component: MensagensPreDefinidasLista },

  { path: "/canais", component: CanaisLista },

  { path: "/aplicacao", component: Aplicacao },

  { path: "/fluxos", component: FluxosLista },
  { path: "/fluxos/editor", component: FluxoEditor },
  { path: "/fluxos/editor/:id", component: FluxoEditor },

  // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/confirmacao", component: Confirmacao },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

]

export { userRoutes, authRoutes }
