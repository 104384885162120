import React from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import { formatDataHora } from "../../../services/formatService";


const ContatosListaColunas = (removerContato, setEdicaoModal, permissoes, translate) => [
	{
		text: `${translate("Nome")}`,
		dataField: "nome",
		sort: true,
		formatter: (cellContent, contato) => (
			<>
				{!contato.foto ? (
					<div className="avatar-xs d-inline-block me-2">
						<div className="avatar-title bg-soft-primary rounded-circle text-primary">
							<i className="mdi mdi-account-circle m-0"></i>
						</div>
					</div>
				) : (
					<img
						className="avatar-xs rounded-circle me-2"
						src={contato.foto}
						alt=""
					/>
				)}
				<Link to="#" className="text-body" onClick={() => setEdicaoModal(contato)}>
					{contato.nome}
				</Link>
			</>
		),
	},
	{
		text: `${translate("Desde")}`,
		dataField: "datahora_cadastro",
		sort: true,
		formatter: (cellContent, contato) => (
			<>
				{formatDataHora(contato.datahora_cadastro, "DD/MM/YYYY")}
			</>
		),
	},
	{
		dataField: "menu",
		isDummyField: true,
		text: "",
		formatter: (cellContent, contato) => (
			<ul className="list-inline font-size-20 contact-links mb-0">
				<li className="list-inline-item dropdown">
					<UncontrolledDropdown>
						<DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
							<i className="uil uil-ellipsis-v"></i>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-end">
							<DropdownItem href="#">{translate("Visualizar")}</DropdownItem>
							{permissoes.grupos_gerenciamento_contatos && <DropdownItem href="#" onClick={() => { setEdicaoModal(contato); }}>{translate("Editar")}</DropdownItem>}
							{permissoes.grupos_gerenciamento_contatos && <DropdownItem href="#" onClick={() => { removerContato(contato); }}>{translate("Deletar")}</DropdownItem>}
						</DropdownMenu>
					</UncontrolledDropdown>
				</li>
			</ul>
		),
	},
];

export default ContatosListaColunas;
