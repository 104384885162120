import React, { useCallback, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import { withTranslation } from "react-i18next"
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import ContatosDinamicosListaColunas from "../../../pages/Contatos/ContatosLista/ContatosDinamicosListaColunas"
import { getCamposAdicionais } from "../../../services/campos-adicionais"
import { postContatosImportados } from "../../../services/contatos"
import FileReader from "../../Files/Reader"

export default withTranslation()(function ModalImportarContatos({ opened, setOpened, refresh, translate }) {

  const [camposAdicionais, setCamposAdicionais] = useState([])
  const [contatos, setContatos] = useState({ headers : [], data: [] })
  const [step, setStep] = useState(0)
  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

	const buscarCamposAdicionais = useCallback(async () => {
    try {
      const camposAdicionais = await getCamposAdicionais()
      setCamposAdicionais(camposAdicionais)

    } catch (error) {
      setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Ooops!"), text: error.response?.data ? error.response?.data : translate("Ocorreu um erro"), success: false, error: true }));
    }
	}, [translate]);

  const handleSubmit = useCallback(async (event, errors, data) => {
    try {
      if (errors.length > 0) return

      const newData = {
        nome: data.nome,
        campos_adicionais: []
      }

      for (var campo of camposAdicionais) {
        if(data.hasOwnProperty(`campo_adicional_${campo.id}`)) {
          newData.campos_adicionais.push({ id: campo.id, valor: data[`campo_adicional_${campo.id}`] })
        }
      }
      const contato = await postContatosImportados(newData)
      if (refresh) refresh(contato)
      setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Contato")} ${contato.nome} ${translate("cadastrado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } }));
    } catch (error) {
      setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Ooops!"), text: error.response?.data ? error.response?.data : translate("Ocorreu um erro"), success: false, error: true }));
    }
  }, [refresh, translate, setOpened, camposAdicionais]);

	useEffect(() => {
		buscarCamposAdicionais();
	}, [buscarCamposAdicionais]);

  return (
    <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
      <ModalHeader toggle={() => { setOpened(!opened) }}>
        {translate("Importar Contatos")}
      </ModalHeader>
      <ModalBody>
        <FileReader hidden={!!step} action={(data) => {setContatos(data);setStep(1)}}/>

          <ToolkitProvider
            keyField="id"
            data={contatos.data || []}
            columns={ContatosDinamicosListaColunas(contatos.headers, () => {}, setOpened, [], translate)}
            bootstrap4
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive mb-4">
                      <BootstrapTable
                        selectRow={{ mode: "checkbox" }}
                        responsive
                        remote
                        bordered={false}
                        striped={false}
                        classes={"table table-centered table-nowrap mb-0"}
                        {...toolkitProps.baseProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
          <Button color="primary" type="button" disabled={!!contatos[0]}>{translate("Salvar Contatos")}</Button>

        {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
      </ModalBody>
    </Modal>
  )
});
