import api from "./api"

const getConfiguracoes = async (categoria) => {
    try {
        const resultado = await api.get(`/configuracoes/${categoria}?completo=false`)
        const configuracoes = resultado.data
        return configuracoes
    } catch (error) {
        throw error
    }
}

export { getConfiguracoes }