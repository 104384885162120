import api from "./api"

const getGruposDeHorarios = async () => {
    try {
        const { data } = await api.get("/grupos-de-horarios")
        return data
    } catch (error) {
        throw error
    }
}

const getByIdGrupoDeHorario = async (id) => {
    try {
        const { data } = await api.get(`/grupos-de-horarios?id=${id}`)
        return data[0]
    } catch (error) {
        throw error
    }
}

const postGrupoDeHorario = async (data) => {
    try {
        const resultado = await api.post("/grupos-de-horarios", data)
        return resultado.data
    } catch (error) {
        throw error
    }
}

const putGrupoDeHorario = async (id, data) => {
    try {
        const resultado = await api.put(`/grupos-de-horarios/${id}`, data)
        return resultado.data
    } catch (error) {
        throw error
    }
}

const deleteGrupoDeHorario = async (id) => {
    try {
        const resultado = await api.delete(`/grupos-de-horarios/${id}`)
        return resultado.data
    } catch (error) {
        throw error
    }
}

export { getGruposDeHorarios, getByIdGrupoDeHorario, postGrupoDeHorario, putGrupoDeHorario, deleteGrupoDeHorario }