import api from "./api"

const getGrupos = async () => {
    try {
        const resultado = await api.get("/grupos")
        const grupos = resultado.data
        return grupos
    } catch (error) {
        throw error
    }
}

const postGrupo = async (data) => {
    try {
        const resultado = await api.post("/grupos", data)
        const grupos = resultado.data
        return grupos
    } catch (error) {
        throw error
    }
}

const putGrupo = async (id, data) => {
    try {
        const resultado = await api.put(`/grupos/${id}`, data)
        const grupos = resultado.data
        return grupos
    } catch (error) {
        throw error
    }
}

const deleteGrupo = async (id) => {
    try {
        const resultado = await api.delete(`/grupos/${id}`)
        const grupos = resultado.data
        return grupos
    } catch (error) {
        throw error
    }
}

export { getGrupos, postGrupo, putGrupo, deleteGrupo }
