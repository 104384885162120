import React, { useEffect, useState, useContext } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

import DefaultForm from "../../../../components/form/DefaultForm"
import Carregando from "../../../../components/Carregando"

import SweetAlert from "react-bootstrap-sweetalert"

import FluxoContext from "../../../../contexts/FluxoContext"

import { getSetores } from "../../../../services/setores"
import MDHorario from "./tipos/MDHorario"

export default function ModalEdicaoTipo() {
  const { modalEdicaoIsOpened, setModalEdicaoIsOpened, modalEdicao, elements, setElements } = useContext(FluxoContext)

  const [form, setForm] = useState({})

  const [loading, setLoading] = useState(true)

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  useEffect(() => {
    if (modalEdicaoIsOpened)
      start()
  }, [modalEdicaoIsOpened])

  useEffect(() => {
    // console.log("FORM ALTERADO", form)
  }, [form])

  const start = async () => {
    console.log("{ start }", modalEdicao, elements)

    setLoading(() => true)

    if (modalEdicao.tipo == "mensagem") { // Mensagem
      setForm({
        nome: { name: "nome", type: "text", value: modalEdicao.data.nome, label: "Nome", size: { lg: 12, md: 12, sm: 12 } },
        mensagem: { name: "mensagem", type: "textarea-whatsapp", value: modalEdicao.data.mensagem || modalEdicao.data?.etapa?.mensagem, label: "Mensagem", size: { lg: 12, md: 12, sm: 12 } },
      })
    } else if (modalEdicao.tipo == "imagem") { // Imagem
      setForm({
        nome: { name: "nome", type: "text", value: modalEdicao.data.nome, label: "Nome", size: { lg: 12, md: 12, sm: 12 }, validate: { required: { value: true } } },
        link: {
          name: "imagem", type: "file", value: modalEdicao.data.link || modalEdicao.data?.etapa?.link, label: "Imagem", size: { lg: 12, md: 12, sm: 12 }, validate: { required: { value: true } }, onChange: (file) => {
            setForm((form) => {
              return { ...form, link: { ...form.link, imagem: file } }
            })
          }
        },
        mensagem: { name: "mensagem", type: "textarea-whatsapp", value: modalEdicao.data.mensagem || modalEdicao.data?.etapa?.mensagem, imagem: modalEdicao.data.link || modalEdicao.data?.etapa?.link, label: "Mensagem", size: { lg: 12, md: 12, sm: 12 } },
      })
    } else if (modalEdicao.tipo == "entrada") { // Entrada
      let vars = getVariaveis()
      setForm({
        nome: { name: "nome", type: "text", value: modalEdicao.data.nome, label: "Nome", size: { lg: 12, md: 12, sm: 12 } },
        // fluxo_variavel_uuid: { name: "fluxo_variavel_uuid", type: "select", options: vars, value: modalEdicao.data.fluxo_variavel_uuid || modalEdicao.data?.etapa?.fluxo_variavel_uuid, label: "Variável", size: { lg: 12, md: 12, sm: 12 } },
        variavel: { name: "variavel", type: "text", options: vars, value: modalEdicao.data.variavel || modalEdicao.data?.etapa?.variavel, label: "Variável", size: { lg: 12, md: 12, sm: 12 } },
        mensagem: { name: "mensagem", type: "textarea-whatsapp", value: modalEdicao.data.mensagem || modalEdicao.data?.etapa?.mensagem, label: "Mensagem", size: { lg: 12, md: 12, sm: 12 } },
      })
    } else if (modalEdicao.tipo == "variavel") { // Variável
      setForm({
        chave: { name: "chave", type: "text", value: modalEdicao.data.chave || modalEdicao.data?.etapa?.chave, label: "Chave", validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
        valor: { name: "valor", type: "text", value: modalEdicao.data.valor || modalEdicao.data?.etapa?.valor, label: "Valor", validate: {}, size: { lg: 6, md: 6, sm: 12 } }
      })
    } else if (modalEdicao.tipo == "condicao") { // Condição
      let senao_fluxo_etapa_uuid = null
      let destinos = [{ label: "-", value: null }]
      elements.filter(e => e.type && e.type != "inicial" && e.id != modalEdicao.id).map(e => {
        if (e?.data?.fluxo_etapa_id == modalEdicao.data.senao_fluxo_etapa_uuid || e?.id == modalEdicao.data.senao_fluxo_etapa_uuid)
          senao_fluxo_etapa_uuid = e?.id

        destinos.push({
          label: e.type == "variavel" ? `Variável "${e?.data?.chave}"` : e?.data?.nome,
          value: e?.id
        })
      })

      setForm({
        nome: { name: "nome", type: "text", value: modalEdicao.data.nome, label: "Nome", size: { lg: 12, md: 12, sm: 12 } },
        senao_fluxo_etapa_uuid: { name: "senao_fluxo_etapa_uuid", type: "select", options: destinos, value: senao_fluxo_etapa_uuid, label: "Se não", size: { lg: 12, md: 12, sm: 12 } },
      })
    } else if (modalEdicao.tipo == "menu") { // Menu
      setForm({
        nome: { name: "nome", type: "text", value: modalEdicao.data.nome, label: "Nome", size: { lg: 12, md: 12, sm: 12 } },
        mensagem_cabecalho: { name: "mensagem_cabecalho", type: "textarea-whatsapp", value: modalEdicao.data.mensagem_cabecalho || modalEdicao.data?.etapa?.mensagem_cabecalho, label: "Mensagem de Cabeçalho", size: { lg: 12, md: 12, sm: 12 } },
        mensagem_rodape: { name: "mensagem_rodape", type: "textarea-whatsapp", value: modalEdicao.data.mensagem_rodape || modalEdicao.data?.etapa?.mensagem_rodape, label: "Mensagem de Rodapé", size: { lg: 12, md: 12, sm: 12 } },
        maximo_tentativas: { name: "maximo_tentativas", type: "text", value: modalEdicao.data.maximo_tentativas || modalEdicao.data.maximo_tentativas, label: "Máximo de tentativas", size: { lg: 4, md: 4, sm: 12 } }
      })
    } else if (modalEdicao.tipo == "finalizar") { // Finalizar
      setForm({
        nome: { name: "nome", type: "text", value: modalEdicao.data.nome, label: "Nome", size: { lg: 12, md: 12, sm: 12 } },
        mensagem: { name: "mensagem", type: "textarea-whatsapp", value: modalEdicao.data.mensagem || modalEdicao.data?.etapa?.mensagem, label: "Mensagem", size: { lg: 12, md: 12, sm: 12 } },
      })
    } else if (modalEdicao.tipo == "setor") { // Ir para setor
      let setores = await getSetores()

      console.log(setores)

      let options = []
      for (let setor of setores) {
        options.push({ label: setor.nome, value: setor.id })
      }

      setForm({
        setor_id: { name: "setor_id", type: "select", options: options, value: modalEdicao.data.setor_id || modalEdicao.data?.etapa?.setor_id, label: "Setor", size: { lg: 12, md: 12, sm: 12 } },
      })
    }

    setLoading(() => false)
  }

  const handleSubmit = async (event, errors, val) => {
    try {
      console.log("{ handleSubmit }", errors, val, elements)
      if (errors.length > 0) return

      let newElements = [...elements]
      for (let el of newElements) {
        if (el.id == modalEdicao.id) {
          console.log("AQUI", el)
          if (el.type == "menu")
            el.data = { ...val, opcoes: el.data.opcoes }
          else if (el.type == "condicao") {
            el.data = { ...val, condicoes: el.data.condicoes }

            newElements = newElements.filter(e => e.sourceHandle != `handle-off-${el.id}`)

            if (el.data.senao_fluxo_etapa_uuid) {
              newElements.push({
                id: `reactflow__edge-${el.id}handle-off-${el.data.senao_fluxo_etapa_uuid}`,
                source: el.id,
                sourceHandle: `handle-off-${el.id}`,
                target: el.data.senao_fluxo_etapa_uuid,
                targetHandle: null
              })
            }
          } else if (el.type == "horario") {
            el.data = val;
            // console.log("valores", el)

            (["fluxo_etapa_id_grupo_horario", "fluxo_etapa_id_grupo_horario_excecao", "proximo_fluxo_etapa_id"]).map((key, index) => {
              if (
                (key == "fluxo_etapa_id_grupo_horario" && !isNaN(+el.data.grupo_horario_id)) ||
                (key == "fluxo_etapa_id_grupo_horario_excecao" && !isNaN(+el.data.grupo_horario_id_excecao)) ||
                key == "proximo_fluxo_etapa_id"
              ) {
                let target = el.data[key]
                // console.log("target", target)
                if (target && !["-"].includes(target)) {
                  newElements.push({
                    id: `reactflow__edge-${el.id}handle-${index}-${target}`,
                    source: el.id,
                    sourceHandle: `handle-${index}-${el.id}`,
                    target: target,
                    targetHandle: null
                  })
                }
              }
            })
          } else
            el.data = val
        }
      }

      console.log("NEW ELEMENT", newElements)

      setElements(newElements)

      setModalEdicaoIsOpened(false)
    } catch (error) {
      console.error(error)
    }
  }

  const getVariaveis = () => {
    console.log("getVariaveis", elements)
    let variaveis = []
    for (let el of elements) {
      if (el.type == "variavel") {
        variaveis.push({ label: el.data.etapa ? el.data.etapa.chave : el.data.chave, value: el.id })
        break
      }
    }
    return variaveis
  }

  return (
    <Modal size="lg" isOpen={modalEdicaoIsOpened} toggle={() => { setModalEdicaoIsOpened(!modalEdicaoIsOpened) }}>
      <ModalHeader toggle={() => { setModalEdicaoIsOpened(!modalEdicaoIsOpened) }}>
        Atualizar
      </ModalHeader>
      <ModalBody>

        {loading ?
          <Carregando /> :
          (
            modalEdicao.tipo == "horario" ? <MDHorario modalEdicao={modalEdicao} handleSubmit={handleSubmit} /> :
              <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText="Atualizar" />
          )
        }

        {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
      </ModalBody>
    </Modal>
  )
}
