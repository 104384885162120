import PropTypes from "prop-types";
import React, { useCallback, useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Carregando from "../../../components/Carregando";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ModalCadastroContato from "../../../components/modals/contatos/ModalCadastroContato";
import ModalEdicaoContato from "../../../components/modals/contatos/ModalEdicaoContato";
import ModalImportarContatos from "../../../components/modals/contatos/ModalImportarContatos";
import PermissaoContext from "../../../contexts/PermissaoContext";
import { deleteContato, getContatos } from "../../../services/contatos";
import ContatosListaColunas from "./ContatosListaColunas";

const ContatosLista = props => {
  const { t } = props;
	const { permissoes, isLoadingPermissoes } = useContext(PermissaoContext);
	const [initialList, setInitialList] = useState([]);
	const [list, setList] = useState([]);
	const [pageOptions, setPageOptions] = useState({
		sizePerPage: 10,
		totalSize: 1,
		custom: true
	});
	const { SearchBar } = Search;
	const [modalImportarOpened, setModalImportarOpened] = useState(false);
	const [modalCadastroOpened, setModalCadastroOpened] = useState(false);
	const [modalEdicao, setModalEdicao] = useState(null);
	const [modalEdicaoOpened, setModalEdicaoOpened] = useState(false);
	const [sweetAlert, setSweetAlert] = useState({
		show: false,
		title: "",
		text: "",
		confirmBtnBsStyle: "success",
		onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); }
	});

	const translate = useCallback((text) => {
		return t(text);
	}, [t]);

  const handleTableChange = useCallback ((_, { page, searchText }, data) => {
    const list = data || initialList

		setList(() => {
      const inicio = pageOptions.sizePerPage * page - pageOptions.sizePerPage;
		  const fim = pageOptions.sizePerPage * page;

      return list.slice(inicio, fim > list.length ? list.length : fim).filter(
				user => Object.keys(user).some(key => typeof user[key] === "string"
        && user[key].toLowerCase().includes(searchText.toLowerCase()))
			)
    });
	}, [initialList, pageOptions.sizePerPage]);

	const handleRefresh = useCallback((data, action = "add") => {
    console.log(action, data)
		if (action === "add") {
			setInitialList((list) => [...list, data]);
			setPageOptions((current) => ({ ...current, totalSize: current.totalSize + 1 }));
		} else if (action === "edit") {
			setInitialList((list) => list.map(item => {
        if (String(item.id) === String(data.id)) {
          return data
        } else {
          return item
        }
      }));
		} else if (action === "remove") {
			setInitialList((list) => list.filter(item => String(item.id) !== String(data.id)));
			setPageOptions((current) => ({ ...current, totalSize: current.totalSize - 1 }));
		}
  }, []);

	const removerContato = useCallback((contato) => {
    setSweetAlert((sweetAlert) => ({
      ...sweetAlert,
      show: true,
      title: translate("Atenção!"),
      text: `${translate("Tem certeza que deseja excluir o contato")} ${contato.nome}?`,
      showCancel: true,
      cancelBtnBsText: translate("Não"),
      cancelBtnBsStyle: "danger",
      warning: true,
      onConfirm: async () => {
        setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: translate("Estamos removendo o contato") });
        try {
          let retorno = await deleteContato(contato.id);
          setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: translate("Sucesso"), text: retorno || translate("Contato removido com sucesso!"), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); } });
          handleRefresh(contato, "remove");
          setModalEdicaoOpened(null);
        } catch (error) {
          setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: translate("Ooops"), text: error.message || translate("Ocorreu um erro."), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); } });
        }
      }
    }));
	}, [handleRefresh, translate]);

	async function carregarContatos () {
    const contatos = await getContatos();
    setInitialList(contatos)
    setList(contatos)
	};

	useEffect(() => {
		setModalEdicaoOpened(modalEdicao != null);
	}, [modalEdicao]);

	useEffect(() => {
		if (!modalEdicaoOpened) setModalEdicao(null);
	}, [modalEdicaoOpened]);

  useEffect(() => {
    handleTableChange(null, { page: 1, searchText: "" }, initialList);
  }, [initialList, handleTableChange])

	useEffect(() => {
		carregarContatos();
	}, []);

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs title={translate("Contatos")} breadcrumbItem={translate("Lista de Contatos")} />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									{isLoadingPermissoes ? <Carregando /> :
										<PaginationProvider
											pagination={paginationFactory(pageOptions)}
										>
											{({ paginationProps, paginationTableProps }) => (
												<ToolkitProvider
													keyField="id"
													data={list || []}
													columns={ContatosListaColunas(removerContato, setModalEdicao, permissoes, translate)}
													bootstrap4
													search
												>
													{toolkitProps => (
														<React.Fragment>
															<Row className="row mb-2">
																<Col md={2}>
																	{true && <div className="mb-3">
																		<Link to="#" className="btn btn-success waves-effect waves-light" onClick={() => setModalCadastroOpened(true)}><i className="mdi mdi-plus me-2"></i>{translate("Novo")}</Link>
																	</div>}
																</Col>

																<Col md={8}>
																	<div className="form-inline float-md-end mb-3">
																		<div className="search-box ms-2">
																			<div className="position-relative">
																				<SearchBar {...toolkitProps.searchProps} />
																				<i className="mdi mdi-magnify search-icon"></i>
																			</div>
																		</div>
																	</div>
																</Col>

																<Col md={2}>
																	{true && <div className="mb-3">
																		<Link to="#" className="btn btn-success waves-effect waves-light" onClick={() => setModalImportarOpened(true)}><i className="mdi mdi-plus me-2"></i>{translate("Importar Contatos")}</Link>
																	</div>}
																</Col>
															</Row>
															<Row>
																<Col xl="12">
																	<div className="table-responsive mb-4">
																		<BootstrapTable
																			selectRow={{ mode: "checkbox" }}
																			responsive
																			remote
																			bordered={false}
																			striped={false}
																			classes={"table table-centered table-nowrap mb-0"}
																			{...toolkitProps.baseProps}
																			onTableChange={handleTableChange}
																			{...paginationTableProps}
																		/>
																	</div>
																</Col>
															</Row>
															<div className="float-sm-end">
																<PaginationListStandalone
																	{...paginationProps}
																/>
															</div>
														</React.Fragment>
													)}
												</ToolkitProvider>
											)}
										</PaginationProvider>
									}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>

			<ModalCadastroContato
        opened={modalCadastroOpened}
        setOpened={setModalCadastroOpened}
        refresh={handleRefresh}
        translate={translate}
      />
			<ModalEdicaoContato
        opened={modalEdicaoOpened}
        setOpened={setModalEdicaoOpened}
        refresh={handleRefresh}
        values={modalEdicao}
        permissoes={permissoes}
        remover={removerContato}
        translate={translate}
      />
			<ModalImportarContatos
        opened={modalImportarOpened}
        setOpened={setModalImportarOpened}
        refresh={handleRefresh}
        translate={translate}
      />

			{sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
		</React.Fragment>
	);
};

ContatosLista.propTypes = {
	t: PropTypes.func,
};

export default connect()(withRouter(withTranslation()(ContatosLista)));
