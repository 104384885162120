import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import { getConfiguracoes } from "../../../services/configuracoes"
import { getGruposDeHorarios } from "../../../services/grupos-de-horarios"
import { getPermissoesDeSetor } from "../../../services/permissoes-de-setor"
import { getSetores, postSetor } from "../../../services/setores"
import DefaultForm from "../../form/DefaultForm"

export default withTranslation()(function ModalCadastroSetor({ opened, setOpened, refresh, ...props }) {
    function translate (text) {
      return props.t(text)
    }
    const [checked, setChecked] = useState(false)
    const [permissoesPadroes, setPermissoesPadroes] = useState([])

    const [form, setForm] = useState({
        nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 8, md: 8, sm: 12 } },
        setor_id_transbordo: { name: "setor_id_transbordo", type: "select", options: [], label: translate("Setor de transbordo"), placeholder: translate("Nenhum setor selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
        grupo_horario_id: { name: "grupo_horario_id", type: "select", options: [], label: translate("Grupo de Horário"), placeholder: translate("Nenhum grupo selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
        grupo_horario_id_excecao: { name: "grupo_horario_id_excecao", type: "select", options: [], label: translate("Grupo de Excessão de Horário"), placeholder: translate("Nenhum grupo selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
        descricao: { name: "descricao", type: "textarea", label: translate("Descrição"), placeholder: translate("Descreva o setor"), validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        configuracoes: {
          label: translate("Configurações"), fields: {
              distribuicao_atendimentos: { name: "configuracoes['distribuicao_atendimentos']", type: "select", options: [{ label: translate("Todos agentes"), value: translate("todos") }, { label: translate("Com menos atendimentos"), value: "menos_atendimentos" }, { label: translate("Sequencial"), value: "sequencial" }], label: translate("Distribuição de atendimentos"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
              historico_conversa: { name: "configuracoes['historico_conversa']", type: "select", options: [{ label: translate("Geral"), value: "geral" }, { label: translate("Sessão atual"), value: "sessao_atual" }, { label: translate("Sessões da equipe"), value: "sessoes_equipe" }], label: translate("Histórico"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 } },
              tempo_inatividade_sessao: { name: "configuracoes['tempo_inatividade_sessao']", type: "number", label: translate("Limite de inatividade de sessão"), placeholder: translate("Valor em minutos"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 } },
              espera_transferencia_status: { name: "configuracoes['espera_transferencia_status']", type: "select", options: [{ label: translate("Não"), value: "0" }, { label: translate("Sim"), value: "1" }], label: translate("Limite de transferência"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 }, onChange: (e) => { alterouLimiteTransferencia(e.target.value) } },
              espera_transferencia_setor_tipo: { name: "configuracoes['espera_transferencia_setor_tipo']", type: "select", options: [{ label: translate("Segundos"), value: "segundos" }, { label: translate("Minutos"), value: "minutos" }, { label: translate("Horas"), value: "horas" }], label: translate("Tipo de tempo"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 4, md: 4, sm: 12 }, display: false },
              espera_transferencia_tempo: { name: "configuracoes['espera_transferencia_tempo']", type: "number", label: translate("Tempo"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 }, display: false },
              mensagem_nenhum_agente: { name: "configuracoes['mensagem_nenhum_agente']", type: "textarea", label: translate("Mensagem quando não houver agentes"), placeholder: translate("Digite alguma mensagem"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
              mensagem_encerramento_sessao: { name: "configuracoes['mensagem_encerramento_sessao']", type: "textarea", label: translate("Mensagem de encerramento"), placeholder: translate("Digite alguma mensagem"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
              permissao_de_setor_total: { label: translate("Permissões"), component: <div>
                <br/>
                  <FormGroup check>
                    <Input id="permissao_de_setor_total" name="permissao_de_setor_total" type="checkbox" onChange={(e) => setChecked(e.target.checked)} />
                    <Label check>{translate("Permissão Total")}</Label>
                  </FormGroup>
                <br/>
              </div>},
              permissoes: { fields: {} }
          },
        }
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        start()
    }, [])

    const start = async () => {
        try {
            let newJson = { ...form }
            let configuracoes = await getConfiguracoes("setor")
            let permissoes = await getPermissoesDeSetor()
            setPermissoesPadroes(permissoes)

            console.log("START", configuracoes)

            let subcategorias = []
            for (let permissao of permissoes) {
                let encontrou = false
                for (let sub of subcategorias) {
                    if (sub.id == permissao.permissao_de_setor_subcategoria_id) {
                        encontrou = true
                        break
                    }
                }
                if (!encontrou) subcategorias.push({ id: permissao.permissao_de_setor_subcategoria_id, nome: permissao.permissao_de_setor_subcategoria_nome, permissoes: [] })
            }

            // Colocar as permissões nas subcategorias
            for (let permissao of permissoes) {
                for (let sub of subcategorias) {
                    if (sub.id == permissao.permissao_de_setor_subcategoria_id) {
                        sub.permissoes.push(permissao)
                        break
                    }
                }
            }

            let size = { lg: 4, md: 4, sm: 4 }
            if (subcategorias.length == 1) size = { lg: 12, md: 12, sm: 12 }
            else if (subcategorias.length >= 2) size = { lg: 6, md: 6, sm: 12 }
            //  else if (subcategorias.length >= 3) size = { lg: 4, md: 4, sm: 12 }

            subcategorias.forEach(sub => {
                let options = []
                sub.permissoes.forEach(perm => { options.push({ label: perm.nome, value: perm.id, help: perm.descricao, checked: perm.padrao == 1 }) })
                newJson.configuracoes.fields.permissoes.fields[`permissao_de_setor_subcategoria[${sub.id}]`] = { name: `permissoes[${sub.id}]`, options: options, label: sub.nome, type: "checkbox-group", disabled: checked, size: size, direction: "column" }
            })

            let setores = await getSetores()

            let options = [{ label: translate("Nenhum"), value: null }]
            for (let s of setores) {
                options.push({ label: s.nome, value: s.id })
            }

            if (options.length == 0) options = [{ label: translate("Nenhum setor"), value: null }]

            let gruposDeHorarios = await getGruposDeHorarios()

            let optionsGruposDeHorarios = [{ label: translate("Nenhum"), value: null }]
            for (let g of gruposDeHorarios) {
                    optionsGruposDeHorarios.push({ label: g.nome, value: g.id })
            }

            if (optionsGruposDeHorarios.length === 0) optionsGruposDeHorarios = [{ label: translate("Nenhum setor"), value: null }]

            setForm({
                ...form,
                configuracoes: {
                    ...form.configuracoes,
                    fields: {
                        ...form.configuracoes.fields,
                        permissoes: newJson.configuracoes.fields.permissoes,
                        distribuicao_atendimentos: { ...form.configuracoes.fields.distribuicao_atendimentos, value: configuracoes?.distribuicao_atendimentos },
                        historico_conversa: { ...form.configuracoes.fields.historico_conversa, value: configuracoes?.historico_conversa },
                        tempo_inatividade_sessao: { ...form.configuracoes.fields.tempo_inatividade_sessao, value: configuracoes?.tempo_inatividade_sessao },
                        espera_transferencia_status: { ...form.configuracoes.fields.espera_transferencia_status, value: configuracoes?.espera_transferencia_status },
                        espera_transferencia_setor_tipo: { ...form.configuracoes.fields.espera_transferencia_setor_tipo, value: configuracoes?.espera_transferencia_setor_tipo },
                        espera_transferencia_tempo: { ...form.configuracoes.fields.espera_transferencia_tempo, value: configuracoes?.espera_transferencia_tempo },
                        mensagem_nenhum_agente: { ...form.configuracoes.fields.mensagem_nenhum_agente, value: configuracoes?.mensagem_nenhum_agente },
                        mensagem_encerramento_sessao: { ...form.configuracoes.fields.mensagem_encerramento_sessao, value: configuracoes?.mensagem_encerramento_sessao },
                    }
                },
                setor_id_transbordo: { ...form.setor_id_transbordo, options: options, disabled: options.length === 0 },
                grupo_horario_id: { ...form.grupo_horario_id, options: optionsGruposDeHorarios, disabled: optionsGruposDeHorarios.length === 0 },
                grupo_horario_id_excecao: { ...form.grupo_horario_id_excecao, options: optionsGruposDeHorarios, disabled: optionsGruposDeHorarios.length === 0 }
            })
        } catch (error) {
            console.log("ERRORRRR", error)
        }
    }

    const alterouLimiteTransferencia = (val) => {
        let display = val == "1"
        console.log("DISPLAY", display, form, form.configuracoes.fields)
        setForm({
            ...form,
            configuracoes: {
                ...form.configuracoes,
                fields: {
                    ...form.configuracoes.fields,
                    espera_transferencia_setor_tipo: { ...form.configuracoes.fields.espera_transferencia_setor_tipo, display: display },
                    espera_transferencia_tempo: { ...form.configuracoes.fields.espera_transferencia_tempo, display: display, validade: display ? { required: { value: true } } : {} }
                }
            }
        })
    }

    useEffect(() => {
        console.log("MUDOUUU", form)
    }, [form])

    const handleSubmit = async (event, errors, values) => {
        try {
            console.log("{ handleSubmit }", errors, values)
            if (errors.length > 0) return

            let data = values

            let permissoes = []
            Object.keys(data.permissoes).forEach(perm => {
                permissoes = permissoes.concat(data.permissoes[perm])
            })

            data.permissao_de_setor_total = checked
            data.permissoes = {}
            permissoesPadroes.forEach(perm => {
                data.permissoes[perm.id] = permissoes.indexOf(perm.id) >= 0
            })

            const setor = await postSetor(data)

            console.log("USUARIO CADASTRADO", setor)

            if (refresh) refresh(setor) // Passar setor para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Setor")} ${setor.nome} ${translate("cadastrado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalCadastroSetor ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Cadastrar Setor")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
