import React, { useContext, useEffect, useState } from 'react';
import { Handle } from 'react-flow-renderer';
import { Button } from "reactstrap"

import FluxoContext from "../../../../../../contexts/FluxoContext"

import operadoresCondicao from "../../../../../../values/operadoresCondicao.json"

import "./styles.scss"

export default function NodePersonalizado({ id, type, label, data, styles = {}, handles, buttons, ...rest }) {
  const { elements, setElements, setModalEdicao } = useContext(FluxoContext)

  /*const customNodeStyles = {
    background: '#ff0000',
    color: '#000',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: "3px",
    fontSize: "12px"
  };*/

  const remover = () => {
    setElements(elements.filter(el => el.id != id && el.source != id && el.target != id))
  }

  const editar = () => {
    setModalEdicao({
      tipo: type,
      id: id,
      data: data
    })
  }

  useEffect(() => {
    console.log("REST", rest)
  }, [])

  return (
    <>
      <div className={`custom-node ${type}`} style={{ minWidth: `${(handles * 30) + 20}px` }}>
        <Handle type="target" position="top" style={{ borderRadius: 0 }} />
        {label}
        <div className="handles-wrapper">
          {(() => {
            let array = []
            for (let i = 0; i < handles; i++) array.push(i)
            return array
          })().map((h, index) => (
            <Handle
              type="source"
              position="bottom"
              id={`handle-${index}-${id}`}
              key={index}
            //style={{ left: `${100 - ((100 / (handles + 1) * (index + 1)))}%`, borderRadius: 0 }}
            >
              {type == "menu" && <div className="handle-conteudo">
                <p className="entradas">{(data.etapa ? data.etapa.opcoes[index] : data.opcoes[index]).entradas}</p>
                <p className="descricao">{(data.etapa ? data.etapa.opcoes[index] : data.opcoes[index]).descricao}</p>
              </div>}

              {type == "condicao" && <div className="handle-conteudo">
                <p className="entradas">{(data.etapa ? data.etapa.condicoes[index] : operadoresCondicao[data.condicoes[index].operador])}</p>
                <p className="descricao">{(data.etapa ? data.etapa.condicoes[index] : data.condicoes[index]).valor}</p>
              </div>}
            </Handle>
          ))}

          {["condicao"].includes(type) &&
            <Handle
              type="source"
              position="bottom"
              id={`handle-off-${id}`}
              style={{ borderColor: "red", backgroundColor: "white" }}
              className="handle-off"
            />
          }
        </div>

        <div className="node-controls">
          {buttons?.map((button, i) => (
            <Button key={i} size="sm" {...button}>{button.text}</Button>
          ))}
          <Button color="warning" size="sm" onClick={editar}><i className="uil uil-edit" /></Button>
          <Button color="danger" size="sm" onClick={remover}><i className="uil uil-trash" /></Button>
        </div>
      </div>
    </>
  )
}
