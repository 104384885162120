import React, { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

import DefaultForm from "../../form/DefaultForm"

import SweetAlert from "react-bootstrap-sweetalert"

import { getUsuarios } from "../../../services/usuarios"
import { postUsuarioSetor, deleteUsuarioSetor } from "../../../services/setores"

import "./style.scss"

export default withTranslation()(function ModalUsuariosSetor({ opened, setOpened, setor, ...props }) {
    function translate (text) {
      return props.t(text)
    }
    
    const [usuarios, setUsuarios] = useState([])

    useEffect(() => {
        buscarUsuarios()
    }, [])

    useEffect(async () => {
        if (opened && setor) {
            if (usuarios.length == 0)
                await buscarUsuarios()

            let newUsuarios = [...usuarios]

            for (let usuario of newUsuarios) {
                usuario.dentro = false
                for (let user of setor.usuarios) {
                    if (usuario.id == user.id) {
                        usuario.dentro = true
                        break
                    }
                }
            }

            setUsuarios(newUsuarios)
        }
    }, [opened])

    const adicionarUsuario = async (usuario_id) => {
        try {
            await postUsuarioSetor({
                setor_id: setor.id,
                usuario_id
            })
        } catch (error) {
            let newUsuarios = [...usuarios]

            for (let usuario of newUsuarios) {
                if (usuario.id == usuario_id) {
                    usuario.dentro = false
                    break
                }
            }

            setUsuarios(newUsuarios)
        }
    }

    const removerUsuario = async (usuario_id) => {
        try {
            await deleteUsuarioSetor(setor.id, usuario_id)
        } catch (error) {
            let newUsuarios = [...usuarios]

            for (let usuario of newUsuarios) {
                if (usuario.id == usuario_id) {
                    usuario.dentro = true
                    break
                }
            }

            setUsuarios(newUsuarios)
        }
    }

    const buscarUsuarios = async () => {
        const resultado = await getUsuarios()
        setUsuarios(resultado)
        return resultado
    }

    const changeUsuario = async (usuario, dentro) => {
        let newState = [...usuarios]

        for (let user of usuarios) {
            if (user.id == usuario.id) {
                user.dentro = dentro

                if (dentro) adicionarUsuario(usuario.id)
                else removerUsuario(usuario.id)

                break
            }
        }

        setUsuarios(newState)
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Usuários de Setor")}
            </ModalHeader>
            {setor &&
                <ModalBody>
                    <div className="usuarios-setor-wrapper">
                        <div className="usuarios-setor-wrapper-split">
                            <h6>{translate("Fora do setor")} ({usuarios.filter(usuario => !usuario.dentro).length})</h6>

                            {usuarios.filter(usuario => !usuario.dentro).length == 0 && <i>{translate("Nenhum usuário")}</i>}
                            {usuarios.filter(usuario => !usuario.dentro).map(usuario => (
                                <div key={usuario.id} className="usuarios-setor-wrapper-usuario" onClick={() => { changeUsuario(usuario, true) }}>
                                    <div>
                                        <img
                                            className="avatar-xs rounded-circle me-2"
                                            src={usuario.foto}
                                            alt=""
                                        />
                                        <span>{usuario.nome}</span>
                                    </div>
                                    <div>
                                        <i className="uil uil-arrow-right" />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="usuarios-setor-wrapper-split">
                            <h6>{translate("Dentro do setor")} ({usuarios.filter(usuario => usuario.dentro).length})</h6>

                            {usuarios.filter(usuario => usuario.dentro).length == 0 && <i>{translate("Nenhum usuário")}</i>}
                            {usuarios.filter(usuario => usuario.dentro).map(usuario => (
                                <div key={usuario.id} className="usuarios-setor-wrapper-usuario" onClick={() => { changeUsuario(usuario, false) }}>
                                    <div>
                                        <img
                                            className="avatar-xs rounded-circle me-2"
                                            src={usuario.foto}
                                            alt=""
                                        />
                                        <span>{usuario.nome}</span>
                                    </div>
                                    <div>
                                        <i className="uil uil-arrow-left" />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </ModalBody>
            }
        </Modal>
    )
})