import React from "react"
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"

const AtendimentosListaColunas = (permissoes, translate, transferirAtendimento, encerrarAtendimento) => [
  {
    text: translate("Protocolo"),
    sort: true,
    formatter: (cellContent, atendimento) => (
      <>
        {atendimento.protocolo || <i>Sem protocolo</i>}
      </>
    ),
  },
  {
    text: "Nome",
    sort: true,
    formatter: (cellContent, atendimento) => atendimento?.campos_iniciais.filter(ci => ci.campo_contato == "nome").length > 0 ?
      <>{atendimento?.campos_iniciais.filter(ci => ci.campo_contato == "nome")[0].valor}</>
      :
      <i>{translate("Não informado")}</i>
  },
  {
    text: translate("Situação"),
    dataField: "status_string",
    sort: true
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, atendimento) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href={`/atendimentos/${atendimento.id}`}>{translate("Visualizar")}</DropdownItem>
              <DropdownItem href="#" onClick={() => { transferirAtendimento(atendimento) }}>Transferir</DropdownItem>
              <DropdownItem href="#" onClick={() => { encerrarAtendimento(atendimento) }}>Encerrar</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    ),
  },
]

export default AtendimentosListaColunas
