import React from "react"
import { Container } from "reactstrap"
import ResumoChats from "../../components/ResumoChats"

const Dashboard = () => {

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <ResumoChats />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard