import { isEmpty } from "lodash"
import React, { useContext, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import PermissaoContext from "../../../contexts/PermissaoContext"
import MensagensPreDefinidasListaColunas from "../../../pages/MensagensPreDefinidas/MensagensPreDefinidasLista/MensagensPreDefinidasColunas"
import { deleteMensagemPreDefinida, getMensagensPreDefinidasPorSetor } from "../../../services/mensagens-pre-definidas"
import Carregando from "../../Carregando"
import ModalCadastroMensagemPreDefinida from "../mensagens-pre-definidas/ModalCadastroMensagemPreDefinida"
import ModalEdicaoMensagemPreDefinida from "../mensagens-pre-definidas/ModalEdicaoMensagemPreDefinida"








export default withTranslation()(function ModalMensagensPreDefinidas({ opened, setOpened, refresh, setor_id, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const { users, onGetUsers } = props
    const [list, setList] = useState([])
    const [listView, setListView] = useState([])
    const [pageOptions, setPageOptions] = useState({
      sizePerPage: 10,
      totalSize: list.length,
      custom: true
    })
    const { SearchBar } = Search

    const [page, setPage] = useState(1)
    const [searchText, setSearchText] = useState("")

    const [modalCadastroOpened, setModalCadastroOpened] = useState(false)
    const [modalEdicaoOpened, setModalEdicaoOpened] = useState(null)

    const [modalUsuariosMensagensPreDefinidasOpened, setModalUsuariosMensagensPreDefinidasOpened] = useState(null)

    const [sweetAlert, setSweetAlert] = useState({
      show: false,
      title: "",
      text: "",
      confirmBtnBsStyle: "success",
      onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    const { permissoes, isLoadingPermissoes } = useContext(PermissaoContext)

    useEffect(() => {
      buscarMensagensPreDefinidas()
    }, [setor_id])

    useEffect(() => {
      if (!isEmpty(users)) {
        setList(users)
      }
    }, [users])

    const buscarMensagensPreDefinidas = async () => {
      try {
        const mensagens = await getMensagensPreDefinidasPorSetor(setor_id)

        setList(mensagens)
        setListView(mensagens)
      } catch (error) {
      }
    }

    const removerMensagemPreDefinida = async (mensagemPreDefinida) => {
      try {
        console.log("removerMensagemPreDefinida", mensagemPreDefinida)
        setSweetAlert({
          ...sweetAlert,
          show: true,
          title: translate("Atenção!"),
          text: `${translate("Tem certeza que deseja excluir a Mensagem Pré-Definida")} ${mensagemPreDefinida.descricao}?`,
          showCancel: true,
          cancelBtnBsText: translate("Não"),
          cancelBtnBsStyle: "danger",
          warning: true,
          onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
          onConfirm: async () => {
            setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: translate("Estamos removendo o mensagemPreDefinida") })
            try {
              let retorno = await deleteMensagemPreDefinida(mensagemPreDefinida.id)
              setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: translate("Sucesso"), text: retorno || "Setor removido com sucesso!", onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
              handleRefresh(mensagemPreDefinida, "remove")
            } catch (error) {
              setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: translate("Ooops"), text: error.message || translate("Ocorreu um erro."), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
            }
          }
        })
      } catch (error) {
        console.error(error)
      }
    }

    const handleRefresh = async (mensagemPreDefinida, action = "add") => {
      console.log("{ handleRefresh }", action, list, mensagemPreDefinida)
      if (action == "add") {
        let newArray = [...list, mensagemPreDefinida]
        setList(newArray)
        setPageOptions({ ...pageOptions, totalSize: list.length + 1 })
        handleTableChange(null, { page, searchText }, newArray)
      } else if (action == "edit") {
        let newArray = [...list]
        for (let i = 0; i < newArray.length; i++) {
          const el = newArray[i]
          if (el.id == mensagemPreDefinida.id) {
            newArray[i] = mensagemPreDefinida
            break
          }
        }
        handleTableChange(null, { page, searchText }, newArray)
      } else if (action == "remove") {
        let newArray = list.filter(user => user.id != mensagemPreDefinida.id)
        setList(newArray)
        setPageOptions({ ...pageOptions, totalSize: newArray.length - 1 })
        handleTableChange(null, { page, searchText }, newArray)
      }
    }

    // eslint-disable-next-line no-unused-vars
    const handleTableChange = (type, { page, searchText }, data = null) => {
      const dados = data || list
      console.log("{ handleTableChange }", page, searchText, dados)
      setPage(page)
      setSearchText(searchText)

      let inicio = pageOptions.sizePerPage * page - pageOptions.sizePerPage
      let fim = pageOptions.sizePerPage * page
      if (fim > dados.length) fim = dados.length

      setListView(
        dados.slice(inicio, fim).filter(user =>
          Object.keys(user).some(
            key =>
              typeof user[key] === "string" &&
              user[key].toLowerCase().includes(searchText.toLowerCase())
          )
        )
      )
    }

    var selectRowProp = {
      mode: "checkbox",
      clickToSelect: false,
    };

    return (
        <Modal size="xl" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Mensagens Pré Definidas")}
            </ModalHeader>
            <ModalBody>
                    <Container fluid>
                    <Row>
                        <Col lg="12">
                        <Card>
                            <CardBody>
                            {isLoadingPermissoes ? <Carregando /> :
                                <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                >
                                {({ paginationProps, paginationTableProps }) => (
                                    <ToolkitProvider
                                    keyField="id"
                                    data={listView || []}
                                    columns={MensagensPreDefinidasListaColunas(removerMensagemPreDefinida, setModalEdicaoOpened, permissoes, setModalUsuariosMensagensPreDefinidasOpened, translate)}
                                    bootstrap4
                                    search
                                    >
                                    {toolkitProps => (
                                        <React.Fragment>
                                        <Row className="row mb-2">
                                            <Col md={6}>
                                            <div className="mb-3">
                                                <Link to="#" className="btn btn-success waves-effect waves-light" onClick={() => setModalCadastroOpened(true)}><i className="mdi mdi-plus me-2"></i>{translate("Novo")}</Link>
                                            </div>
                                            </Col>

                                            <Col md={6}>
                                            <div className="form-inline float-md-end mb-3">
                                                <div className="search-box ms-2">
                                                <div className="position-relative">
                                                    <SearchBar {...toolkitProps.searchProps} />
                                                    <i className="mdi mdi-magnify search-icon"></i>
                                                </div>
                                                </div>
                                            </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl="12">
                                            <div className="table-responsive mb-4">
                                                <BootstrapTable
                                                selectRow={selectRowProp}
                                                responsive
                                                remote
                                                bordered={false}
                                                striped={false}
                                                classes={"table table-centered table-nowrap mb-0"}
                                                {...toolkitProps.baseProps}
                                                onTableChange={handleTableChange}
                                                {...paginationTableProps}
                                                />
                                            </div>
                                            </Col>
                                        </Row>
                                        <div className="float-sm-end">
                                            <PaginationListStandalone
                                            {...paginationProps}
                                            />
                                        </div>
                                        </React.Fragment>
                                    )}
                                    </ToolkitProvider>
                                )}
                                </PaginationProvider>
                            }
                            </CardBody>
                        </Card>
                        </Col>
                    </Row>
                    </Container>

                <ModalCadastroMensagemPreDefinida opened={modalCadastroOpened} setOpened={setModalCadastroOpened} refresh={handleRefresh} setor_id={setor_id} />
                <ModalEdicaoMensagemPreDefinida opened={modalEdicaoOpened} setOpened={setModalEdicaoOpened} refresh={handleRefresh} values={modalEdicaoOpened} setor_id={setor_id} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
