import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { getGrupos } from "../../../services/grupos"
import { postUsuario } from "../../../services/usuarios"
import DefaultForm from "../../form/DefaultForm"

export default withTranslation()(function ModalCadastroUsuario({ opened, setOpened, refresh, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const [form, setForm] = useState({
      nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
      codinome: { name: "codinome", type: "text", label: translate("Codinome"), placeholder: translate("Codinome"), help: translate("Atalho de acesso e identificação do usuário"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
      email: { name: "email", type: "email", label: translate("E-mail"), placeholder: translate("E-mail"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
      grupos: { name: "grupos", type: "multiselect", label: translate("Grupos"), placeholder: translate("Carregando grupos")+"...", isDisabled: false, isMulti: true, options: [], errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
      acesso_como_atendente: { label: translate("Acesso como Atendente"), fields: {} },
      hora_inicio: { name: "hora_inicio", type: "time", label: translate("Horário Inicial"), placeholder: translate("Horário Inicial"), errorMessage: translate("Campo obrigatório"), size: { lg: 6, md: 6, sm: 12 } },
      hora_fim: { name: "hora_fim", type: "time", label: translate("Horário Final"), placeholder: translate("Horário Final"), errorMessage: translate("Campo obrigatório"), size: { lg: 6, md: 6, sm: 12 } }
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        buscarGrupos()
    }, [])

    const buscarGrupos = async () => {
        try {
            const grupos = await getGrupos()

            let options = []
            grupos.forEach(grupo => {
                options.push({ label: grupo.nome, value: grupo.id })
            })

            setForm({ ...form, grupos: { ...form.grupos, options: options, placeholder: translate("Selecione os grupos que desejar") } })
        } catch (error) {
            setForm({ ...form, grupos: { ...form.grupos, placeholder: translate("Erro na busca de grupos"), isDisabled: true } })
        }
    }

    const handleSubmit = async (event, errors, values) => {
        try {
            console.log("{ handleSubmit }", errors, values)
            if (errors.length > 0) return

            if (
              ((values.hora_inicio !== "") && (values.hora_fim === "")) ||
              ((values.hora_inicio === "") && (values.hora_fim !== ""))
            ) {
              setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"), text: "Acesso como atendente deve ser preenchido", success: false, error: true })
              return
            }

            const date = new Date();
            const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];

            if (new Date(`${month+1}/${day}/${year} ${values.hora_inicio}`) >= new Date(`${month+1}/${day}/${year} ${values.hora_fim}`)) {
              setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"), text: "O horário de inicil deve ser menor que horário final.", success: false, error: true })
              return
            }

            const usuario = await postUsuario(values)

            console.log("USUARIO CADASTRADO", usuario)

            if (refresh) refresh(usuario) // Passar usuário para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Usuario")} ${usuario.nome} ${translate("cadastrado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalEdicaoUsuario ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Cadastrar Usuário")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
