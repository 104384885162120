import api from "./api"

const getConfiguracoesDeAplicacao = async () => {
  try {
    const { data } = await api.get("/aplicao/configuracoes")
    return data
  } catch (error) {
    throw error
  }
}

const putConfiguracoesDeAplicacao = async (id, data) => {
  try {
    const response = await api.put(`/aplicao/configuracoes/${id}`, data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getMensagensAutomaticasAplicacao = async () => {
  try {
    const { data } = await api.get("/aplicao/mesagens-automaticas")
    return data
  } catch (error) {
    throw error
  }
}

const putMensagensAutomaticasAplicacao = async (id, data) => {
  try {
    const response = await api.put(`/aplicao/mesagens-automaticas/${id}`, data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getKeepAliveAplicacao = async () => {
  try {
    const { data } = await api.get("/aplicao/keep-alive")
    return data
  } catch (error) {
    throw error
  }
}

const putKeepAliveAplicacao = async (id, data) => {
  try {
    const response = await api.put(`/aplicao/keep-alive/${id}`, data)
    return response.data
  } catch (error) {
    throw error
  }
}

const getEstiloAplicacao = async () => {
  try {
    const { data } = await api.get("/aplicao/estilo")
    return data
  } catch (error) {
    throw error
  }
}

const putEstiloAplicacao = async (id, data) => {
  try {
    const response = await api.put(`/aplicao/estilo/${id}`, data)
    return response.data
  } catch (error) {
    throw error
  }
}

export { getConfiguracoesDeAplicacao, getMensagensAutomaticasAplicacao, getKeepAliveAplicacao, getEstiloAplicacao, putConfiguracoesDeAplicacao, putMensagensAutomaticasAplicacao, putKeepAliveAplicacao, putEstiloAplicacao }
