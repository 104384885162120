import axios from "axios";
import { logout } from "./authService"
require('dotenv').config()

console.log("URL", process.env.REACT_APP_API_URL)

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL || "http://165.227.221.240:3030",
    params: {
        public_key: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjaGF2ZV9wdWJsaWNhIjoiNTY2ZjEyZWQtOTNjOS00ZjNiLWJmMTAtZGU0M2IyMTU0MDg1In0.2lsUm5RL9lANvHSxwQ8R-GBr0MLPKHtjDRFZABtos94"
    }
})

api.interceptors.request.use(config => {
    const access_token = localStorage.getItem("access_token")
    if (access_token)
        config.headers["x-access-token"] = access_token

    return config
})

api.interceptors.response.use(resp => {
    return resp
}, (error) => {
    if (error.response && error.response.status == 401 && error.response?.data && error.response?.data.logout)
        logout()
    throw error.response
})

export default api;
