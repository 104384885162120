import React, { useCallback, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { withTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { getCamposAdicionais } from "../../../../services/campos-adicionais"
import { postContato } from "../../../../services/contatos"
import DefaultForm from "../../../form/DefaultForm"

export default withTranslation()(function ModalCadastroContato({ opened, setOpened, refresh, translate }) {
  const [form, setForm] = useState({
    nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
  })

  const [camposAdicionais, setCamposAdicionais] = useState([])
  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

	const buscarCamposAdicionais = useCallback(async () => {
    try {
      const camposAdicionais = await getCamposAdicionais()
      setCamposAdicionais(camposAdicionais)

      setForm((form) => {
        for (let campo of camposAdicionais) {
          form = {
            ...form,
            [`campo_adicional_${campo.id}`]: { name: `campo_adicional_${campo.id}`, type: "text", label: campo.nome, placeholder: campo.nome, errorMessage: translate("Campo obrigatório"), validate: campo.obrigatorio ? { required: { value: true } } : {}, size: { lg: 6, md: 12, sm: 12 } }
          }
        }
        return form
      })
    } catch (error) {
      setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Ooops!"), text: error.response?.data ? error.response?.data : translate("Ocorreu um erro"), success: false, error: true }));
    }
	}, [translate]);

  const handleSubmit = useCallback(async (event, errors, data) => {
    try {
      if (errors.length > 0) return

      const newData = {
        nome: data.nome,
        campos_adicionais: []
      }

      for (var campo of camposAdicionais) {
        if(data.hasOwnProperty(`campo_adicional_${campo.id}`)) {
          newData.campos_adicionais.push({ id: campo.id, valor: data[`campo_adicional_${campo.id}`] })
        }
      }
      const contato = await postContato(newData)
      if (refresh) refresh(contato)
      setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Contato")} ${contato.nome} ${translate("cadastrado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } }));
    } catch (error) {
      setSweetAlert((sweetAlert) => ({ ...sweetAlert, show: true, title: translate("Ooops!"), text: error.response?.data ? error.response?.data : translate("Ocorreu um erro"), success: false, error: true }));
    }
  }, [refresh, translate, setOpened, camposAdicionais]);

	useEffect(() => {
		buscarCamposAdicionais();
	}, [buscarCamposAdicionais]);

  return (
    <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
      <ModalHeader toggle={() => { setOpened(!opened) }}>
        {translate("Cadastrar Contato")}
      </ModalHeader>
      <ModalBody>
        <DefaultForm fields={form} handleSubmit={handleSubmit} />

        {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
      </ModalBody>
    </Modal>
  )
});
