import React, { useState, createContext, useEffect } from 'react'
import { useParams, useHistory } from "react-router-dom"

import { v4 as uuidv4 } from 'uuid'

import { getFluxo, postFluxo, postRascunho, postPublicar } from "../services/fluxos"

const FluxoContext = createContext({})

export const FluxoProvider = ({ children }) => {
  const history = useHistory()

  let params = useParams()

  const [fluxo, setFluxo] = useState(null) // Objeto do fluxo aberto
  const [fluxoId, setFluxoId] = useState(null)
  const [nomeFluxo, setNomeFluxo] = useState("Fluxo em branco")
  const [elements, setElements2] = useState([])
  const [historico, setHistorico] = useState([[]])
  const [prevOK, setPrevOK] = useState(false) // Diz se o botão de voltar versão
  const [nextOK, setNextOK] = useState(false) // Diz se o botão de avançar versão
  const [saveOK, setSaveOK] = useState(false) // Diz se o botão de salvar está liberado ou não
  const [publicarOK, setPublicarOK] = useState(false) // Diz se o botão de publicar está liberado ou não
  const [loadingSave, setLoadingSave] = useState(false) // Tela carregando
  const [modalEdicaoIsOpened, setModalEdicaoIsOpened] = useState(false)
  const [modalEdicao, setModalEdicao] = useState(null)
  const [modalEdicaoMenuIsOpened, setModalEdicaoMenuIsOpened] = useState(false)
  const [modalEdicaoMenu, setModalEdicaoMenu] = useState(null)
  const [modalEdicaoCondicoesIsOpened, setModalEdicaoCondicoesIsOpened] = useState(false)
  const [modalEdicaoCondicoes, setModalEdicaoCondicoes] = useState(null)

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true) // Tela carregando

  const [historicoAtual, setHistoricoAtual] = useState(null)

  const [infos, setInfos] = useState({}) // Salvar dados capturados durante os processos (exemplo: salvar grupos de horários)

  useEffect(() => {
    if (params.id) {
      setFluxoId(params.id)
      carregarFluxo(null, params.id)
    } else {
      cadastrarFluxo()
    }
  }, [])

  useEffect(() => {
    if (!loading)
      fluxoAlterado()
  }, [elements])

  useEffect(() => {
    if (modalEdicao) {
      setModalEdicaoIsOpened(true)
    }
  }, [modalEdicao])

  useEffect(() => {
    console.log("useEffect", modalEdicaoMenu)
    if (modalEdicaoMenu) {
      setModalEdicaoMenuIsOpened(true)
    }
  }, [modalEdicaoMenu])

  useEffect(() => {
    console.log("useEffect", modalEdicaoCondicoes)
    if (modalEdicaoCondicoes) {
      setModalEdicaoCondicoesIsOpened(true)
    }
  }, [modalEdicaoCondicoes])

  const fluxoAlterado = () => {
    console.log("fluxoAlterado", historicoAtual)
    if (historicoAtual == 0 && elements.length == 0)
      return setSaveOK(false)
    setSaveOK(true)
    setPublicarOK(false)
  }

  const cadastrarFluxo = async () => {
    try {
      const posicao_inicial = { x: 500, y: 100 }
      setElements2([{
        id: "inicial",
        type: "inicial",
        position: posicao_inicial
      }])

      const resultado = await postFluxo({
        inicial_fluxo_x: posicao_inicial.x,
        inicial_fluxo_y: posicao_inicial.y
      })

      history.push(`/fluxos/editor/${resultado.id}`)
    } catch (error) {
      alert("Ocorreu um erro no cadastro do fluxo")
    }
  }

  const carregarFluxo = async (flx = null, id = null) => {
    try {
      console.log("carregarFluxo", flx, id)

      if (!flx) flx = await getFluxo(id)

      setNomeFluxo(flx.nome)
      setFluxoId(flx.id)

      if (flx.fluxo_rascunho_id)
        setPublicarOK(true)

      let elems = []

      elems.push({
        id: "inicial",
        type: "inicial",
        position: { x: flx?.fluxo?.inicial_fluxo_x, y: flx?.fluxo?.inicial_fluxo_y }
      })

      if (flx?.fluxo?.elementos) {
        console.log("FLUXOOOO", flx)
        if (flx?.fluxo?.fluxo_etapa_inicial_uuid) {
          elems.push({
            id: `reactflow__edge-inicialhandle-inicial-${flx.fluxo.fluxo_etapa_inicial_uuid}`,
            source: "inicial",
            sourceHandle: "handle-inicial",
            target: flx.fluxo.fluxo_etapa_inicial_uuid,
            targetHandle: null
          })
        }

        for (let etapa of flx?.fluxo?.elementos) {
          if (etapa.tipo == "menu") {
            let opcoes = etapa.dados.opcoes || []
            for (let i = 0; i < opcoes.length; i++) {
              let opcao = opcoes[i]
              console.log(opcao)
              console.log("OLHA AQUI", opcao.proxima_fluxo_etapa_uuid)
              if (opcao.proxima_fluxo_etapa_uuid) {
                console.log({
                  id: `reactflow__edge-${etapa.uuid}handle-${i}-${opcao.proxima_fluxo_etapa_uuid}`,
                  source: etapa.uuid,
                  sourceHandle: `handle-${i}`,
                  target: opcao.proxima_fluxo_etapa_uuid,
                  targetHandle: null
                })
                elems.push({
                  id: `reactflow__edge-${etapa.uuid}handle-${i}-${opcao.proxima_fluxo_etapa_uuid}`,
                  source: etapa.uuid,
                  sourceHandle: `handle-${i}`,
                  target: opcao.proxima_fluxo_etapa_uuid,
                  targetHandle: null
                })
              }
            }
          } else if (etapa.tipo == "condicao") {
            let condicoes = etapa.dados.condicoes || []
            if (etapa.dados.senao_fluxo_etapa_uuid) {
              elems.push({
                id: `reactflow__edge-${etapa.uuid}handle-off-${etapa.dados.senao_fluxo_etapa_uuid}`,
                source: etapa.uuid,
                sourceHandle: `handle-off-${etapa.uuid}`,
                target: etapa.dados.senao_fluxo_etapa_uuid,
                targetHandle: null
              })
            }

            for (let i = 0; i < condicoes.length; i++) {
              let condicao = condicoes[i]
              if (condicao.proxima_fluxo_etapa_uuid) {
                elems.push({
                  id: `reactflow__edge-${etapa.uuid}handle-${i}-${condicao.proxima_fluxo_etapa_uuid}`,
                  source: etapa.uuid,
                  sourceHandle: `handle-${i}-${etapa.uuid}`,
                  target: condicao.proxima_fluxo_etapa_uuid,
                  targetHandle: null
                })
              }
            }
          } else if (etapa.tipo == "horario") {
            ["fluxo_etapa_id_grupo_horario", "fluxo_etapa_id_grupo_horario_excecao", "proximo_fluxo_etapa_id"].map((key, index) => {
              elems.push({
                id: `reactflow__edge-${etapa.uuid}handle-0-${etapa.dados[key]}`,
                source: etapa.uuid,
                sourceHandle: `handle-${index}-${etapa.uuid}`,
                target: etapa.dados[key],
                targetHandle: null
              })
            })
          } else {
            if (etapa && etapa.proxima_fluxo_etapa_uuid) {
              elems.push({
                id: `reactflow__edge-${etapa.uuid}handle-0-${etapa.proxima_fluxo_etapa_uuid}`,
                source: etapa.uuid,
                sourceHandle: `handle-0-${etapa.uuid}`,
                target: etapa.proxima_fluxo_etapa_uuid,
                targetHandle: null
              })
            }
          }

          elems.push({
            id: etapa.uuid,
            type: etapa.tipo,
            position: { x: etapa.fluxo_x, y: etapa.fluxo_y },
            data: {
              ...etapa?.dados,
              nome: etapa.nome
            } // etapa
          })
        }
      }

      console.log("SET ELEMENTS", elems)

      setElements(elems)

      setFluxo(flx)

      setError(null)
      setLoading(() => false)
    } catch (error) {
      console.error(error)
      setError({
        titulo: "Erro no carregamento do fluxo",
        descricao: error?.message
      })
      setLoading(() => false)
    }
  }

  const toPrev = () => {
    console.log("toPrev", historicoAtual, historico)
    let novoHistoricoAtual = historicoAtual
    if (historicoAtual) {
      setElements2(historico[historicoAtual - 1])
      novoHistoricoAtual = historicoAtual - 1
      setHistoricoAtual(novoHistoricoAtual)
    }

    // Botão Prev
    if (!novoHistoricoAtual)
      setPrevOK(false)

    // Botão next
    if (novoHistoricoAtual < (historico.length - 1))
      setNextOK(true)
  }

  const toNext = () => {
    console.log("toNext", historicoAtual, historico)
    let novoHistoricoAtual = historicoAtual
    if (historicoAtual != null && historico.length > historicoAtual) {
      setElements2(historico[historicoAtual + 1])
      novoHistoricoAtual = historicoAtual + 1
      setHistoricoAtual(novoHistoricoAtual)
    }

    // Botão Prev
    if (novoHistoricoAtual)
      setPrevOK(true)

    // Botão next
    if (novoHistoricoAtual >= (historico.length - 1))
      setNextOK(false)
  }

  const setElements = (el) => {
    console.log("setElements", el)
    const novo = [...historico, el]
    setHistorico(novo)
    setElements2(el)
    setHistoricoAtual(novo.length - 1)
    setPrevOK(true)
    // console.log("HISTORICO ALTERADO", novo)
  }

  const createId = () => {
    return uuidv4()
  }

  // Procurar conexão
  const findConnection = (id, handle = null) => {
    console.log("findConnection", id, handle)

    let element = null

    for (let el of elements) {
      if (el.source == id && (handle === null || el.sourceHandle == `handle-${handle}`)) {
        element = el
        break
      }
    }

    return element
  }

  const publicar = async () => {
    try {
      setLoadingSave(true)

      await postPublicar(fluxoId)

      setLoadingSave(false)
      setPublicarOK(false)
    } catch (error) {
      console.error(error)
      alert("ERRO")
      setLoadingSave(false)
    }
  }

  const salvar = async () => {
    try {
      console.log("{ salvar }", nomeFluxo, elements)

      setLoadingSave(true)

      let elementos = []
      let ligacoes = []

      for (let element of elements.filter(e => e.type != "inicial")) {
        console.log(element)
        if (element.source || element.target) {
          console.log("ELEMENT", element)
          ligacoes.push({
            source: element.source,
            target: element.target,
            sourceHandle: element.sourceHandle
          })
        } else {
          elementos.push({
            nome: element.data.nome,
            uuid: element.id,
            tipo: element.type,
            fluxo_x: element.position.x,
            fluxo_y: element.position.y,
            dados: element.data,
            proxima_fluxo_etapa_uuid: null
          })
        }
      }

      // Ligações entre etapas
      console.log("LIGAÇÕES", ligacoes)
      let fluxo_etapa_inicial_uuid = null
      for (let ligacao of ligacoes) {
        if (ligacao.source == "inicial") {
          fluxo_etapa_inicial_uuid = ligacao.target
        } else {
          for (let elemento of elementos) {
            if (elemento.uuid == ligacao.source) {
              if (elemento.tipo == "menu") {
                console.log("ELEMENTO MENU", elemento)
                let index = ligacao.sourceHandle.split("handle-")[1].split("-")[0]
                console.log("INDEX", index)
                elemento.dados.opcoes[index].proxima_fluxo_etapa_uuid = ligacao.target
                break
              } else if (elemento.tipo == "condicao") {
                console.log("ELEMENTO CONDIÇÃO", elemento)
                let index = ligacao.sourceHandle.split("handle-")[1].split("-")[0]
                console.log("INDEX", index)
                if (index != "off")
                  elemento.dados.condicoes[index].proxima_fluxo_etapa_uuid = ligacao.target
                break
              } else {
                elemento.proxima_fluxo_etapa_uuid = ligacao.target
                break
              }
            }
          }
        }
      }

      let dados = {
        fluxo_id: fluxoId,
        elementos,
        inicial_fluxo_x: elements.filter(e => e.type == "inicial")[0].position.x,
        inicial_fluxo_y: elements.filter(e => e.type == "inicial")[0].position.y,
        fluxo_etapa_inicial_uuid
      }

      console.log("DADOS", dados)

      await postRascunho(dados)

      setLoadingSave(false)
      setSaveOK(false)
      setPublicarOK(true)
    } catch (error) {
      console.error(error)
      alert("ERRO")
      setLoadingSave(false)
    }
  }

  return (
    <FluxoContext.Provider value={{
      fluxo, setFluxo,
      fluxoId,
      elements, setElements,
      nomeFluxo, setNomeFluxo,
      toPrev, toNext, prevOK, nextOK, saveOK, setSaveOK, publicarOK, setPublicarOK,
      createId,
      publicar, salvar,
      loading, setLoading,
      error,
      fluxoAlterado,
      modalEdicaoIsOpened, setModalEdicaoIsOpened,
      modalEdicao, setModalEdicao,
      modalEdicaoMenuIsOpened, setModalEdicaoMenuIsOpened,
      modalEdicaoMenu, setModalEdicaoMenu,
      modalEdicaoCondicoesIsOpened, setModalEdicaoCondicoesIsOpened,
      modalEdicaoCondicoes, setModalEdicaoCondicoes,
      loadingSave,
      findConnection,
      infos, setInfos
    }}>
      {children}
    </FluxoContext.Provider>
  )
}

export default FluxoContext
