import { Card, CardBody } from "reactstrap"

import "./style.scss"

export default function CanalBox({ canal, setOpened, removerCanal }) {
    return (
        <Card className="box" onClick={() => setOpened(canal)}>
            <CardBody className="box-body">
                {canal.canal_tipo_id == "webchat" && <>
                    <i className="uil-comments-alt webchat" />
                    <p>{canal.canal_tipo_nome}</p>
                </>}
            </CardBody>
        </Card>
    )
}