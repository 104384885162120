import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"

const UsuariosListaColunas = (removerUsuario, setModalEdicao, permissoes, translate) => [
  {
    text: translate("Nome"),
    dataField: "nome",
    sort: true,
    formatter: (cellContent, usuario) => (
      <>
        {!usuario.foto ? (
          <div className="avatar-xs d-inline-block me-2">
            <div className="avatar-title bg-soft-primary rounded-circle text-primary">
              <i className="mdi mdi-account-circle m-0"></i>
            </div>
          </div>
        ) : (
          <img
            className="avatar-xs rounded-circle me-2"
            src={usuario.foto}
            alt=""
          />
        )}
        <Link to="#" className="text-body" onClick={() => setModalEdicao(usuario)}>
          {usuario.nome}
        </Link>
      </>
    ),
  },
  {
    text: translate("Codinome"),
    dataField: "codinome",
    sort: true,
    formatter: (cellContent, usuario) => (
      <>
        @{usuario.codinome}
      </>
    ),
  },
  {
    dataField: "email",
    text: translate("E-mail"),
    sort: true,
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, usuario) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {permissoes.grupos_gerenciamento_usuarios && <DropdownItem href="#" onClick={() => { setModalEdicao(usuario) }}>{translate("Editar")}</DropdownItem>}
              {permissoes.grupos_gerenciamento_usuarios && <DropdownItem href="#" onClick={() => { removerUsuario(usuario) }}>{translate("Remover")}</DropdownItem>}
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    ),
  },
]

export default UsuariosListaColunas
