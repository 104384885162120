import api from "./api"

const getMensagensPreDefinidas = async () => {
    try {
        const resultado = await api.get("/mensagens-pre-definidas")
        const mensagensPreDefinidas = resultado.data
        return mensagensPreDefinidas
    } catch (error) {
        throw error
    }
}

const getMensagensPreDefinidasPorSetor = async (setor_id) => {
    try {
        const resultado = await api.get(`/mensagens-pre-definidas?setor_id=${setor_id}`)
        const mensagensPreDefinidas = resultado.data
        return mensagensPreDefinidas
    } catch (error) {
        throw error
    }
}

const postMensagemPreDefinida = async (data) => {
    try {
        const resultado = await api.post("/mensagens-pre-definidas", data)
        const mensagensPreDefinidas = resultado.data
        return mensagensPreDefinidas
    } catch (error) {
        throw error
    }
}

const putMensagemPreDefinida = async (id, data) => {
    try {
        const resultado = await api.put(`/mensagens-pre-definidas/${id}`, data)
        const mensagensPreDefinidas = resultado.data
        return mensagensPreDefinidas
    } catch (error) {
        throw error
    }
}

const deleteMensagemPreDefinida = async (id) => {
    try {
        const resultado = await api.delete(`/mensagens-pre-definidas/${id}`)
        const mensagensPreDefinidas = resultado.data
        return mensagensPreDefinidas
    } catch (error) {
        throw error
    }
}

export { getMensagensPreDefinidas, getMensagensPreDefinidasPorSetor, postMensagemPreDefinida, putMensagemPreDefinida, deleteMensagemPreDefinida }
