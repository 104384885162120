import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"

const FluxosListaColunas = (removerFluxo, setEdicaoModal, permissoes) => [
  {
    text: "Nome",
    dataField: "nome",
    sort: true,
    formatter: (cellContent, fluxo) => (
      <>
        <Link to={`/fluxos/editor/${fluxo.id}`} className="text-body">
          {fluxo.nome}
        </Link>
      </>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, fluxo) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {permissoes.fluxos_gerenciamento && <DropdownItem href={`/fluxos/editor/${fluxo.id}`} onClick={() => { setEdicaoModal(fluxo) }}>Editar</DropdownItem>}
              {permissoes.fluxos_gerenciamento && <DropdownItem href="#" onClick={() => { removerFluxo(fluxo) }}>Remover</DropdownItem>}
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    ),
  },
]

export default FluxosListaColunas
