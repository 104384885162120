import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { withTranslation } from "react-i18next"
import { Input, Label } from "reactstrap"
import { getConfiguracoesDeAplicacao, putConfiguracoesDeAplicacao } from "../../../services/aplicacao"
import Carregando from "../../Carregando"
import DefaultForm from "../../form/DefaultForm"
import "./styles.scss"

const limitesPorPagina = [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 40, label: "40" },
  { value: 50, label: "50" },
]

const idiomas = [
  { value: "pt-br", label: "Português" },
]

const timezones = [
  { value: "-12:00:00", label: "-12:00:00" },
  { value: "-11:00:00", label: "-11:00:00" },
  { value: "-10:00:00", label: "-10:00:00" },
  { value: "-09:00:00", label: "-09:00:00" },
  { value: "-08:00:00", label: "-08:00:00" },
  { value: "-07:00:00", label: "-07:00:00" },
  { value: "-06:00:00", label: "-06:00:00" },
  { value: "-05:00:00", label: "-05:00:00" },
  { value: "-04:00:00", label: "-04:00:00" },
  { value: "-03:00:00", label: "-03:00:00" },
  { value: "-02:00:00", label: "-02:00:00" },
  { value: "-01:00:00", label: "-01:00:00" },
  { value: "+00:00:00", label: "+00:00:00" },
  { value: "+01:00:00", label: "+01:00:00" },
  { value: "+02:00:00", label: "+02:00:00" },
  { value: "+03:00:00", label: "+03:00:00" },
  { value: "+04:00:00", label: "+04:00:00" },
  { value: "+05:00:00", label: "+05:00:00" },
  { value: "+06:00:00", label: "+06:00:00" },
  { value: "+07:00:00", label: "+07:00:00" },
  { value: "+08:00:00", label: "+08:00:00" },
  { value: "+09:00:00", label: "+09:00:00" },
  { value: "+10:00:00", label: "+10:00:00" },
  { value: "+11:00:00", label: "+11:00:00" },
  { value: "+12:00:00", label: "+12:00:00" },
]

export default withTranslation()(function Configuracoes(props, values) {
  const [loading, setLoading] = useState(true)
  const [configuracoes, setConfiguracoes] = useState(false)
  const [checked, setChecked] = useState(false)

  function translate(text) {
    return props.t(text)
  }

  const [form, setForm] = useState({
    manter_apos_logar: {
      component:
        <Label check style={{ marginBottom: 16 }}>
          <Input  id="manter_apos_logar" name="manter_apos_logar" type="checkbox" onChange={(e) => setChecked(e.target.checked)} style={{ marginRigth: 8 }}/>{' '}
          Manter Contatos em Atendimento após Logar
        </Label>
    },
    limite_por_pagina: { name: "limite_por_pagina", type: "select", value: limitesPorPagina[0].value, options: limitesPorPagina, label: translate("Limite por Página"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
    fuso_horario: { name: "fuso_horario", type: "select", value: timezones[9].value, options: timezones, label: translate("Fuso Horário"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
    idioma: { name: "idioma", type: "select", value: idiomas[0].value, options: idiomas, label: translate("Idioma"), placeholder: "", errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
    codigo_de_pais: { name: "codigo_de_pais", type: "text", label: translate("Código de País"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
    logotipo: { label: translate("Logotipo"), name: "logotipo", type: "upload-avatar", placeholder: translate("Logotipo"), errorMessage: translate("Campo obrigatório"), validate: { }, size: { lg: 12, md: 12, sm: 12 } },
  })

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const start = async () => {
    try {
      const configs = await getConfiguracoesDeAplicacao()
      setConfiguracoes(configs)
      let newJson = { ...form }
      Object.keys(newJson).forEach(key => {
        newJson[key] = { ...newJson[key], value: configs[key] ? `${configs[key]}` : null }
      })

      setForm(newJson)
    } catch (error) {
      console.log("ERRO", error)
    }

    setLoading(() => false)
  }

  const handleSubmit = async (event, errors, data) => {
    try {
      if (errors.length > 0) return

      data.manter_apos_logar = checked

      await putConfiguracoesDeAplicacao(configuracoes.id, data)

      setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `Configurações atualizada com sucesso.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); ; } })//setOpened(false); } })
    } catch (error) {
      setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
    }
  }

  useEffect(() => {
    start()
  }, [])

  return (
    <React.Fragment>
      {loading ? <Carregando />
      :
        <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />
      }

      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
})
