import React from 'react'
import { Handle } from 'react-flow-renderer'
import "./styles.scss"

export default function NodeInicial() {
    return (
        <div className="node-inicial">
            <Handle
                type="source"
                position="bottom"
                id={`handle-inicial`}
                style={{ left: `50%` }}
            />
        </div>
    )
}