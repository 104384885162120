import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { getUsers } from "../../../store/contacts/actions"
import { isEmpty } from "lodash"

import { getCanais, deleteCanal } from "../../../services/canais"

import PermissaoContext from "../../../contexts/PermissaoContext"

import ModalCadastroCanal from "../../../components/modals/canal/ModalCadastroCanal"
import ModalEdicaoCanal from "../../../components/modals/canal/ModalEdicaoCanal"

import CanalBox from "./CanalBox"

import SweetAlert from "react-bootstrap-sweetalert"

import "./style.scss"
import Carregando from "../../../components/Carregando"

const CanaisLista = props => {
  function translate (text) {
    return props.t(text)
  }

  const { users, onGetUsers } = props
  const [list, setList] = useState([])
  const [listView, setListView] = useState([])
  const [loading, setLoading] = useState(true)

  const [modalCadastroOpened, setModalCadastroOpened] = useState(false)
  const [modalEdicaoIsOpened, setModalEdicaoIsOpened] = useState(false)
  const [modalEdicaoOpened, setModalEdicaoOpened] = useState(null)

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const { permissoes, isLoadingPermissoes } = useContext(PermissaoContext)

  useEffect(() => {
    buscarCanais()
  }, [])

  useEffect(() => {
    setModalEdicaoIsOpened(modalEdicaoOpened ? true : false)
  }, [modalEdicaoOpened])

  useEffect(() => {
    if (!isEmpty(users)) {
      setList(users)
    }
  }, [users])

  const buscarCanais = async () => {
    try {
      const canais = await getCanais()

      setList(canais)
      setListView(canais)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const removerCanal = async (canal) => {
    try {
      console.log("removerCanal", canal)
      setSweetAlert({
        ...sweetAlert,
        show: true,
        title: translate("Atenção!"),
        text: `${translate("Tem certeza que deseja excluir o canal do tipo")} ${canal.canal_tipo_nome}?`,
        showCancel: true,
        cancelBtnBsText: translate("Não"),
        cancelBtnBsStyle: "danger",
        warning: true,
        onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
        onConfirm: async () => {
          setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: translate("Estamos removendo o canal") })
          try {
            let retorno = await deleteCanal(canal.id)
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: translate("Sucesso"), text: retorno || translate("Canal removido com sucesso!"), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
            setModalEdicaoOpened(false)
            handleRefresh(canal, "remove")
          } catch (error) {
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: translate("Ooops"), text: error.message || translate("Ocorreu um erro."), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleRefresh = async (canal, action = "add") => {
    console.log("{ handleRefresh }", action, list, canal)
    if (action == "add") {
      let newArray = [...list, canal]
      setList(newArray)
    } else if (action == "edit") {
      let newArray = [...list]
      for (let i = 0; i < newArray.length; i++) {
        const el = newArray[i]
        if (el.id == canal.id) {
          newArray[i] = canal
          break
        }
      }
    } else if (action == "remove") {
      let newArray = list.filter(user => user.id != canal.id)
      setList(newArray)
    }
  }

  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={translate("Canais")} breadcrumbItem={translate("Lista de Canais")} />

          {loading ? <Carregando /> :
            <Row>
              {list.map((canal, index) => (
                <Col lg={3} md={4} sm={6} key={index}>
                  <CanalBox key={canal.id} canal={canal} setOpened={setModalEdicaoOpened} />
                </Col>
              ))}
              <Col lg={3} md={4} sm={6}>
                <Card className="box new" onClick={() => setModalCadastroOpened(true)}>
                  <CardBody className="box-body">
                    <i className="uil-comment-plus new"></i>
                    <p>{translate("Novo canal")}</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>

      <ModalCadastroCanal opened={modalCadastroOpened} setOpened={setModalCadastroOpened} refresh={handleRefresh} />
      <ModalEdicaoCanal opened={modalEdicaoIsOpened} setOpened={(bool) => { setModalEdicaoIsOpened(bool); if (!bool) setModalEdicaoOpened(null) }} refresh={handleRefresh} values={modalEdicaoOpened} remover={removerCanal} />

      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
}

CanaisLista.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CanaisLista)))
