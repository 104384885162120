import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from "reactstrap"
import AvatarDefault from "../../../assets/images/avatar-default.jpeg"
import ModalMeusDados from "../../../components/modals/meus-dados/ModalMeusDados"
import AuthContext from "../../../contexts/AuthContext"
import { getUsuario } from "../../../services/authService"

const ProfileMenu = props => {
  const { usuario, setUsuario } = useContext(AuthContext)

  const [modalMeusDadosOpened, setModalMeusDadosOpened] = useState(false)

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  useEffect(() => {
    buscarUsuario()
  }, [])

  const buscarUsuario = async () => {
    const user = await getUsuario()
    setUsuario(user)
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={(usuario && usuario.foto) || AvatarDefault}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{usuario && usuario.nome}</span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={() => { setModalMeusDadosOpened(true) }}>
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props.t("Meus dados")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <i className="uil uil-cog font-size-18 align-middle me-1 text-muted"></i>
            {props.t("Configurações")}
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/login" className="dropdown-item">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Sair")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>

      <ModalMeusDados opened={modalMeusDadosOpened} setOpened={setModalMeusDadosOpened} usuario={usuario} />
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
