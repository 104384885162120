import React from "react"
import {
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"

const SetoresListaColunas = (removerSetor, setEdicaoModal, permissoes, usuariosOpened, horariosOpened, mensagensPreDefinidasOpened, translate) => [
  {
    text: translate("Nome"),
    dataField: "nome",
    sort: true,
    formatter: (cellContent, setor) => (
      <>
        <Link to="#" className="text-body" onClick={() => { setEdicaoModal(setor) }}>
          {setor.nome}
        </Link>
      </>
    ),
  },
  {
    text: translate("Descrição"),
    dataField: "descricao",
    sort: true,
    formatter: (cellContent, setor) => (
      <>
        {
          setor.descricao ||
          <i>Sem descrição</i>
        }
      </>
    ),
  },
  {
    text: translate("Setor de transborto"),
    dataField: "setor_nome_transbordo",
    sort: true,
    formatter: (cellContent, setor) => (
      <>
        {setor.setor_nome_transbordo || <i>{translate("Nenhum")}</i>}
      </>
    ),
  },
  {
    text: translate("Qtd. de usuários"),
    dataField: "quantidade_usuarios",
    sort: true,
    formatter: (cellContent, setor) => (
      <>
        {setor.quantidade_usuarios == 0 ? `${translate("Nenhum")} ${translate("usuário")}` : setor.quantidade_usuarios == 1 ? `1 ${translate("usuário")}` : `${setor.quantidade_usuarios} ${translate("usuários")}`}
      </>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, setor) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item" onClick={() => { mensagensPreDefinidasOpened(setor) }}>
          <i className="uil uil-chat" />
        </li>
        <li className="list-inline-item" onClick={() => { horariosOpened(setor) }}>
          <i className="uil uil-clock-three" />
        </li>
        <li className="list-inline-item" onClick={() => { usuariosOpened(setor) }}>
          <i className="uil uil-user-circle" />
        </li>
        <li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              {permissoes.setores_gerenciamento && <DropdownItem href="#" onClick={() => { setEdicaoModal(setor) }}>{translate("Editar")}</DropdownItem>}
              {permissoes.setores_gerenciamento && <DropdownItem href="#" onClick={() => { removerSetor(setor) }}>{translate("Remover")}</DropdownItem>}
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    ),
  },
]

export default SetoresListaColunas
