import api from "./api"

const getCamposAdicionais = async () => {
  try {
    const resultado = await api.get("/campos-adicionais")
    return resultado.data
  } catch (error) {
    throw error
  }
}

const postCampoAdicional = async (data) => {
  try {
    const resultado = await api.post("/campos-adicionais", data)
    return resultado.data
  } catch (error) {
    throw error
  }
}

const putCampoAdicional = async (id, data) => {
  try {
    const resultado = await api.put(`/campos-adicionais/${id}`, data)
    return resultado.data
  } catch (error) {
    throw error
  }
}

const deleteCampoAdicional = async (id) => {
  try {
    const resultado = await api.delete(`/campos-adicionais/${id}`)
    return resultado.data
  } catch (error) {
    throw error
  }
}

export { getCamposAdicionais, postCampoAdicional, putCampoAdicional, deleteCampoAdicional }
