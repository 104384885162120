import React, { useContext, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import PermissaoContext from "../../../contexts/PermissaoContext"
import { getGrupos } from "../../../services/grupos"
import { putUsuario } from "../../../services/usuarios"
import DefaultForm from "../../form/DefaultForm"

export default withTranslation()(function ModalEdicaoUsuario({ opened, setOpened, refresh, values, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const { permissoes } = useContext(PermissaoContext)

    const [form, setForm] = useState({
        nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
        codinome: { name: "codinome", type: "text", label: translate("Codinome"), placeholder: translate("Codinome"), help: translate("Atalho de acesso e identificação do usuário"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
        email: { name: "email", type: "email", label: translate("E-mail"), placeholder: translate("E-mail"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
        grupos: { name: "grupos", type: "multiselect", label: translate("Grupos"), placeholder: translate("Carregando grupos")+"...", isDisabled: false, isMulti: true, options: [], errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
        acesso_como_atendente: { label: translate("Acesso como Atendente"), fields: {} },
        hora_inicio: { name: "hora_inicio", type: "time", label: translate("Horário Inicial"), placeholder: translate("Horário Inicial"), errorMessage: translate("Campo obrigatório"), size: { lg: 6, md: 6, sm: 12 } },
        hora_fim: { name: "hora_fim", type: "time", label: translate("Horário Final"), placeholder: translate("Horário Final"), errorMessage: translate("Campo obrigatório"), size: { lg: 6, md: 6, sm: 12 } }
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        buscarGrupos()
    }, [opened])

    useEffect(() => {
        setForm({ ...form, grupos: { ...form.grupos, isDisabled: false } })
    }, [opened, permissoes])

    useEffect(async () => {
        if (values) {
            console.log("USUARIO", values)

            let newJson = { ...form }
            Object.keys(newJson).forEach(key => {
                if (newJson[key].type != "multiselect")
                    newJson[key] = { ...newJson[key], value: values[key] ? values[key] : null }
            })

            newJson.grupos.selecteds = []
            for (let grupo of values.grupos) {
                newJson.grupos.selecteds.push({ label: grupo.nome, value: grupo.id })
            }

            //console.log("NEW JSON", newJson)

            setForm(newJson)
        }
    }, [opened, values])

    const buscarGrupos = async () => {
        try {
            const grupos = await getGrupos()

            let options = []
            grupos.forEach(grupo => {
                options.push({ label: grupo.nome, value: grupo.id })
            })

            setForm({ ...form, grupos: { ...form.grupos, options: options, placeholder: translate("Selecione os grupos que desejar") } })
        } catch (error) {
            setForm({ ...form, grupos: { ...form.grupos, placeholder: translate("Erro na busca de grupos"), isDisabled: true } })
        }
    }

    const handleSubmit = async (event, errors, val) => {
        try {
            console.log("{ handleSubmit }", errors, val)
            if (errors.length > 0) return

            if (
              ((val.hora_inicio !== "") && (val.hora_fim === "")) ||
              ((val.hora_inicio === "") && (val.hora_fim !== ""))
            ) {
              setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"), text: "Acesso como atendente deve ser preenchido", success: false, error: true })
              return
            }

            const date = new Date();
            const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];

            if (new Date(`${month+1}/${day}/${year} ${val.hora_inicio}`) > new Date(`${month+1}/${day}/${year} ${val.hora_fim}`)) {
              setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"), text: "Horário final deve ser maior que o horário de início", success: false, error: true })
              return
            }
            let data = val

            const usuario = await putUsuario(values.id, data)

            console.log("USUARIO ATUALIZADO", usuario)

            if (refresh) refresh(usuario, "edit") // Passar usuario para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Usuario")} ${usuario.nome} ${translate("atualizado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalEdicaoUsuario ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Atualizar Usuario")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
