import React, { useState, createContext, useEffect } from 'react'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
    const [usuario, setUsuario] = useState(null)

    useEffect(() => {
        console.log("MUDOU", usuario)
    }, usuario)

    return (
        <AuthContext.Provider value={{
            usuario, setUsuario
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
