import React from "react"
import { Link } from "react-router-dom"
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledDropdown
} from "reactstrap"

const GruposListaColunas = (removerGrupo, setEdicaoModal, permissoes, translate) => [
  {
    text: translate("Nome"),
    dataField: "nome",
    sort: true,
    formatter: (cellContent, grupo) => (
      <>
        <Link to="#" className="text-body" onClick={() => { setEdicaoModal(grupo) }}>
          {grupo.nome}
        </Link>
      </>
    ),
  },
  {
    text: translate("Descrição"),
    dataField: "descricao",
    sort: true,
    formatter: (cellContent, grupo) => (
      <>
        {
          grupo.descricao ||
          <i>Sem descrição</i>
        }
      </>
    ),
  },
  {
    text: translate("Qtd. de usuários"),
    dataField: "quantidade_usuarios",
    sort: true,
    formatter: (cellContent, grupo) => (
      <>
        {grupo.quantidade_usuarios == 0 ? `${translate("Nenhum")} ${translate("usuário")}` : grupo.quantidade_usuarios == 1 ? `1 ${translate("usuário")}` : `${grupo.quantidade_usuarios} ${translate("usuários")}`}
      </>
    ),
  },
  {
    dataField: "menu",
    isDummyField: true,
    text: "",
    formatter: (cellContent, grupo) => (
      <ul className="list-inline font-size-20 contact-links mb-0">
        <li className="list-inline-item dropdown">
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="text-muted font-size-18 px-2" caret>
              <i className="uil uil-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#" onClick={() => { setEdicaoModal(grupo) }}>{translate("Visualizar")}</DropdownItem>
              <DropdownItem href="#" onClick={() => { removerGrupo(grupo) }}>{translate("Remover")}</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    ),
  },
]

export default GruposListaColunas
