import React, { useContext } from 'react';

import NodePersonalizado from './NodePersonalizado';

import FluxoContext from '../../../../../contexts/FluxoContext';

import "./style.scss"

export default function NodeCondicao({ data, id, type, ...rest }) {
  const { setModalEdicaoCondicoes } = useContext(FluxoContext)

  const abrirModal = () => {
    console.log("abrirModal")
    setModalEdicaoCondicoes({
      id: id,
      data: data
    })
  }

  return <NodePersonalizado
    key={id}
    id={id}
    label={data?.nome}
    data={data}
    type={type}
    handles={data?.etapa?.condicoes ? data.etapa.condicoes.length : data.condicoes ? data.condicoes.length : 0}
    buttons={[
      { color: "dark", text: <i className="uil uil-list-ul"></i>, onClick: abrirModal }
    ]}
    {...rest}
  />
}
