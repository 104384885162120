import api from "./api"

const getAtendimentos = async (id = null, page = null, pageSize = null) => {
    try {
        const resultado = await api.get(`/atendimentos/${id || ""}${page ? `?page=${page}${pageSize && `&pageSize=${pageSize}`}` : ""}`)
        const atendimentos = resultado.data
        return atendimentos
    } catch (error) {
        throw error
    }
}

const postAtendimento = async (data) => {
    try {
        const resultado = await api.post("/atendimentos", data)
        const atendimentos = resultado.data
        return atendimentos
    } catch (error) {
        throw error
    }
}

const putAtendimento = async (id, data) => {
    try {
        const resultado = await api.put(`/atendimentos/${id}`, data)
        const atendimentos = resultado.data
        return atendimentos
    } catch (error) {
        throw error
    }
}

const deleteAtendimento = async (id) => {
    try {
        const resultado = await api.delete(`/atendimentos/${id}`)
        const atendimentos = resultado.data
        return atendimentos
    } catch (error) {
        throw error
    }
}

const encerrarAtendimento = async (atendimento_id) => {
    try {
        const { data } = await api.post(`/atendimentos/encerrar`, { atendimento_id })
        return data
    } catch (error) {
        throw error
    }
}

export { encerrarAtendimento, getAtendimentos, postAtendimento, putAtendimento, deleteAtendimento }
