import "./styles.scss"

//i18n
import { withTranslation } from "react-i18next"

export default withTranslation()(function MensagemWhatsapp ({ value, imagem, ...props }) {
    return (
        <div className="chat">
            {imagem && <img src={imagem} />}
            {
                value &&
                    value.split("<p>").join("").split("</p>").join("").split("<ins>").join("").split("</ins>").join("").split("<strong>").join("").split("</strongg>").join("").split("<em>").join("").split("</em>").join("").split("&nbsp;").join("").trim().length > 0 ? <p dangerouslySetInnerHTML={{ __html: value }}></p>
                    :
                    imagem ? <></> :
                        <i>{props.t("Mensagem vazia")}</i>
            }
        </div>
    )
})