import api from "./api"

const getPermissoesDeSetor = async () => {
  try {
    const resultado = await api.get(`/permissoes-de-setor`)
    const permissoes = resultado.data
    return permissoes
  } catch (error) {
    throw error
  }
}

export { getPermissoesDeSetor }
