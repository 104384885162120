import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { putMensagemPreDefinida } from "../../../services/mensagens-pre-definidas"
import DefaultForm from "../../form/DefaultForm"





export default withTranslation()(function ModalEdicaoMensagemPreDefinida({ opened, setOpened, refresh, values, setor_id = null, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const [form, setForm] = useState({
        descricao: { name: "descricao", type: "text", label: translate("Descrição"), placeholder: translate("Descrição"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        comando: { name: "comando", type: "text", label: translate("Comando"), placeholder: translate("Comando"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        mensagem: { name: "mensagem", type: "textarea-whatsapp", label: translate("Mensagem"), placeholder: translate("Mensagem"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        start()
    }, [values])

    const start = async () => {

        if (values) {
            try {
                console.log("VALUESSS", values)

                let newJson = { ...form }
                Object.keys(newJson).forEach(key => {
                    newJson[key] = { ...newJson[key], value: values[key] ? `${values[key]}` : null }
                })

                setForm(newJson)
            } catch (error) {
                console.log("ERRO", error)
            }
        }
    }

    const handleSubmit = async (event, errors, val) => {
        try {
            console.log("{ handleSubmit }", errors, val)
            if (errors.length > 0) return

            if (setor_id !== null) {
                val.setor_id = setor_id
            }

            let data = val

            const mensagemPreDefinida = await putMensagemPreDefinida(values.id, data)

            console.log("USUARIO ATUALIZADO", mensagemPreDefinida)

            if (refresh) refresh(mensagemPreDefinida, "edit") // Passar mensagemPreDefinida para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Mensagem Pré-Definida atualizada com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalEdicaoMensagemPreDefinida ERROR", error, error.result, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Atualizar Mensagem Pré-Definida")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Atualizar")} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
