import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { postGrupoDeHorario } from "../../../services/grupos-de-horarios"
import CadastroHorarios from "../../CadastroHorarios"
import DefaultForm from "../../form/DefaultForm"

export default withTranslation()(function ModalCadatroGrupoDeHorario({ opened, setOpened, refresh, values, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const [form, setForm] = useState({
        nome: { name: "nome", type: "text", label: translate("Nome do Grupo"), placeholder: translate("Nome do Grupo"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        horarios: {
            label: translate("Horários"),
            component: <CadastroHorarios />
        }
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    const handleSubmit = async (event, errors, val) => {
        try {
            val.horarios = validarHorarios(
                val.descricao,
                val.dia_inicio,
                val.dia_fim,
                val.dia_mes_inicio,
                val.dia_mes_fim,
                val.dia_semana_inicio,
                val.dia_semana_fim,
                val.hora_inicio,
                val.hora_fim,
            )
            delete val.descricao
            delete val.dia_inicio
            delete val.dia_fim
            delete val.dia_mes_inicio
            delete val.dia_mes_fim
            delete val.dia_semana_inicio
            delete val.dia_semana_fim
            delete val.hora_inicio
            delete val.hora_fim
            delete val.removed
            delete val.id

            if (errors.length > 0) return

            let data = val

            const grupoDeHorario = await postGrupoDeHorario(data)

            if (refresh) refresh(grupoDeHorario)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Grupo de horário")} ${grupoDeHorario.nome} ${translate("criado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalCadatroGrupoDeHorario ERROR", error)

            if(error instanceof Error) {
              return
            }

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"), text: error.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    const validarHorarios = (
        descricao,
        dia_inicio,
        dia_fim,
        dia_mes_inicio,
        dia_mes_fim,
        dia_semana_inicio,
        dia_semana_fim,
        hora_inicio,
        hora_fim,
    ) => {
        const horarios = descricao.map((descricao, index) =>  {
          if (descricao == "") {
            throw new Error("Preencha os campos em branco da linha de horário")
          }
          return {
            descricao: descricao,
            dia_inicio: dia_inicio[index],
            dia_fim: dia_fim[index],
            dia_mes_inicio: dia_mes_inicio[index].value,
            dia_mes_fim: dia_mes_fim[index].value,
            dia_semana_inicio: dia_semana_inicio[index].value,
            dia_semana_fim: dia_semana_fim[index].value,
            hora_inicio: hora_inicio[index],
            hora_fim: hora_fim[index],
          }
        })

        return horarios
    }

    return (
        <Modal size="lg" style={{maxWidth: '1650px', width: '100%'}} isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Cadastro de Grupo de Horários")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} buttonText={translate("Salvar")} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
