import React from 'react'

import { AuthProvider } from '../contexts/AuthContext'
import { PermissaoProvider } from '../contexts/PermissaoContext'

const Providers = ({ children }) => {
    return (
        <AuthProvider>
            <PermissaoProvider>
                {children}
            </PermissaoProvider>
        </AuthProvider>
    )
}

export default Providers