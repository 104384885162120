import React, { useState, useContext } from "react"
import { useHistory } from "react-router-dom";

import { Button, Collapse, Card, CardBody } from "reactstrap"

import Editable from "react-bootstrap-editable"

import FluxoContext from "../../../../contexts/FluxoContext"

import { putFluxo, deleteFluxo } from "../../../../services/fluxos"

import SweetAlert from "react-bootstrap-sweetalert"

import "./style.scss"

export default function Menu() {
  const { prevOK, nextOK, saveOK, publicarOK, toPrev, toNext, nomeFluxo, setNomeFluxo, publicar, salvar, fluxo, fluxoId, elements, loadingSave } = useContext(FluxoContext)
  const history = useHistory();

  const [fluxoTipos, setFluxoTipos] = useState({
    entradas: {
      nome: "Entradas", tipos: {
        entrada: { nome: "Entrada" },
        menu: { nome: "Menu" }
      }, show: false
    },
    saidas: {
      nome: "Saídas", tipos: {
        mensagem: { nome: "Mensagem" },
        imagem: { nome: "Imagem" }
      }, show: false
    },
    outros: {
      nome: "Outros", tipos: {
        horario: { nome: "Horário" },
        variavel: { nome: "Variável" },
        condicao: { nome: "Condição" },
        finalizar: { nome: "Finalizar" },
        setor: { nome: "Ir para Setor" }
      }, show: false
    }
  })

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const atualizouNome = async (novoNome) => {
    let nomeAtual = nomeFluxo

    setNomeFluxo(novoNome)

    try {
      await putFluxo(fluxoId, { nome: novoNome })
    } catch (error) {
      setNomeFluxo(nomeAtual)
    }
  }

  const removerFluxo = () => {
    setSweetAlert({
      ...sweetAlert,
      show: true,
      title: "Atenção!",
      text: `Tem certeza que deseja excluir o usuário ${fluxo.nome}?`,
      showCancel: true,
      cancelBtnBsText: "Não",
      cancelBtnBsStyle: "danger",
      warning: true,
      onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
      onConfirm: async () => {
        setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: "Aguarde", text: "Estamos removendo o usuário" })
        try {
          await deleteFluxo(fluxoId)
          history.push("/fluxos")
        } catch (error) {
          setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: "Ooops", text: error.message || "Ocorreu um erro.", onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
        }
      }
    })
  }

  const salvarRascunho = () => {
    salvar()
  }

  const publicarFluxo = () => {
    publicar()
  }

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <>
      <Editable
        initialValue={nomeFluxo}
        id={null}
        mode="popover"
        placement="top"
        type="textfield"
        isValueClickable={true}
        onSubmit={val => atualizouNome(val)}
        className="fluxo-nome"
      />

      <div className="fluxo-menu-botoes">
        <div
          className="btn-group"
          role="group"
          aria-label="Basic example"
        >
          <Button color="secondary" disabled={!prevOK} onClick={toPrev}><i className="uil uil-corner-down-left" /></Button>
          <Button color="secondary" disabled={!nextOK} onClick={toNext}><i className="uil uil-corner-down-right" /></Button>
        </div>

        <Button color="danger" onClick={removerFluxo}><i className="uil uil-trash" /></Button>

        <Button color="info" disabled={!saveOK || loadingSave} onClick={salvarRascunho}><i className="uil uil-save" /> {loadingSave && "Salvando..."}</Button>

        <Button color="success" disabled={!publicarOK} onClick={publicarFluxo}>Publicar</Button>
      </div>

      <Card>

        <div className="accordion accordion-flush" id="accordionFlushExample">
          {Object.keys(fluxoTipos).map((key, index) => {
            const fluxoTipo = fluxoTipos[key]

            return (
              <div key={index} className="accordion-item">
                <h2 className="accordion-header" id={`flush-heading-${index}`}>
                  <button className="accordion-button collapsed" type="button"
                    onClick={() => {
                      setFluxoTipos({ ...fluxoTipos, [key]: { ...fluxoTipos[key], show: !fluxoTipos[key].show } })
                    }}
                    style={{ cursor: "pointer" }}>
                    {fluxoTipo.nome}
                  </button>
                </h2>
                <Collapse id={`flush-collapse-${index}`} className="accordion-collapse" isOpen={fluxoTipo.show}>
                  <div className="accordion-body">
                    {Object.keys(fluxoTipo.tipos).map((key, i) => {
                      const tipo = fluxoTipo.tipos[key]
                      return (
                        <div key={i} className={`dndnode ${key}`} onDragStart={(event) => onDragStart(event, key)} draggable>
                          {tipo.nome}
                        </div>
                      )
                    })}
                  </div>
                </Collapse>
              </div>
            )
          })}
        </div>
      </Card>

      <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>
    </>
  )
}
