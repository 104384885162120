import React, { useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import { withTranslation } from "react-i18next"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { postCampoAdicional } from "../../../services/campos-adicionais"
import DefaultForm from "../../form/DefaultForm"

export default withTranslation()(function ModalCadastroCampoAdicional({ opened, setOpened, refresh, ...props }) {
  function translate (text) {
    return props.t(text)
  }

  const [form, setForm] = useState({
    nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
    descricao: { name: "descricao", type: "textarea", label: translate("Descrição"), placeholder: translate("Descreva o setor"), validate: {}, size: { lg: 12, md: 12, sm: 12 } },
    obrigatorio: { name: "obrigatorio", type: "select", value: "0", options: [{ value: 1, label: translate("Sim") }, { value: 0, label: translate("Não") }], label: translate("Obrigatório"), validate: {}, size: { lg: 3, md: 3, sm: 12 } },
    permitir_busca: { name: "permitir_busca", type: "select", value: "1", options: [{ value: 1, label: translate("Sim") }, { value: 0, label: translate("Não") }], label: translate("Permitir Busca"), validate: {}, size: { lg: 3, md: 3, sm: 12 } },
    exibir_lista: { name: "exibir_lista", type: "select", value: "1", options: [{ value: 1, label: translate("Sim") }, { value: 0, label: translate("Não") }], label: translate("Exibir Lista"), validate: {}, size: { lg: 3, md: 3, sm: 12 } },
    confidencial: { name: "confidencial", type: "select", value: "0", options: [{ value: 1, label: translate("Sim") }, { value: 0, label: translate("Não") }], label: translate("Confidencial"), validate: {}, size: { lg: 3, md: 3, sm: 12 } },
    validacao_tipo: { name: "validacao_tipo", type: "select", value: "null", options: [{ value: "null", label: translate("Sem validação") }, { value: "personalizado", label: translate("Personalizado") }, { value: "layout", label: translate("Layout") }], label: translate("Validação"), placeholder: translate("Escolha o tipo de validação"), validate: {}, size: { lg: 12, md: 12, sm: 12 }, onChange: (e) => validacaoTipoAlterado(e.target.value) },
  })

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const validacaoTipoAlterado = (val) => {
    if (val === "0") {
      setForm((form) => {
        let { validacao_layout, condicao_tipo, condicao_valor, ...rest } = form
        return {
          ...rest,
          validacao_tipo: { ...form.validacao_tipo, size: { lg: 12, md: 12, sm: 12 } }
        }
      })
    } else if (val === "personalizado") {
      setForm((form) => {
        let options = [
          { label: "=", value: "=" },
          { label: "<=", value: "<=" },
          { label: ">=", value: ">=" },
          { label: "<>", value: "<>" }
        ]
        let { validacao_layout, ...rest } = form

        return {
          ...rest,
          validacao_tipo: { ...form.validacao_tipo, size: { lg: 3, md: 3, sm: 12 } },
          condicao_tipo: {
            name: "condicao_tipo", type: "select", label: translate("Tipo de Condição"), value: options[0].value, options: options, validate: {}, size: { lg: 3, md: 3, sm: 12 }
          },
          condicao_valor: { name: "condicao_valor", type: "text", label: translate("Layout"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
        }
      })
    } else if (val === "layout") {
      setForm((form) => {
        let options = [
          { label: "CPF", value: "cpf" },
          { label: "CNPJ", value: "cnpj" },
          { label: "CEP", value: "cep" },
          { label: "Data", value: "data" }
        ]

        let { condicao_tipo, condicao_valor, ...rest } = form

        return {
          ...rest,
          validacao_tipo: { ...form.validacao_tipo, size: { lg: 3, md: 3, sm: 12 } },
          validacao_layout: {
            name: "validacao_layout", type: "select", label: translate("Layout"), value: options[0].value, options: options, validate: {}, size: { lg: 9, md: 9, sm: 12 }
          },
        }
      })
    }
  }

  const handleSubmit = async (_, errors, data) => {
    try {
      if (errors.length > 0) return
      const setor = await postCampoAdicional(data)
      if (refresh) refresh(setor) // Passar setor para componente pai
      setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Campo Adicional")} ${setor.nome} ${translate("cadastrado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
    } catch (error) {
      setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
    }
  }

  return (
    <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
      <ModalHeader toggle={() => { setOpened(!opened) }}>
        {translate("Cadastrar Campo Adicional")}
      </ModalHeader>
      <ModalBody>
        <DefaultForm fields={form} handleSubmit={handleSubmit} />
        {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
      </ModalBody>
    </Modal>
  )
})
