import PropTypes from 'prop-types'
import React, { useEffect, useState, useContext } from "react"

import { Row, Col, Alert, Container, CardBody, Card } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import radiusChatOmni from "../../assets/images/radiuschat-omni.png"

import { login } from "../../services/authService"

import AuthContext from '../../contexts/AuthContext'

const Login = (props) => {
  function translate (text) {
    return props.t(text)
  }
  
  const [loading, setLoading] = useState(false)
  const { setUsuario } = useContext(AuthContext)

  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    try {
      console.log(values)
      setLoading(true)
      const resultado = await login(values.email, values.senha)

      setUsuario(resultado)

      localStorage.setItem("access_token", resultado.access_token)

      //alert(resultado.access_token)
      props.history.push("/dashboard")
    } catch (error) {
      console.log(error.message)
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log(props)
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={4} lg={6} xl={5}>
              <div className="text-center">
                <Link to="/" className="mb-5 mr-5 d-block auth-logo">
                  <img src={radiusChatOmni} alt="" height="500" className="logo logo-dark" />
                  <img src={radiusChatOmni} alt="" height="500" className="logo logo-light" />
                </Link>
              </div>
            </Col>
            <Col md={8} lg={6} xl={5} >
              <Card>

                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{translate("Seja bem-vindo")}</h5>
                    <p className="text-muted">{translate("Entre com sua conta para continuar")}</p>
                  </div>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label={translate("E-mail")}
                          className="form-control"
                          placeholder={translate("Digite seu e-mail")}
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">{translate("Esqueceu sua senha?")}</Link>
                        </div>
                        <AvField
                          name="senha"
                          label={translate("Senha")}
                          type="password"
                          required
                          placeholder={translate("Informe sua senha")}
                        />
                      </div>

                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="customControlInline" name="lembrar" />
                        <label className="form-check-label" htmlFor="customControlInline">
                          {translate("Lembrar de mim")}
                        </label>
                      </div>

                      <div className="mt-3">
                        <button className="btn btn-primary w-100 waves-effect waves-light" type="submit" disabled={loading}>{translate("Entrar")}</button>
                      </div>
                    </AvForm>

                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>&copy; {new Date().getFullYear()}. {translate("Desenvolvido")} {translate("por")} RadiusNet</p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(withTranslation()(Login))
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}