import api from "./api"

const getCanais = async () => {
    try {
        const resultado = await api.get("/canais?configuracoes=true&usuarios=true")
        const canais = resultado.data
        return canais
    } catch (error) {
        throw error
    }
}

const getTiposCanal = async () => {
    try {
        const resultado = await api.get("/canais/tipos")
        const tipos = resultado.data
        return tipos
    } catch (error) {
        throw error
    }
}

const postCanal = async (data) => {
    try {
        const resultado = await api.post("/canais", data)
        const canais = resultado.data
        return canais
    } catch (error) {
        throw error
    }
}

const putCanal = async (id, data) => {
    try {
        const resultado = await api.put(`/canais/${id}`, data)
        const canais = resultado.data
        return canais
    } catch (error) {
        throw error
    }
}

const deleteCanal = async (id) => {
    try {
        const resultado = await api.delete(`/canais/${id}`)
        const canais = resultado.data
        return canais
    } catch (error) {
        throw error
    }
}

export { getCanais, postCanal, putCanal, deleteCanal, getTiposCanal }