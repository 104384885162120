import PropTypes from 'prop-types'
import React, { useEffect, useState, useContext } from "react"
import { useHistory } from "react-router-dom";

import { Row, Col, Alert, Container, CardBody, Card } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions"

// import images
import radiusChatOmni from "../../assets/images/radiuschat-omni.png"

import { confirmar } from "../../services/authService"

import AuthContext from '../../contexts/AuthContext'
import DefaultForm from '../../components/form/DefaultForm'

const Confirmacao = (props) => {
  function translate (text) {
    return props.t(text)
  }
  
  const history = useHistory();

  const [loading, setLoading] = useState(true)
  const { setUsuario } = useContext(AuthContext)

  const [uuidConfirmacao, setUuidConfirmacao] = useState(null)

  const [form, setForm] = useState({
    senha: { name: "senha", type: "password", label: translate("Senha"), placeholder: translate("Senha"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
    confirmacao: { name: "confirmacao", type: "password", label: translate("Confirme a senha"), placeholder: translate("Confirme a senha"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
    msg: {
      component: <></>
    }
  })

  useEffect(() => {
    let search = props.history.location.search
    search = search.replace("?c=", "")

    let uuid = search

    if (uuid.length > 0) {
      setUuidConfirmacao(uuid)
      setLoading(false)
    } else {
      setUuidConfirmacao(null)
      setLoading(false)
    }


    console.log(props)
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  const handleSubmit = async (event, errors, values) => {
    console.log("handleSubmit", values)

    setForm(form => {
      return {
        ...form,
        msg: { ...form.msg, component: <></> }
      }
    })

    if (errors.length > 0)
      return

    let { senha, confirmacao } = values

    if (senha != confirmacao) {
      setForm(form => {
        return {
          ...form,
          msg: { ...form.msg, component: <p style={{ textAlign: "center", color: "red" }}>{translate("As senhas devem ser iguais")}</p> }
        }
      })
      return
    }

    try {
      await confirmar(uuidConfirmacao, senha)

      history.push("/login")
    } catch (error) {
      console.error(error)
      setForm(form => {
        return {
          ...form,
          msg: { ...form.msg, component: <p style={{ textAlign: "center", color: "red" }}>{typeof error == "string" ? error : translate("Ocorreu um erro")}</p> }
        }
      })
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col sm={12} md={6} lg={6} xl={5} >
              <Card>
                <CardBody className="p-4">
                  {
                    loading ?
                      <div className="text-center mt-2">
                        <h5 className="text-primary">{translate("Carregando")}...</h5>
                      </div>
                      : uuidConfirmacao ? <>
                        <div className="text-center mt-2">
                          <h5 className="text-primary">{translate("Você está quase lá!")}</h5>
                          <p className="text-muted">{translate("Crie uma senha para confirmar sua conta")}</p>
                        </div>
                        <div className="p-2 mt-4">
                          <DefaultForm
                            fields={form}
                            buttonText={translate("Confirmar")}
                            handleSubmit={handleSubmit}
                          />

                        </div>
                      </> :
                        <div className="text-center mt-2">
                          <h5 className="text-primary">{translate("Não foi identificador um código de confirmação.")}</h5>
                        </div>
                  }
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>&copy; {new Date().getFullYear()}. {translate("Desenvolvido")} {translate("por")} RadiusNet</p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(withTranslation()(Confirmacao))
)

Confirmacao.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func
}