import { AvForm } from "availity-reactstrap-validation";
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Button, Col, Row } from "reactstrap";
import FormPart from './FormPart';
import "./style.scss";




export default function DefaultForm({ fields, handleSubmit, buttonSubmit = true, buttonText = "Cadastrar", buttonSubmitDisabled = false, buttons, logs = false }) {
    useEffect(() => {
        if (logs)
            console.log("ALTEROU FIELDS", fields)
    }, [fields])

    return (
        <AvForm onSubmit={handleSubmit} onInvalidSubmit={e => console.error(e)}>
            <Row>
                {Object.keys(fields).map((field, index) => <FormPart key={index} input={fields[field]} />)}
            </Row>

            {((buttons && buttons.length > 0) || buttonSubmit) && <Row>
                <Col lg={12} align="right" className="buttons">
                    {buttons && buttons.filter(b => !b.hasOwnProperty("show") || b.show).map((button, index) => (
                        <Button key={index} {...button}>{button.text}</Button>
                    ))}

                    {buttonSubmit && <Button color="primary" type="submit" disabled={buttonSubmitDisabled}>{buttonText}</Button>}
                </Col>
            </Row>}
        </AvForm >
    )
}

DefaultForm.propTypes = {
  fields: PropTypes.array,
  buttonSubmit: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonSubmitDisabled: PropTypes.bool,
  buttons: PropTypes.array,
  logs: PropTypes.bool
};
