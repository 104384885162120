import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
//i18n
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import Carregando from "../../../components/Carregando"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import PermissaoContext from "../../../contexts/PermissaoContext"
import { encerrarAtendimento, getAtendimentos } from "../../../services/atendimentos"
import { getUsers } from "../../../store/contacts/actions"
import AtendimentosListaColunas from "./AtendimentosListaColunas"








const AtendimentosLista = props => {
  function translate (text) {
    return props.t(text)
  }

  const [list, setList] = useState([])
  const [pageOptions, setPageOptions] = useState({
    page: 1,
    sizePerPage: 10,
    totalSize: list.length,
    custom: false
  })
  const { SearchBar } = Search

  const [loading, setLoading] = useState(true)

  const [pageSize, setPageSize] = useState(10)

  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const [sweetAlert, setSweetAlert] = useState({
    show: false,
    title: "",
    text: "",
    confirmBtnBsStyle: "success",
    onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
  })

  const { permissoes, isLoadingPermissoes } = useContext(PermissaoContext)

  useEffect(() => {
    buscarAtendimentos(1, pageSize)
  }, [])

  const buscarAtendimentos = async (page, pageSize) => {
    try {
      setLoading(() => true)

      const resultado = await getAtendimentos(null, page, pageSize)

      let { atendimentos, totalSize } = resultado

      setPageOptions({
        page: page,
        sizePerPage: pageSize,
        totalSize: totalSize,
        custom: false
      })

      setList(atendimentos)

      //handleTableChange(null, { page: 1, searchText: "" }, atendimentos)

      setLoading(() => false)
    } catch (error) {
      setLoading(false)
    }
  }

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText, sizePerPage }, data = null) => {
    const dados = data || list
    console.log("{ handleTableChange }", page, searchText, dados)
    setPage(page)
    setSearchText(searchText)
    setPageSize(sizePerPage)

    buscarAtendimentos(page, sizePerPage)

    /*let inicio = pageOptions.sizePerPage * page - pageOptions.sizePerPage
    let fim = pageOptions.sizePerPage * page
    if (fim > dados.length) fim = dados.length

    setList(
      dados.slice(inicio, fim).filter(user =>
        Object.keys(user).some(
          key =>
            typeof user[key] === "string" &&
            user[key].toLowerCase().includes(searchText.toLowerCase())
        )
      )
    )*/
  }

  const handleTransferirAtendimento = async (atendimento) => {
    try {
      setSweetAlert({
        ...sweetAlert,
        show: true,
        title: translate("Atenção!"),
        text: `${translate("Regra de negócio da para transfência de atendimento vai ser discutida")}`,
        showCancel: true,
        cancelBtnBsText: translate("Não"),
        cancelBtnBsStyle: "danger",
        warning: true,
        onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
        onConfirm: async () => {
          setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: translate("Estamos transferindo o atendimento") })
          try {
            let retorno = "Sucesso" // transferirAtendimento(atendimento.id, atendente.id)
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: translate("Sucesso"), text: retorno || translate("Atendimento encerrado com sucesso!"), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          } catch (error) {
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: translate("Ooops"), text: error.message || translate("Ocorreu um erro."), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleEncerrarAtendimento = async (atendimento) => {
    try {
      setSweetAlert({
        ...sweetAlert,
        show: true,
        title: translate("Atenção!"),
        text: `${translate("Tem certeza que deseja encerrar o atendimento de protocolo")} ${atendimento.protocolo}?`,
        showCancel: true,
        cancelBtnBsText: translate("Não"),
        cancelBtnBsStyle: "danger",
        warning: true,
        onCancel: () => { setSweetAlert({ ...sweetAlert, show: false }) },
        onConfirm: async () => {
          setSweetAlert({ ...sweetAlert, showCancel: false, showConfirm: false, title: translate("Aguarde"), text: translate("Estamos encerrando o atendimento") })
          try {
            let retorno = await encerrarAtendimento(atendimento.id)
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, success: true, title: translate("Sucesso"), text: retorno || translate("Atendimento encerrado com sucesso!"), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          } catch (error) {
            setSweetAlert({ ...sweetAlert, showConfirm: true, info: false, error: true, title: translate("Ooops"), text: error.message || translate("Ocorreu um erro."), onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) } })
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: false,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={translate("Atendimentos")} breadcrumbItem={translate("Lista de Atendimentos")} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {isLoadingPermissoes || loading ? <Carregando /> :
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={list}
                          columns={AtendimentosListaColunas(permissoes, translate, handleTransferirAtendimento, handleEncerrarAtendimento)}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="row mb-2">
                                <Col md={12}>
                                  <div className="form-inline float-md-end mb-3">
                                    <div className="search-box ms-2">
                                      <div className="position-relative">
                                        <SearchBar {...toolkitProps.searchProps} />
                                        <i className="mdi mdi-magnify search-icon"></i>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="mb-4">
                                    <BootstrapTable
                                      selectRow={selectRowProp}
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={"table table-centered table-nowrap mb-0"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/*<div className="float-sm-end">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                          </div>*/}
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
    </React.Fragment>
  )
}

AtendimentosLista.propTypes = {
  users: PropTypes.array,
  onGetUsers: PropTypes.func,
}

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(AtendimentosLista)))
