import api from "./api"

const getSetores = async () => {
    try {
        const resultado = await api.get("/setores?configuracoes=true&usuarios=true&horarios=true")
        const setores = resultado.data
        return setores
    } catch (error) {
        throw error
    }
}

const postSetor = async (data) => {
    try {
        const resultado = await api.post("/setores", data)
        const setores = resultado.data
        return setores
    } catch (error) {
        throw error
    }
}

const putSetor = async (id, data) => {
    try {
        const resultado = await api.put(`/setores/${id}`, data)
        const setores = resultado.data
        return setores
    } catch (error) {
        throw error
    }
}

const deleteSetor = async (id) => {
    try {
        const resultado = await api.delete(`/setores/${id}`)
        const setores = resultado.data
        return setores
    } catch (error) {
        throw error
    }
}

const postUsuarioSetor = async (data) => {
    try {
        const resultado = await api.post("/setores/usuarios", data)
        const retorno = resultado.data
        return retorno
    } catch (error) {
        throw error
    }
}

const deleteUsuarioSetor = async (setor_id, usuario_id) => {
    try {
        const resultado = await api.delete(`/setores/usuarios/${setor_id}/${usuario_id}`)
        const retorno = resultado.data
        return retorno
    } catch (error) {
        throw error
    }
}

export { getSetores, postSetor, putSetor, deleteSetor, postUsuarioSetor, deleteUsuarioSetor }