import React, { memo, useContext } from "react"
import { Row } from "reactstrap"
import FormPart from "../.."
import NavContext from "../../../../../../contexts/NavContext"




const TabContent = ({ tabs }) => {
    const { tabSelected } = useContext(NavContext)

    return <>
        {Object.keys(tabs).map((tabKey, index) =>
            <>
                {/*tabs[tabKey].fields && Object.keys(tabs[tabKey].fields).map(fld =>
                    <Input {...tabs[tabKey].fields[fld]} />
                )*/}
                <Row key={index} style={Object.assign({},
                    { paddingTop: index == tabSelected ? 15 : 0, visibility: index == tabSelected ? "visible" : "hidden" },
                    index != tabSelected ? { maxHeight: 0 } : {}
                )}>
                    <FormPart input={tabs[tabKey]} />
                </Row>
            </>
        )}
    </>
}

export default memo(TabContent)
