import React, { useEffect, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap"
import { postGrupo } from "../../../services/grupos"
import { getGruposDeHorarios } from "../../../services/grupos-de-horarios"
import { getPermissoes } from "../../../services/permissoes"
import DefaultForm from "../../form/DefaultForm"

export default withTranslation()(function ModalCadastroGrupo({ opened, setOpened, refresh, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const [checked, setChecked] = useState(false)

    const [form, setForm] = useState({
        nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 12, md: 12, sm: 12 } },
        grupo_horario_id: { name: "grupo_horario_id", type: "select", options: [], label: translate("Grupo de Horário"), placeholder: translate("Nenhum grupo selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
        grupo_horario_id_excecao: { name: "grupo_horario_id_excecao", type: "select", options: [], label: translate("Grupo de Excessão de Horário"), placeholder: translate("Nenhum grupo selecionado"), errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 6, md: 6, sm: 12 } },
        descricao: { name: "descricao", type: "textarea", label: translate("Descrição"), placeholder: translate("Descreva o grupo"), validate: {}, size: { lg: 12, md: 12, sm: 12 } },
        permissao_total: { label: translate("Permissões"), component: <div>
          <br/>
            <FormGroup check>
              <Input id="permissao_total" name="permissao_total" type="checkbox" onChange={(e) => setChecked(e.target.checked)} />
              <Label check>{translate("Permissão Total")}</Label>
            </FormGroup>
          <br/>
        </div>},
        permissoes: { fields: {} }
    })

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    const [permissoesPadroes, setPermissoesPadroes] = useState([])

    useEffect(() => {
        start()
    }, [])

    const start = async () => {
        try {
            let newJson = { ...form }

            let permissoes = await getPermissoes()
            setPermissoesPadroes(permissoes)

            console.log("PERMISSÕES", permissoes)

            // Separar permissões subcategorias
            let subcategorias = []
            for (let permissao of permissoes) {
                let encontrou = false
                for (let sub of subcategorias) {
                    if (sub.id == permissao.permissao_subcategoria_id) {
                        encontrou = true
                        break
                    }
                }
                if (!encontrou) subcategorias.push({ id: permissao.permissao_subcategoria_id, nome: permissao.permissao_subcategoria_nome, permissoes: [] })
            }

            // Colocar as permissões nas subcategorias
            for (let permissao of permissoes) {
                for (let sub of subcategorias) {
                    if (sub.id == permissao.permissao_subcategoria_id) {
                        sub.permissoes.push(permissao)
                        break
                    }
                }
            }

            console.log("SUBCATEGORIAS", subcategorias)

            let size = { lg: 4, md: 4, sm: 4 }
            if (subcategorias.length == 1) size = { lg: 12, md: 12, sm: 12 }
            else if (subcategorias.length >= 2) size = { lg: 6, md: 6, sm: 12 }
            //  else if (subcategorias.length >= 3) size = { lg: 4, md: 4, sm: 12 }

            subcategorias.forEach(sub => {
                let options = []
                sub.permissoes.forEach(perm => { options.push({ label: perm.nome, value: perm.id, help: perm.descricao, checked: perm.padrao == 1 }) })
                newJson.permissoes.fields[`permissao_subcategoria[${sub.id}]`] = { name: `permissoes[${sub.id}]`, options: options, label: sub.nome, type: "checkbox-group", disabled: checked, size: size, direction: "column" }
            })


            let gruposDeHorarios = await getGruposDeHorarios()

            let optionsGruposDeHorarios = [{ label: null, value: null }]
            for (let g of gruposDeHorarios) {
                    optionsGruposDeHorarios.push({ label: g.nome, value: g.id })
            }

            if (optionsGruposDeHorarios.length === 0) optionsGruposDeHorarios = [{ label: translate("Nenhum setor"), value: null }]

            newJson.grupo_horario_id = { ...newJson.grupo_horario_id, options: optionsGruposDeHorarios, disabled: optionsGruposDeHorarios.length === 0 }

            newJson.grupo_horario_id_excecao = { ...newJson.grupo_horario_id_excecao, options: optionsGruposDeHorarios, disabled: optionsGruposDeHorarios.length === 0 }

            setForm(newJson)
        } catch (error) {

        }
    }

    const handleSubmit = async (event, errors, values) => {
        try {
            console.log("{ handleSubmit }", errors, values)
            if (errors.length > 0) return

            let data = values

            let permissoes = []
            Object.keys(data.permissoes).forEach(perm => {
                permissoes = permissoes.concat(data.permissoes[perm])
            })

            data.permissao_total = checked
            data.permissoes = {}
            permissoesPadroes.forEach(perm => {
                data.permissoes[perm.id] = permissoes.indexOf(perm.id) >= 0
            })

            const grupo = await postGrupo(data)

            console.log("USUARIO CADASTRADO", grupo)

            if (refresh) refresh(grupo) // Passar grupo para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Grupo")} ${grupo.nome} ${translate("atualizado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalCadastroGrupo ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Cadastrar Grupo")}
            </ModalHeader>
            <ModalBody>
                <DefaultForm fields={form} handleSubmit={handleSubmit} />

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
