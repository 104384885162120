import React, { useEffect, useRef, useState } from "react"
import SweetAlert from "react-bootstrap-sweetalert"
//i18n
import { withTranslation } from "react-i18next"
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { getCamposAdicionais } from "../../../../services/campos-adicionais"
import { getTiposCanal, postCanal } from "../../../../services/canais"
import { getConfiguracoes } from "../../../../services/configuracoes"
import { getFluxos } from "../../../../services/fluxos"
import Carregando from "../../../Carregando"
import DefaultForm from "../../../form/DefaultForm"
import "./styles.scss"






export default withTranslation()(function ModalCadastroCanal({ opened, setOpened, refresh, ...props }) {
    function translate (text) {
      return props.t(text)
    }

    const [form, setForm] = useState({})

    const [tiposCanais, setTiposCanais] = useState([])
    const [tipoCanal, setTipoCanal] = useState(null)
    const [loading, setLoading] = useState(true)

    const [webChatTitulo, setWebChatTitulo] = useState("")
    const [webChatSubtitulo, setWebChatSubtitulo] = useState("")
    const [webChatCorPrimaria, setWebChatCorPrimaria] = useState("")
    const [webChatCorSecundaria, setWebChatCorSecundaria] = useState("")
    const [webChatCorMensagemEnvio, setWebChatCorMensagemEnvio] = useState("")
    const [webChatCorMensagemRecebida, setWebChatCorMensagemRecebida] = useState("")
    const [webChatOpcaoEmoji, setWebChatOpcaoEmoji] = useState("")
    const [webChatOpcaoAnexo, setWebChatOpcaoAnexo] = useState("")
    const [webChatOpcaoAudio, setWebChatOpcaoAudio] = useState("")

    const refWebChatTitulo = useRef()

    const [sweetAlert, setSweetAlert] = useState({
        show: false,
        title: "",
        text: "",
        confirmBtnBsStyle: "success",
        onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }) }
    })

    useEffect(() => {
        start()
    }, [opened])

    const start = async () => {
        try {
            setTipoCanal(null)
            setLoading(true)

            let tipos_canais = await getTiposCanal()
            setTiposCanais(tipos_canais)

            setLoading(false)
        } catch (error) {
            console.log("ERRORRRR", error)
        }
    }

    useEffect(() => {
        console.log("MUDOUUU", form)
    }, [form])

    const handleSubmit = async (event, errors, values) => {
        try {
            console.log("{ handleSubmit }", errors, values)
            if (errors.length > 0) return

            let data = values

            data = {
                ...data,
                canal_tipo_id: tipoCanal
            }

            const canal = await postCanal(data)

            console.log("USUARIO CADASTRADO", canal)

            if (refresh) refresh(canal) // Passar canal para componente pai

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Sucesso!"), text: `${translate("Canal cadastrado com sucesso")}.`, success: true, onConfirm: () => { setSweetAlert({ ...sweetAlert, show: false }); setOpened(false); } })
        } catch (error) {
            console.error("ModalCadastroCanal ERROR", error, error.response)

            setSweetAlert({ ...sweetAlert, show: true, title: translate("Ooops!"),text: error.data || translate("Ocorreu um erro"), success: false, error: true })
        }
    }

    const selecionarTipoCanal = async (tipo) => {
        try {
            setLoading(true)

            setTipoCanal(tipo)

            let fluxos = await getFluxos()

            let optionsFluxos = []

            for (let fluxo of fluxos) {
                optionsFluxos.push({ label: fluxo.nome, value: fluxo.id })
            }

            let json = {
                tabs: {
                    type: "tabs",
                    tabs: {
                        geral: {
                            title: translate("Geral"),
                            fields: {
                                nome: { name: "nome", type: "text", label: translate("Nome"), placeholder: translate("Nome"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
                                fluxo_id: { name: "fluxo_id", type: "select", options: [], label: "Fluxo", placeholder: translate("Nenhum fluxo selecionado"), errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 }, options: optionsFluxos, value: optionsFluxos.length > 0 ? optionsFluxos[0].value : null },
                            }
                        }
                    }
                }
            }

            if (tipo == "webchat") {
                let configuracoes = await getConfiguracoes("canal")
                console.log("CONFIGURAÇÕES", configuracoes)

                let campos_adicionais = await getCamposAdicionais()
                let optionsCamposAdicionais = {}

                if (campos_adicionais.length > 0) {
                    optionsCamposAdicionais = {
                        label: translate("Campos Adicionais"),
                        options: []
                    }

                    for (let campo of campos_adicionais) {
                        optionsCamposAdicionais.options.push({ label: campo.nome, value: `adicional_${campo.id}` })
                    }
                }

                let optionsCamposIniciais = [
                    {
                        label: translate("Contato"),
                        options: [
                            { label: translate("Nome"), value: "contato_nome" }
                        ]
                    },
                    { ...optionsCamposAdicionais }
                ]

                json = {
                    ...json,
                    tabs: {
                        ...json.tabs,
                        tabs: {
                            ...json.tabs.tabs,
                            configuracoes: {
                                title: translate("Configurações do Webchat"),
                                fields: {
                                    webchat_titulo: { name: "configuracoes['webchat_titulo']", type: "text", label: translate("Título"), placeholder: translate("Informe o título"), value: configuracoes?.webchat_titulo, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
                                    webchat_subtitulo: { name: "configuracoes['webchat_subtitulo']", type: "text", label: translate("Subtítulo"), placeholder: translate("Informe o subtítulo"), value: configuracoes?.webchat_subtitulo, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 6, md: 6, sm: 12 } },
                                    webchat_cor_primaria: { name: "configuracoes['webchat_cor_primaria']", type: "color", label: translate("Cor primária"), placeholder: "#000000", value: configuracoes?.webchat_cor_primaria, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 3, md: 3, sm: 12 } },
                                    webchat_cor_secundaria: { name: "configuracoes['webchat_cor_secundaria']", type: "color", label: translate("Cor secundária"), placeholder: "#000000", value: configuracoes?.webchat_cor_secundaria, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 3, md: 3, sm: 12 } },
                                    webchat_cor_mensagem_envio: { name: "configuracoes['webchat_cor_mensagem_envio']", type: "color", label: translate("Cor mens. envio"), placeholder: "#000000", value: configuracoes?.webchat_cor_mensagem_envio, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 3, md: 3, sm: 12 } },
                                    webchat_cor_mensagem_recebida: { name: "configuracoes['webchat_cor_mensagem_recebida']", type: "color", label: translate("Cor mens. recebida"), placeholder: "#000000", value: configuracoes?.webchat_cor_mensagem_recebida, errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 3, md: 3, sm: 12 } },
                                    webchat_opcao_emoji: { name: "configuracoes['webchat_opcao_emoji']", type: "select", options: [{ label: translate("Sim"), value: "1" }, { label: translate("Não"), value: "0" }], value: configuracoes?.webchat_opcao_emoji, label: "Emoji", placeholder: "", errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 } },
                                    webchat_opcao_anexo: { name: "configuracoes['webchat_opcao_anexo']", type: "select", options: [{ label: translate("Sim"), value: "1" }, { label: translate("Não"), value: "0" }], value: configuracoes?.webchat_opcao_anexo, label: "Anexo", placeholder: "", errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 } },
                                    webchat_opcao_audio: { name: "configuracoes['webchat_opcao_audio']", type: "select", options: [{ label: translate("Sim"), value: "1" }, { label: translate("Não"), value: "0" }], value: configuracoes?.webchat_opcao_audio, label: "Áudio", placeholder: "", errorMessage: translate("Campo obrigatório"), validate: { required: { value: true } }, size: { lg: 4, md: 4, sm: 12 } }
                                }
                            },
                            campos_iniciais: {
                                title: translate("Campos Adicionais"),
                                fields: {
                                    campos_iniciais: { name: "campos_iniciais", type: "multiselect", label: translate("Campos"), isMulti: true, options: optionsCamposIniciais, errorMessage: translate("Campo obrigatório"), validate: {}, size: { lg: 12, md: 12, sm: 12 } }
                                }
                            }
                        }
                    }
                }
            }

            console.log(json)

            setForm(json)

            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    return (
        <Modal size="lg" isOpen={opened} toggle={() => { setOpened(!opened) }}>
            <ModalHeader toggle={() => { setOpened(!opened) }}>
                {translate("Cadastrar Canal")}
            </ModalHeader>
            <ModalBody>
                {loading ? <>
                    <Carregando />
                </> : <>
                    {!tipoCanal && <div>
                        <Row>
                            {tiposCanais.map(tipo_canal => (
                                <Col key={tipo_canal.id} className="tipo_canal" onClick={() => selecionarTipoCanal(tipo_canal.id)}>
                                    {tipo_canal.id == "webchat" && <i className="uil uil-comments-alt" style={{ color: "#BED62D" }}></i>}
                                    {tipo_canal.id == "whatsapp" && <i className="uil uil-whatsapp"></i>}
                                    <p>{tipo_canal.nome}</p>
                                </Col>
                            ))}
                        </Row>
                    </div>}
                    {tipoCanal && <DefaultForm fields={form} handleSubmit={handleSubmit} buttons={[
                        { text: translate("Voltar"), onClick: () => setTipoCanal(null) }
                    ]} />}
                </>}

                {sweetAlert.show && <SweetAlert {...sweetAlert}>{sweetAlert.text}</SweetAlert>}
            </ModalBody>
        </Modal>
    )
})
