import React from "react"
import { Container, Row, Col } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"

const Footer = (props) => {
  function translate (text) {
    return props.t(text)
  }
  
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © RadiusChat.</Col>
            <Col md={6}>
              <div className="text-sm-right d-none d-sm-block">
                {translate("Desenvolvido")} {translate("por")} RadiusNet
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default withTranslation()(Footer)
